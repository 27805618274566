ul.name-suggestion-list {
  border: 1px solid gray;
  border-top: none;
  background-color: var(--gray-1);
  list-style-type: none;
  padding-left: 0px;
  padding-top: 5px;
  height: 85px;
  width: 90% !important;
  z-index: 1;
  overflow-y: auto;
  position: absolute;
  width: auto;
}
.name-suggestion-list > li {
  padding-left: 9px;
}
.name-suggestion-list > li:hover {
  background-color: #2b3043;
  cursor: pointer;
}

.hide-suggestion {
  display: none;
}
.suggestions > li {
  padding: 4px 8px 4px 8px;
  background-color: var(--gray-5);
  white-space: normal;
  word-wrap: break-word;
}
.suggestions > li:not(:last-child) {
  border-bottom: 1px solid var(--gray-3);
}
.suggestions > li:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.suggestions > li:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.suggestions::-webkit-scrollbar-thumb {
  background-color: var(--mint-6);
  border-radius: 4px;
}
.suggestions::-webkit-scrollbar-track {
  border-radius: 4px;
  box-shadow: none;
  background-color: var(--gray-4);
}
.suggestions::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}
