.vt-container {
  height: 100%;
  color: #ffffff;
  background-color: var(--gray-1);
  display: flex;
  flex-direction: column;
}

.ct-container {
  padding-top: 64px;
  display: flex;
  flex-direction: column;
}

.venuemgt_table_container {
  height: 100%;
  padding: 0px 32px 32px;
  padding-right: 32px;
  margin: 0px;
}

.ct-container .router_buttons_container {
  width: 100%;
  margin-left: 0px;
  padding: 0px 32px;
}

.venuemgt_table_container .ag-header-row {
  background-color: var(--gray-4);
}

.venuemgt_table_container .ag-root-wrapper {
  border: none;
  border-radius: 4px;
  background: var(--gray-2);
}
.venuemgt_table_container .ag-header {
  border: none;
}
.venuemgt_table_container .ag-header-row:not(:first-child) .ag-header-cell {
  border: none;
}
.venuemgt_table_container .ag-row:not(.ag-row-first) {
  border-color: transparent;
}
.venuemgt_table_container .ag-icon {
  display: none;
}
.ag-icon-asc,
.ag-icon-desc {
  display: block !important;
}
.ag-theme-alpine-dark input[class^="ag-"][type="text"] {
  border: none;
  background: var(--gray-2);
}
.ag-theme-alpine-dark input[class^="ag-"][type="text"]:focus {
  box-shadow: none;
  border: 1px solid var(--gray-9) !important;
}
.ag-theme-alpine-dark input[class^="ag-"][type="text"]:hover {
  border: 1px solid var(--gray-9) !important;
}
.ag-theme-alpine-dark
  .ag-ltr
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected) {
  border: none;
}
.venue-address-edit-btn {
  cursor: pointer;
}
.venuemgt_table_container .ag-center-cols-viewport {
  background-color: var(--gray-1);
}
.ag-layout-normal::-webkit-scrollbar-thumb {
  position: absolute;
  width: 8px;
  height: 15.53px;
  left: 0px;
  top: 0px;
  background: #87d1ae;
  border-radius: 4px;
}
.ag-layout-normal::-webkit-scrollbar {
  position: absolute;
  width: 8px;
  height: 480.5px;
  left: 0px;
  top: 0px;
  background: #262626;
  border-radius: 4px;
}
.venuemgt_table_container .ag-header-viewport {
  background-color: var(--gray-1);
}

.home_page_icon i {
  margin-top: 32px;
  padding-left: 3px;
  font-size: 17px;
}

.venue-address-header-row {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  width: 100%;
  cursor: default;
}
.venue-address-header-banner {
  font-size: 14px;
  color: var(--gray-9);
  width: 1014px;
}
.venue-address-header-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0px;
  width: 100%;
  grid-gap: 12px;
  gap: 12px;
  justify-content: space-between;
}
.venue-header-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  width: 968px;
  height: 28px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: var(--gray-9);
  margin-right: auto;
}
.venue-refresh-btn,
.venue-download-btn,
.venue-upload-btn,
.venue-add-btn {
  width: 130px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: 24px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 1px 8px 1px 7px;
  gap: 4px;
  background: var(--gray-4);
  border-radius: 60px;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
}
.venue-refresh-btn:focus,
.venue-download-btn:focus,
.venue-upload-btn:focus,
.venue-add-btn:focus {
  outline: none;
}
.venue-refresh-btn:hover,
.venue-download-btn:hover,
.venue-upload-btn:hover,
.venue-add-btn:hover {
  border-color: var(--gray-9);
}
.venue-refresh-btn:active,
.venue-download-btn:active,
.venue-upload-btn:active,
.venue-add-btn:active {
  background: var(--mint-6);
  outline: none;
  border-color: var(--gray-4);
}
.venue-refresh-btn:active span,
.venue-download-btn:active span,
.venue-upload-btn:active span,
.venue-add-btn:active span {
  color: var(--gray-4);
  font-weight: 500;
}
.venue-refresh-btn:active img,
.venue-download-btn:active img,
.venue-upload-btn:active img,
.venue-add-btn:active img {
  filter: brightness(0%);
}

.venue-refresh-btn span,
.venue-download-btn span,
.venue-upload-btn span,
.venue-add-btn span {
  width: fit-content;
  height: 22px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-9);
}

.add-tech-manager-icon {
  font-size: 14px;
  background-color: black;
  color: white;
  height: 15px;
  border-radius: 15px;
  border: 2px solid gray;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-bottom: 30px;
}
.manage_ct_disabled,
.manage_rsd_disabled,
.manage_vm_disabled {
  opacity: 0.4;
  cursor: default !important;
}

.refresh-btn {
  margin-top: 70px;
}

.transform-input {
  text-transform: uppercase !important;
}
