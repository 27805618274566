.Modal.createliveshotmodal {
  position: relative;
  background-color: var(--gray-2) !important;
  width: 100%;
  max-height: 80vh;
  border: none;
  box-shadow: none;
  padding: 32px !important;
  overflow: hidden;
  left: 0px !important;
  right: 0px !important;
  top: 0px !important;
}
.padding-space {
  padding-right: 10px;
}
.search-cancel-button {
  color: var(--gray-9);
  font-size: 14px !important;
  background-color: var(--gray-4);
  border: none;
  border-radius: 60px;
  padding: 5px 12px;
  text-transform: uppercase;
  cursor: pointer;
  float: right;
  height: 32px;
  width: 74px;
  font-weight: 400;
  margin-top: 24px;
}
.search-cancel-button:hover {
  background: var(--gray-4);
  border: 1px solid var(--gray-9);
  color: var(--gray-9);
}
.search-cancel-button:focus {
  background: var(--mint-6);
  border: 1px solid var(--mint-6);
  color: var(--gray-1);
}
.Modal.createliveshotmodal::-webkit-scrollbar,
.searchbox::-webkit-scrollbar {
  width: 8px !important;
}
.Modal.createliveshotmodal::-webkit-scrollbar-track,
.searchbox::-webkit-scrollbar-track {
  background: var(--gray-3) !important;
  border-radius: 4px !important;
  box-shadow: none;
}
.Modal.createliveshotmodal::-webkit-scrollbar-thumb,
.searchbox::-webkit-scrollbar-thumb {
  background: var(--mint-6) !important;
  border-radius: 4px !important;
}
.search-lower-row,
.search-middle-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}
.add-ls-outer-box {
  position: absolute;
  z-index: 500;
  background-color: var(--gray-2) !important;
  width: 80%;
  max-height: 80vh;
  border: 2px solid var(--mint-6);
  left: 10%;
  top: 10%;
  transition: all 0.3s ease-out;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 8px;
  overflow: hidden;
}

.search-expDate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 200px;
  height: 24px;
  padding: 1px 8px;
  background: var(--gray-9);
  border-radius: 4px;
  color: var(--gray-1);
  text-transform: uppercase;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.search-upper-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 16px;
  width: auto;
  min-height: 32px;
  background: var(--gray-5);
  border-radius: 4px 4px 0px 0px;
}
.talent-venue-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: auto;
  margin-right: auto;
  cursor: default;
}
.talent-name,
.venue-name {
  font-family: "Karla";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--gray-10);
  text-transform: uppercase;
  word-break: break-all;
}

.search-lower-row {
  min-height: 32px;
  flex-wrap: wrap;
  gap: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 16px;
  border-radius: 0px 0px 4px 4px;
}
.search-middle-row {
  height: 78px;
  border-top: 1px solid var(--gray-2);
  border-bottom: 1px solid var(--gray-2);
}
.search-time-box {
  width: 10%;
  border-right: 1px solid var(--gray-2) !important;
  padding: 4px 12px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.5;
}
.search-gp-box {
  width: 10%;
  padding: 4px 12px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1.5;
}
.search-name-box {
  width: 10%;
}
.search-venue-box {
  width: 15%;
}
.search-name-box,
.search-venue-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 12px;
  line-height: 1.5;
  border-right: 1px solid var(--gray-2);
}
.search-tx-box {
  width: 15% !important;
}
.search-city-box,
.search-lstype-box {
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 12px;
  line-height: 1.5;
  border-right: 1px solid var(--gray-2);
}
.search-cr-box {
  width: 12%;
  padding: 12px 16px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.searchview {
  border: 1px solid grey;
  border-top: none;
  border-left: none;
  text-align: center;
  width: 140px;
}
.searchview-border {
  border-right: none;
}
.liveshot-ion,
.col-border-ifb,
.col-border-pl,
.col-border-pho {
  display: flex;
}
.icon-pos {
  position: relative;
  margin-top: 20px;
}
.add-icon {
  margin-left: 0px;
}
.search-name-box input,
.search-city-box input,
.search-lstype-box input,
.use-count,
.last-used-time {
  outline: none;
}
.search-name-box .tooltip-trigger {
  text-decoration: none;
}
.searchbox-input {
  background-color: var(--gray-4);
  color: var(--gray-10);
  width: 100%;
  border: none;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  padding: 0px;
  text-transform: uppercase;
}
.tx-info {
  background-color: transparent;
  color: var(--gray-9);
  width: 29%;
  border: none;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  flex: none;
  order: 2;
  flex-grow: 0;
  display: flex;
  text-align: inherit;
  text-transform: uppercase;
}
.use-count {
  background-color: transparent;
  border: none;
  width: 15%;
  color: var(--gray-9);
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  flex: none;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  flex: none;
  order: 2;
  flex-grow: 0;
  text-align: left;
}
.Searchbox_container {
  color: var(--gray-9);
  width: 100% !important;
  padding-bottom: 150px;
}
.type-dropdown select {
  width: 100%;
  font-size: 15px;
  background-color: #21242d;
  color: white;
  border: none;
  outline: none;
  border-bottom: 1px solid gray;
  height: 30px;
}
.closebtn button {
  color: #fff;
  font-size: 42px;
  font-weight: lighter;
  float: right;
  padding: 5px;
}
.closebtn {
  position: absolute;
  width: 100%;
}
.hide {
  display: none;
}
.searchbox {
  width: 100%;
  max-height: calc(80vh - 200px);
  overflow-x: hidden;
  text-align: justify;
  overflow-y: auto;
  font-size: 14px;
}
.closebtn button {
  outline: none;
}
.refresh-btn {
  position: absolute;
  right: 0;
  top: 0;
}
.refresh-btn > i {
  float: right;
  margin-right: 5px;
  margin-top: 10px;
}
.search-refresh-btn > Button {
  background-color: var(--gray-4);
  color: var(--gray-9);
  fill: var(--gray-9);
  border-radius: 60px;
  border: var(--gray-4);
  text-transform: uppercase;
  cursor: pointer;
  height: 33px;
  width: 103px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  padding: 5px 10px 5px 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.search-refresh-btn > Button:hover {
  color: var(--gray-9);
  background-color: var(--gray-4);
  border: 1px solid var(--gray-9);
}
.search-refresh-btn > Button:active {
  color: var(--gray-1);
  background-color: var(--mint-6);
  border: 1px solid var(--gray-1);
  fill: var(--gray-1);
}
.search-refresh-icon {
  height: 18px;
  width: 18px;
}
.refresh-btn > Button {
  margin-right: 20px;
  float: right;
  margin-top: 10px;
  font-size: 14px;
  background-color: #2e323b;
  color: white;
  border-radius: 30px;
  border: 1px;
  text-transform: uppercase;
  cursor: pointer;
  height: 28px;
  width: 8em;
  font-size: 15px;
}
.refresh-btn > Button > img {
  margin-right: 5px;
}
.search-header-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 32px;
  justify-content: space-between;
}
.search-display-logo {
  margin-right: 12px;
}
.search-closeicon {
  cursor: pointer;
  float: inherit;
  fill: var(--gray-9);
}
.search-closeicon:hover {
  fill: var(--mint-6);
}
.search-closeicon:focus {
  fill: var(--mint-6);
}
.searchls_icon {
  cursor: pointer;
  height: 18px !important;
  padding: 2px;
}
.search-header-title {
  font-size: 20px !important;
  font-weight: 700;
  color: var(--gray-9);
  line-height: 28px;
  margin-bottom: 0px;
  margin-right: auto;
}
.search-border-2 {
  border: 1px solid var(--gray-7);
}
.search-border-1,
.search-border-2 {
  border-radius: 4px;
  height: 33px;
  width: 100%;
  fill: var(--gray-7);
}
.search-border-1 {
  background: var(--gray-4);
}
.search-border-1:hover,
.search-border-2:hover {
  border: 1px solid var(--gray-9);
  fill: var(--gray-9);
  color: var(--gray-9) !important;
}
.search-border-1 input:hover::placeholder,
.search-border-2 input:hover::placeholder {
  color: var(--gray-9) !important;
}
.search-border-1:focus-within,
.search-border-2:focus-within {
  border: 1px solid var(--gray-9);
  fill: var(--gray-9);
  color: var(--gray-9) !important;
}
.search-border-1 input:focus-within::placeholder,
.search-border-2 input:focus-within::placeholder {
  color: var(--gray-9) !important;
}
.fa-spinner {
  margin-right: 77%;
}
.search-box-row {
  display: flex;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 32px;
  grid-gap: 12px;
  gap: 12px;
  flex-direction: row;
  justify-content: space-between;
}

.common-search-input {
  background-color: var(--gray-4);
  padding-left: 14px;
  cursor: text;
  border: none;
  outline: none;
  box-shadow: none;
  height: 32px;
  border-radius: 4px;
  transition-duration: 0ms;
}
.common-search-input .search-input-icon {
  margin-right: 8px;
  cursor: pointer;
  fill: var(--gray-7);
}
.common-search-input input {
  background-color: var(--gray-4);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 22px;
  color: var(--gray-9);
  font-family: "Karla";
}

.common-search-input:focus {
  outline: var(--gray-9);
  border: none;
  box-shadow: none;
}
.common-search-input input::placeholder {
  color: var(--gray-7);
}

.common-search-input.filled .search-input-icon,
.common-search-input:hover .search-input-icon,
.common-search-input:focus-within .search-input-icon {
  fill: var(--gray-9);
}
.common-search-input:hover,
.common-search-input:focus-within {
  outline: 1px solid var(--gray-9);
}

.common-search-input:hover input::placeholder,
.common-search-input:focus-within input::placeholder {
  color: var(--gray-9);
}

.search-liveshot img.search_icon {
  padding-bottom: 7px;
}
.search-textbox {
  width: 100%;
  background-color: var(--gray-4);
  outline: none;
  border: none;
  color: var(--gray-9) !important;
  margin-left: 12px;
  line-height: 22px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  padding: 0px;
}
.search-gp-box input:focus,
.search-time-box input:focus,
.tx-info:focus {
  outline: none !important;
  border: solid 1px var(--gray-9) !important;
  border-radius: 4px;
}
.search-textbox .input {
  border-bottom: none;
}
.search-img-input {
  width: 100%;
  padding: 5px 12px 5px 12px;
  line-height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
select.facilityList {
  color: var(--gray-7);
  font-size: 14px;
  width: 96%;
  background-color: transparent;
  line-height: 22px;
  font-weight: 400;
  text-transform: uppercase;
  vertical-align: text-top;
}

.add-ls-select #react-select-4-option-0 {
  display: none;
  background-color: transparent !important;
}

.facilityList option {
  background-color: #31343d;
}
.vprod-paddingright {
  padding-right: 12px;
}
/* dropdown style */
.add-ls-select {
  width: 230px !important;
  height: 33px;
  border-radius: 4px !important;
  padding: 5px 8px 5px;
  border: 1px solid var(--gray-4);
  margin-left: 0px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
}
.add-ls-select:hover {
  border: 1px solid var(--gray-9);
}
.add-ls-select:hover .add-ls-selection__placeholder {
  color: var(--gray-9);
}
.add-ls-select:hover .add-ls-selection__indicator svg {
  fill: var(--gray-9);
}
.add-ls-selection__indicator-separator {
  display: none;
}
/* css-319lph-ValueContainer holds text div in select */
.add-ls-selection__value-container {
  padding: 0px 0px !important;
}
/* css-qc6sy-singleValue  is the text of selected option in select tag */
.add-ls-selection__single-value {
  margin: 0px;
  color: inherit !important;
}
.add-ls-selection__menu .css-d7l1ni-option {
  background-color: transparent !important;
}
.add-ls-selection__control {
  width: 100% !important;
}
.black-text
  .add-ls-selection__control--is-focused
  .add-ls-selection__placeholder {
  color: var(--gray-2);
}
/* add-ls-selection__control add-ls-selection__control--menu-is-open css-13cymwt-control */

.add-ls-select:hover #react-select-2-placeholder {
  color: inherit;
}
.add-ls-select #react-select-2-placeholder {
  color: inherit;
}
/* styling arrow outer and inner div */
.add-ls-selection__indicator {
  padding: 0px 0px !important;
  fill: inherit !important;
  transition: none !important;
}
/* div holds text and arrow */
.add-ls-selection__control {
  border: none !important;
  border-radius: 0px !important;
  background-color: transparent !important;
  width: initial;
  padding: 0px !important;
  display: contents;
  text-align: flex-start !important;
  color: inherit;
  caret-color: transparent;
  outline: none !important;
}
.add-ls-selection__value-container {
  justify-content: flex-start;
}
.add-ls-selection__control {
  box-shadow: none !important;
}
/* dropdown menu options; classes defined by react-select */
.add-ls-selection__menu {
  border-radius: 4px !important;
  background: var(--gray-5) !important;
  color: var(--gray-9) !important;
  font-weight: 400 !important;
  margin-top: 8px;
  width: 100%;
  position: absolute;
  right: 0%;
}
.add-ls-selection__menu-list {
  background: var(--gray-4) !important;
  border-radius: 4px !important;
  padding: 2px !important;
}
.add-ls-selection__menu-list .add-ls-selection__option {
  width: 100%;
  padding: 6px 9px 6px 9.5px !important;
  position: relative;
  background: var(--gray-5) !important;
}
.add-ls-selection__menu-list .add-ls-selection__option:not(:last-child) {
  margin-bottom: 2px;
}
.add-ls-selection__menu-list .add-ls-selection__option:nth-last-child() {
  margin-bottom: 0px;
}
/*  accessing dropdown options */
.add-ls-selection__menu-list > div {
  height: 33px;
  padding: 6px 12px !important;
  line-height: 22px;
}
/* options not selected when hover*/
.add-ls-selection__menu-list > div:hover {
  background-color: var(--gray-2) !important;
  width: 100%;
  border-radius: 4px !important;
  position: relative;
}
/* selected option*/
.add-ls-selection__menu-list .add-ls-selection__option--is-selected {
  background-color: var(--gray-2) !important;
}
.grey-bg {
  background-color: var(--gray-4) !important;
}
.green-bg {
  background-color: var(--mint-6) !important;
  outline: none !important;
}
.white-text {
  color: var(--gray-9) !important;
}
.grey-text {
  color: var(--gray-7);
}
.white-text .add-ls-selection__indicators svg {
  fill: var(--gray-9);
}
.grey-text .add-ls-selection__indicators svg {
  fill: var(--gray-7);
}
.black-text .add-ls-selection__indicators svg {
  fill: var(--gray-1) !important;
}
.black-text {
  color: var(--gray-1) !important;
  font-weight: 700 !important;
  border: none !important;
}
@media screen and (min-device-width: 992px) and (max-device-width: 1199px) {
  .Searchbox_container {
    width: 45%;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .Searchbox_container {
    width: 55%;
  }
}
@media screen and (min-device-width: 576px) and (max-device-width: 767px) {
  .Searchbox_container {
    width: 75%;
  }
}
@media screen and (max-device-width: 575px) {
  .Searchbox_container {
    width: 100%;
  }
  .searchbox {
    font-size: 13px;
  }
}
.ant-tooltip .ant-tooltip-inner {
  background: var(--gray-10);
  color: var(--gray-1);
  font-family: "Karla";
  font-size: 14px;
  padding: 0px 8px;
}
.ant-tooltip .ant-tooltip-arrow::after {
  background: var(--gray-10);
  color: var(--gray-1);
  border-radius: 0px;
}
