.popup-modal {
  background-color: #21242d;
  color: #21242d;
  height: 180px;
  width: 500px;
  position: fixed;
  top: 20%;
  left: calc(50% - 250px);
  z-index: 500;
}

.info-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  width: 500px;
  height: auto;
  background: var(--gray-2);
  border: 2px solid var(--blue-6);
  border-radius: 8px;
  cursor: default;
}
.info-message-container-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 436px;
  height: 122px;
}

.info-message-container-inner-cr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 16px;
  gap: 16px;
  width: 436px;
  padding-left: 20px;
  height: 122px;
  padding-top: 12px;
}
.info-message-container-cr .modal-body {
  margin-top: 16px;
  margin-bottom: 16px;
}

.info-message-container-cr .modal-footer {
  border: none !important;
}
.info-message-nobtn {
  color: var(--gray-7);
  background-color: var(--gray-2);
  border: 1px solid var(--gray-7);
  font-size: 14px !important;
  border-radius: 60px;
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 8px;
  height: 32px;
  width: 65px;
  font-weight: 400;
}
.info-message-nobtn:hover {
  border: 1px solid var(--gray-9);
  color: var(--gray-9);
}
.info-message-nobtn:focus {
  color: var(--blue-6);
  border: 1px solid var(--blue-6);
}

.info-message-container-cr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  width: 500px;
  height: 208px;
  background: var(--gray-2);
  border: 2px solid var(--blue-6);
  border-radius: 8px;
  cursor: default;
}
.info-message-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  width: 436px;
  height: 28px;
}
.info-msg-logo {
  width: 26px;
  height: 26px;
  filter: invert(45%) sepia(88%) saturate(1341%) hue-rotate(160deg)
    brightness(93%) contrast(101%);
}
.failure-msg {
  width: 360px;
  height: 28px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: var(--gray-9);
}
.info-message-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 0px 0px;
  gap: 16px;
  width: 436px;
  height: 40px;
}
.infoIcon {
  margin-top: 5px;
  margin-right: 10px;
}

.info-message-container-cr .closeIcon:hover {
  fill: var(--blue-6);
}
.Close-btn {
  background-color: var(--blue-6);
  color: var(--gray-1);
  font-size: 11px;
  border-radius: 12px;
  border: 5px;
  padding: 3px 6px 3px 6px;
  text-transform: uppercase;
  cursor: pointer;
}
.info-message-closebtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  width: 65px;
  height: 32px;
  background: var(--gray-4);
  border-radius: 60px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-9);
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
}

.info-message-closebtn:hover {
  border-color: var(--gray-9);
  cursor: pointer;
}
.info-message-closebtn:focus {
  cursor: pointer;
  outline: none;
  border: none;
  background: var(--blue-6);
  color: var(--gray-1);
}

.Close-button:hover {
  border: 2px solid var(--gray-9);
}
.successMsg {
  color: green;
}
.failureMsg {
  color: #d64020;
}

.failure-message {
  width: 436px;
  margin-top: 14px;
  height: 22px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: var(--gray-9);
}

.failure-message-cr {
  width: 436px;
  height: 6px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: var(--gray-9);
}

@media screen and (max-device-width: 575px) {
  .popup-modal {
    margin-left: 20%;
    width: 350px;
  }
}
