.hidecursor {
  cursor: default !important;
}

@font-face {
  font-family: "NunitoSans-Regular";
  src: local("NunitoSans"),
    url(./assets/fonts/NunitoSans/NunitoSans-Regular.ttf) format("Opentype");
}
@font-face {
  font-family: "NunitoSans-Bold";
  src: local("NunitoSans"),
    url(./assets/fonts/NunitoSans/NunitoSans-Bold.ttf) format("Opentype");
}
@font-face {
  font-family: "NunitoSans-Italic";
  src: local("NunitoSans"),
    url(./assets/fonts/NunitoSans/NunitoSans-Italic.ttf) format("Opentype");
}
main {
  font-family: "NunitoSans-Regular";
}
