/* Input fields - Liveshot Canvas Summary Row - Left panel */

.venue_city input {
  color: var(--gray-9);
  background: var(--gray-1);
  text-transform: uppercase;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-left: 2px solid var(--rose-6);
  padding-left: 8px;
  border: none;
}

.talentNameInput,
.venueNameInput {
  background: var(--gray-3);
  text-transform: uppercase;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 14px;
  padding: 1px 8px;
  height: 24px;
  flex: none;
  order: 1;
  flex-grow: 1;
  border-radius: 0px 4px 4px 0px;
  color: var(--gray-9);
  outline: none;
}
.talentNameInput,
.venueNameInput,
.venueCityInput,
.venueRegionInput {
  border: none;
  border-left: 2px solid var(--green-6);
}
.venueNameInput {
  background: var(--gray-1);
}
.talentNameInput:placeholder-shown,
.venueNameInput:placeholder-shown,
.venueCityInput:placeholder-shown,
.venueRegionInput:placeholder-shown {
  border-left: 2px solid var(--rose-6) !important;
}
.talentNameInput::placeholder,
.venueNameInput::placeholder,
.venueContactInput::placeholder,
.venueTxInput::placeholder,
.venueContactNameInput::placeholder,
.venueContactInput::placeholder,
.venueCityInput::placeholder,
.venueRegionInput::placeholder,
.venueCountryInput::placeholder,
.poNumberInput::placeholder,
.txInput::placeholder,
.studioTruckNotes::placeholder,
.venueContactInput::placeholder {
  color: var(--gray-7);
}
.placeholder-icon {
  fill: var(--gray-7);
}
.value-icon {
  fill: var(--gray-9);
}
.venueContactInput,
.venueCityInput,
.venueRegionInput {
  background: var(--gray-1);
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 8px;
  height: 24px;
  flex: none;
  order: 1;
  flex-grow: 1;
  border-radius: 0px 4px 4px 0px;
  color: var(--gray-9);
}
.venueCountryInput {
  background: var(--gray-1);
  text-transform: uppercase;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 8px;
  height: 24px;
  flex: none;
  order: 1;
  float: left;
  border-radius: 0px 4px 4px 0px;
  color: var(--gray-9);
}

.venueContactInput:hover,
.venueContactInput:active,
.venueContactInput:focus,
.venueContactNameInput:hover,
.venueCountryInput:hover,
.txInput:hover,
.studioTruckNotes:hover,
.poNumberInput:hover,
.venueCountryInput:active,
.txInput:active,
.studioTruckNotes:active,
.poNumberInput:active,
.venueCountryInput:focus,
.txInput:focus,
.studioTruckNotes:focus,
.poNumberInput:focus,
.venueContactNameInput:focus {
  border: 1px solid var(--gray-9) !important;
  outline: none;
  border-radius: 4px;
}
.venueTxInput:hover,
.venueTxInput:focus,
.contact-container:hover .venueContactInput,
.venueContactInput:hover,
.venueContactInput:focus {
  outline: 1px solid var(--gray-9) !important;
  border: none !important;
  border-radius: 4px;
}
.venueContactNameInput:hover + .contact-icon,
.venueContactNameInput:focus + .contact-icon,
.venueContactNameInput:active + .contact-icon,
.venueTxInput:hover::placeholder,
.venueContactInput:hover::placeholder {
  color: var(--gray-9) !important;
}
.venueContactInput-error:hover,
.venueContactInput-error:active,
.venueContactInput-error:focus {
  border: 1px solid var(--rose-6);
  outline: none;
  border-radius: 4px;
}
.venueNameInput:hover,
.talentNameInput:hover,
.venueNameInput:active,
.venueNameInput:focus,
.talentNameInput:active,
.talentNameInput:focus,
.venueCityInput:focus,
.venueCityInput:hover,
.venueCityInput:active,
.venueRegionInput:focus,
.venueRegionInput:hover,
.venueRegionInput:active {
  border-right: 1px solid var(--gray-9);
  border-top: 1px solid var(--gray-9);
  border-bottom: 1px solid var(--gray-9);
  border-radius: 0px 4px 4px 0px;
  outline: none;
}
.venueContactNameInput:focus::placeholder,
.venueNameInput:focus::placeholder,
.talentNameInput:focus::placeholder {
  color: var(--gray-9);
}

.poNumberInput,
.venueContactNameInput,
.venueTxInput,
.venueContactInput,
.venueContactInput-error,
.studioTruckNotes,
.txInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 8px;
  height: 24px;
  width: 100%;
  font-size: 14px;
  background: var(--gray-3);
  color: var(--gray-9);
  border: none;
  border-radius: 4px;
  overflow: hidden;
}
.contactName {
  padding-right: 30px;
}
.venueContactInput-error {
  border: 1px solid var(--rose-6);
  outline: none;
  border-radius: 4px;
}

.skypeInput,
.quicklinkInput,
.webotherInput {
  background: var(--gray-3);
  color: var(--gray-9);
  padding: 1px 8px;
  width: 100%;
  font-size: 14px;
  border: 1px solid var(--gray-3);
  border-radius: 4px;
}
.skypeInput::placeholder,
.quicklinkInput::placeholder,
.webotherInput::placeholder {
  color: var(--gray-7);
}
.skypeInput:hover,
.quicklinkInput:hover,
.webotherInput:hover,
.skypeInput:active,
.quicklinkInput:active,
.webotherInput:active,
.skypeInput:focus,
.quicklinkInput:focus,
.webotherInput:focus {
  border: 1px solid var(--gray-9);
  border-radius: 4px;
  outline: none;
}
.venue-type-clear-btn {
  display: flex;
  justify-content: center;
  padding: 1px 12px;
  cursor: pointer;
  padding: 1px 12px;
  gap: 4px;
  width: 100px;
  height: 24px;
  background: var(--gray-1);
  border: 1px solid var(--gray-4);
  border-radius: 60px;
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-9);
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-family: "Karla";
  float: right;
}
.venue-clear-button.padding-top {
  padding-top: 8px;
}

.venue-type-clear-btn:hover {
  border: 1px solid var(--gray-9);
}
.venue-type-clear-btn:focus {
  outline: none;
}
.venue-type-clear-btn:active {
  background-color: var(--mint-6);
  color: var(--gray-1);
  border: none;
  outline: none;
}

.venue-type-clear-btn:active span {
  color: var(--gray-1);
}
.submitBtnAnt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 8px;
  gap: 4px;
  width: 66px;
  height: 24px;
  background: var(--mint-6);
  border-radius: 60px;
}

.submitBtnAnt:hover,
.submitBtnAnt:focus {
  background: var(--mint-7);
}

.venueContactNameInput {
  text-transform: uppercase;
}

[placeholder] {
  text-overflow: clip;
}
