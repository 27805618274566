.rt-container {
  height: 100%;
  color: #ffffff;
  padding-top: 60px;
  background-color: var(--gray-1);
  display: flex;
  flex-direction: column;
}
.ag-theme-alpine-dark .ag-row-odd,
.ag-theme-alpine-dark .ag-row-even {
  background-color: #181d1f;
}
.text-gray {
  color: gray !important;
}
.text-green {
  color: #00a700 !important;
}
.text-blue {
  color: #5981dc !important;
}
.venuemgt_table_container .text-orange {
  color: #a78028 !important;
}
.delete-rows {
  cursor: pointer;
}
.resource_table_container {
  height: calc(100% - 198px);
}
.venuemgt_table_container .ag-header-row {
  background-color: var(--gray-4);
}

.venuemgt_table_container .ag-root-wrapper {
  border: none;
  border-radius: 4px;
  background: var(--gray-2);
}
.venuemgt_table_container .ag-header {
  border: none;
}
.venuemgt_table_container .ag-header-row:not(:first-child) .ag-header-cell {
  border: none;
}
.venuemgt_table_container .ag-row:not(.ag-row-first) {
  border-color: transparent;
}
.venuemgt_table_container .ag-icon {
  display: none;
}
.ag-icon-asc,
.ag-icon-desc {
  display: block !important;
}
.ag-theme-alpine-dark input[class^="ag-"][type="text"] {
  border: none;
  background: var(--gray-2);
}
.ag-theme-alpine-dark input[class^="ag-"][type="text"]:focus {
  box-shadow: none;
  border: 1px solid var(--gray-9);
}
.ag-theme-alpine-dark input[class^="ag-"][type="text"]:hover {
  border: 1px solid var(--gray-9);
}
.ag-theme-alpine-dark
  .ag-ltr
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected) {
  border: none;
}
.venue-address-edit-btn {
  cursor: pointer;
}
.venuemgt_table_container .ag-center-cols-viewport {
  background-color: var(--gray-1);
}
.ag-root-wrapper ::-webkit-scrollbar-thumb {
  background-color: var(--mint-6);
  border-radius: 4px;
}
.ag-root-wrapper ::-webkit-scrollbar-track {
  border-radius: 4px;
  box-shadow: none;
  background-color: var(--gray-4);
}
.ag-root-wrapper ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
.venuemgt_table_container .ag-header-viewport {
  background-color: var(--gray-1);
}

.lsm-heading {
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 6px;
}

.control-tracker-refresh {
  right: 4%;
  top: 0px;
}

.control-tracker-refresh > Button {
  margin-top: 5px;
}
.ag-theme-alpine-dark .ag-row-even {
  background-color: var(--gray-2) !important;
  height: 48px !important;
  color: var(--gray-9) !important;
  text-align: center;
}
.ag-theme-alpine-dark .ag-row-odd {
  background-color: var(--gray-3) !important;
  height: 48px !important;
  color: var(--gray-9) !important;
  text-align: center;
}
.Resource-header-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  width: 1358px;
  height: 28px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: var(--gray-9);
  margin-right: auto;
  font-family: "Karla";
  font-style: normal;
}
.resource-mgt-header-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 30px;
  width: 100%;
  grid-gap: 12px;
  gap: 12px;
  height: 52px;
  justify-content: space-between;
}
.resource-mgt-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 32px;
  width: 100%;
  height: 52px;
  margin-top: 24px;
  margin-bottom: 24px;
}
.resource-refresh-btn {
  width: 130px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: 24px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 1px 8px 1px 7px;
  gap: 4px;
  background: var(--gray-4);
  border-radius: 60px;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  color: var(--gray-9);
}
.resource-refresh-btn:focus {
  outline: none;
}
.resource-refresh-btn:hover {
  border-color: var(--gray-9);
}
.resource-refresh-btn:active {
  background: var(--mint-6);
  outline: none;
  border-color: var(--gray-4);
}
.resource-refresh-btn:active span {
  color: var(--gray-4);
  font-weight: 500;
}
.resource-refresh-btn:active img {
  filter: brightness(0%);
}
.resource-refresh-btn span {
  width: fit-content;
  height: 22px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-9);
}
