@import url(../../../assets/overrides/select-dropdown.css);

.contactBox,
.liveTypeBox,
.venueBox {
  width: 14%;
}
.txBox {
  width: 14% !important;
}
.ifbBox,
.plBox,
.phonerBox {
  width: 8% !important;
}
.comms_box {
  width: 8% !important;
}
.send_receive_box {
  width: 10%;
}
.startTimeBox,
.endTimeBox,
.hitTimeBox,
.windowBox {
  width: 8%;
}
.canvas-row input {
  width: inherit;
  color: var(--gray-10) !important;
}
.windowtime,
.windowStartTime,
.timeerror,
.venuecontact,
.venuephone,
.hittime {
  pointer-events: none;
}
.comms_box,
.location,
.name,
.tx,
.venuecontact,
.venuename,
.venuephone {
  padding: 0 !important;
}

.comms-ellip {
  display: block;
}
.comm_div {
  height: 100%;
  width: 100%;
  align-items: flex-start !important;
  padding: 12px 16px;
}

.send_receive_box_div {
  display: inline-flex;
}
.send_receive_box {
  width: 8%;
  border-right: 1px solid var(--gray-1);
  border-bottom: 1px solid var(--gray-1);
}

.record_icon {
  margin: 4px 2px;
}
.global_satellite_icon {
  margin-bottom: 2px;
  margin-left: 4px;
}

.warning-cancel-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  width: 65px;
  height: 32px;
  background: var(--gray-2);
  border-radius: 60px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-7);
  border: 2px solid var(--gray-4);
  outline: none;
  cursor: pointer;
}

.error-cancel-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  width: 65px;
  height: 32px;
  background: var(--gray-2);
  border-radius: 60px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-9);
  border: 2px solid var(--gray-4);
  outline: none;
  cursor: pointer;
}
.error-cancel-btn:hover {
  border: 1px solid var(--gray-9);
  color: var(--gray-9);
  background: var(--gray-1);
}
.error-cancel-btn:active,
.error-cancel-btn:focus {
  border: 1px solid var(--rose-6);
  color: var(--rose-6);
  outline: none;
}

.error-warning-icon {
  color: var(--rose-6);
  width: 26px;
  height: 26px;
}
.warning-submit-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  width: 65px;
  height: 32px;
  background: var(--gray-4);
  border-radius: 60px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-9);
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
}
.inbound_div {
  height: 100%;
  width: 100%;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 2px;
  gap: 2px;
}
.record,
.recording {
  width: 0.8em;
  height: 0.8em;
  background-color: transparent;
  border-radius: 50%;
  vertical-align: middle;
  border: 1.5px solid var(--rose-6);
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  top: 2px;
  left: 59%;
  cursor: pointer;
  z-index: 1;
}

.record:checked {
  background-color: var(--rose-6);
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid var(--gray-3) !important;
  outline: 1.3px solid var(--rose-6);
}
.top-canvas-row {
  display: flex;
  flex-direction: row;
  background-color: var(--gray-5);
  height: 40px;
  justify-content: space-between;
  padding: 8px 16px;
}
.top-canvas-row-names {
  width: 33.3%;
  display: flex;
  justify-content: flex-start;
}
.top-canvas-liveshot {
  gap: 16px;
}
.top-canvas-row-names label {
  font-weight: 900 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(--gray-10) !important;
  text-transform: uppercase;
}
.top-canvas-row-pills {
  width: 33.3%;
  display: flex;
  justify-content: center;
}
.comm-pills-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.outbound-pills-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.form-group.window,
.hittime,
.transmission {
  padding-right: 0px;
}
.hidden {
  display: none;
}
.info-tooltip-container {
  display: flex;
  width: 33.3%;
  justify-content: flex-end;
}
.info {
  display: inline-block;
}
.hidden.row {
  display: none;
}
img.update-info-icon {
  width: 20px;
}
.show {
  display: inline-block;
}
.fold_live_shots {
  font-weight: 400 !important;
  padding: 1px 8px 1px 0px;
  display: inline-block;
}
.unfold_live_shots {
  width: 26px;
  height: 26px;
}
.unfold_live_shots svg {
  width: 22px;
  height: 22px;
}
.submit-btn-div {
  padding-top: 8px;
  display: flex;
  flex-direction: row-reverse;
}
.submit-btn {
  background: var(--mint-6);
  border-radius: 12px;
  border: none;
  cursor: pointer;
  width: 100px;
  font-style: normal;
  font-family: "Karla";
  font-weight: 400;
  font-size: 14px;
  color: var(--gray-1);
  line-height: 22px;
}

.submit-btn:disabled {
  background: var(--gray-3);
  color: var(--gray-5);
}

.submit-btn-div .submit-btn:hover,
.submit-btn-div .submit-btn:focus {
  background: var(--mint-7);
  outline: none;
}

.submit-btn:disabled:hover,
.submit-btn:disabled:focus {
  background: var(--gray-3);
  outline: none;
  cursor: not-allowed;
}

input.form-control.location,
input.form-control.name,
input.form-control.transmission {
  background-color: var(--gray-4);
}
.studio textarea {
  font-size: smaller;
  background-color: #21242d;
  border: none;
  resize: none;
  width: 100%;
}
.studio input {
  padding-bottom: 0.175rem;
}
.send_receive textarea.form-control {
  background-color: rgb(33, 36, 45);
  width: 100%;
  border: none;
  resize: none;
}
.alignbtn,
.objectexp {
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.alignbtn {
  gap: 4px;
}

/* remove number input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.poinput {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  width: 95%;
}
.po-div {
  width: 100% !important;
}
.input-label {
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  padding-bottom: 2px;
  line-height: 22px;
  color: var(--gray-7);
}

.window-time,
.hit-time {
  height: 24px;
  background: var(--gray-9) !important;
  color: var(--gray-1) !important;
  border-radius: 4px;
  border: 0px solid transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  width: 100%;
  gap: 8px !important;
  padding: 1px 8px !important;
  text-overflow: ellipsis;
}

.time-error-left {
  height: 24px;
  background: var(--gray-9) !important;
  color: var(--gray-1) !important;
  border-radius: 4px;
  border: 1px solid var(--rose-6) !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  width: 100%;
  gap: 8px !important;
  padding: 1px 8px !important;
  outline: none !important;
  text-overflow: ellipsis;
}

.window-time::placeholder,
.hit-time::placeholder,
.time-error-left:hover::placeholder,
.time-error-left:focus::placeholder,
.window-time:hover::placeholder,
.window-time:focus::placeholder,
.hit-time:hover::placeholder,
.hit-time:focus::placeholder {
  color: var(--gray-7) !important;
}
.window-time:focus,
.hit-time:focus,
.time-error-left:focus,
.window-time:hover,
.hit-time:hover,
.time-error-left:hover {
  outline: none;
  border: none;
  background: var(--gray-10) !important;
}

.noborder {
  border: none !important;
  background-color: #0e1119 !important;
}
.comms textarea.form-control {
  background-color: rgb(33, 36, 45);
  width: 100%;
  border: none;
  resize: none;
}

.name > input {
  width: 100%;
}
.highlight {
  border: 1px solid #ffffff;
}

.takegrabcol {
  width: 11%;
}
td.globalpath-info {
  text-align: left;
}
td.globalpath-info select {
  width: 40%;
  margin: 0px;
}
.window label {
  width: 100%;
}
.window select {
  margin-left: 5%;
  margin-right: 2%;
  width: 60%;
}
.crpath-info img {
  width: 15px;
}
.show-lsinfo-row {
  visibility: visible;
}
.hidden-lsinfo-row {
  visibility: hidden;
}
.name_location-icon {
  width: 30px;
}
.hittime {
  background-color: var(--gray-4);
  border: none;
  outline: none;
  text-transform: uppercase;
  padding-left: 0px;
  color: var(--gray-10);
}
.windowtime {
  background-color: var(--gray-4);
  color: var(--gray-10);
  border: none;
  outline: none;
  text-transform: uppercase;
  padding-left: 0;
}
.window input {
  width: 95%;
  border: none;
  font-size: 16px;
}
input.windowEndTime {
  margin-left: 15px;
}
.form-inline .form-control {
  display: inline-block;
  width: inherit;
  vertical-align: middle;
  text-transform: uppercase;
  text-align: flex-start;
}
.form-inline {
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.25;
  color: white;
  display: flex;
}
.form-control:focus {
  box-shadow: 0 0 0 0 rgba(249, 249, 249, 0.25);
  background-color: #21242d;
  color: #d2e8dee8;
}
.form-group {
  border-right: 1px solid #0a0b10;
  border-bottom: 1px solid #0a0b10;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  padding: 4px 12px !important;
  border-radius: 0px !important;
}
.form-group-hoststudio {
  border-right: 1px solid var(--gray-1);
  border-bottom: 1px solid var(--gray-1);
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  padding: 4px 8px !important;
  align-self: stretch;
  border-radius: 0px !important;
  height: inherit;
  overflow: hidden;
}
.form-group-hoststudio input {
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: var(--gray-9);
  width: 100%;
}
.btns {
  border-radius: 15px;
  font-size: smaller;
  border: none;
  cursor: pointer;
}
input.form-control {
  background-color: var(--gray-4);
  color: white;
}
.btns.edit {
  background-color: #853f35;
  color: white;
  font-weight: 500;
  margin-left: 1em;
  padding: 5px 20px 5px 20px;
  outline: none;
}
.btns.newonset {
  background-color: #31343d;
  color: white;
  font-weight: 500;
  white-space: nowrap;
  padding: 5px 20px 5px 20px;
  cursor: auto !important;
  outline: none;
}
.ls-takeall {
  width: 52px;
}
.ls-takeall,
.ls-graball {
  font-weight: 400;
  white-space: nowrap;
  outline: none;
  font-size: 14px;
  text-align: center;
  height: 24px;
  line-height: 21px;
  border-radius: 60px;
  flex: none;
  align-items: center;
  padding: 1px 8px;
  flex-grow: 1;
}
.ls-graball {
  width: 58px;
}
.ls-takeall-collapsed {
  color: var(--gray-9) !important;
  background-color: var(--gray-1);
  border: 1px solid var(--gray-1);
}
.ls-graball-collapsed {
  color: var(--gray-9) !important;
  background-color: var(--gray-1);
  border: 1px solid var(--gray-1);
}
.ls-takeall-expanded {
  color: var(--gray-7) !important;
  border: 1px solid var(--gray-7);
  background-color: var(--gray-4);
}
.ls-graball-expanded {
  color: var(--gray-7) !important;
  border: 1px solid var(--gray-7);
  background-color: var(--gray-4);
}
.onset {
  color: #ffffff9c;
  font-size: 10px;
  background-color: #484c58;
  text-decoration: none;
  border-radius: 2px;
  border: 10px;
  cursor: pointer;
}
.readyforair {
  border-bottom: 1px solid #0a0b10;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 2px;
}
.canvas-row {
  background-color: var(--gray-4) !important;
  margin-bottom: 0px !important;
  border-top: 1px solid #0a0b10;
  display: flex;
  flex-wrap: nowrap;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
}
.lsinfo-row {
  background-color: var(--gray-4) !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 0px;
  margin-left: 0px;
  padding: 4px 32px 4px 16px;
  gap: 12px;
  border-radius: 0px 0px 4px 4px;
  height: 32px;
  width: 100%;
}
i.fa.fa-angle-down {
  color: white;
}
select {
  font-size: smaller;
  background-color: #21242d;
  color: white;
  border: none;
  outline: none;
  align-self: auto;
}
input.form-control {
  width: 100%;
}

/* .lsinfo-dropdown {
  display: inline-flex;
  background-color: var(--gray-2);
} */
.liveshotstudiocontact,
.transmission,
.technicalnotes,
.liveshothittime,
.hittimeinterval,
.liveshotwindow,
.purchaseOrder,
.lsname,
.citystate,
.btn-addhititme {
  margin-bottom: 5px;
}
.location-input {
  width: 100%;
  border-radius: 5px;
}
.purchaseorder-readonly {
  margin-top: 5px;
  margin-bottom: 10px;
}
.venuecost-input {
  display: flex;
}
.label-datepicker {
  line-height: 22px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  vertical-align: middle;
  color: var(--gray-1);
  font-weight: 400;
  font-size: 14px;
  background: var(--gray-9);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: none;
  border: 1px solid var(--gray-9);
  width: 37px;
}

.exp-input-overlay {
  height: 24px;
  width: 122px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  color: var(--gray-5);
  font-weight: 400;
  font-size: 14px;
  background: var(--gray-9);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: none;
  border: 1px solid var(--gray-9);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: none;
  padding-left: 48px;
  margin-right: 12px;
  padding-top: 0px;
  padding-bottom: 4px;
}

.exp-input-overlay-ls {
  height: 24px;
  width: 122px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  color: var(--gray-5);
  font-weight: 400;
  font-size: 14px;
  background: var(--gray-9);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: none;
  border: 1px solid var(--gray-9);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: none;
  padding-left: 48px;
  margin-right: 12px;
  padding-top: 0px;
}

.exp-input-date {
  height: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  color: var(--gray-1);
  font-weight: 400;
  font-size: 14px;
  background: var(--gray-9);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: none;
  border: 1px solid var(--gray-9);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: none;
  padding-left: 42px;
  margin-right: 12px;
  padding-top: 2px;
  padding-bottom: 3px;
}

.disabled-input-datepicker {
  width: 85px;
  line-height: 22px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  color: var(--gray-1);
  font-weight: 400;
  font-size: 14px;
  background: var(--gray-9);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: none;
  border: 1px solid var(--gray-9);
  margin-right: 12px;
  padding-top: 0px;
}
.exp-date-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.extend-permanetly-btn {
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 1px 8px 1px 7px;
  height: 24px;
  cursor: pointer;
  background: var(--gray-1);
  border-radius: 4px;
  position: relative;
  line-height: 22px;
  left: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid transparent;
  color: var(--gray-9);
  outline: none;
}

.extend-permanetly-btn:hover {
  border: 1px solid var(--gray-9);
  outline: none;
}

.extend-permanetly-btn:focus {
  border: 1px solid var(--gray-1);
  background: var(--gray-1);
  color: var(--gray-9);
  outline: none;
}

.extend-permanetly-btn:active {
  border: 1px solid var(--mint-6);
  background: var(--mint-6);
  color: var(--gray-1);
  outline: none;
}

.extend-permanetly-btn:active .extend-perm-icon {
  fill: var(--gray-1) !important;
}

.extend-permanetly-btn:focus .extend-perm-icon {
  fill: var(--gray-9);
}

.extend-perm-icon {
  vertical-align: middle;
  margin-bottom: 2px;
}
.extendIconActive {
  fill: var(--gray-1) !important;
}
.extend-perm-icon-overlay {
  filter: brightness(0);
  height: auto;
  width: 28px;
  margin-top: 4px;
}

.exp-date-input {
  display: flex;
}
.exp-date-container .react-datepicker__triangle {
  display: none;
}
.extend-perm-text {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 5px;
}

.objectexp .react-datepicker-popper[data-placement^="bottom"],
.objectexp .react-datepicker-popper[data-placement^="top"] {
  left: -118px !important;
  position: relative;
}
.objectexp
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle,
.objectexp
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle {
  margin-left: 70px;
}

.objectexp .react-datepicker__navigation--next {
  right: -30px;
  outline: none;
}
.objectexp .react-datepicker__navigation--next:active,
.objectexp .react-datepicker__navigation--next:hover {
  filter: invert(0%) sepia(96%) saturate(250%) hue-rotate(66deg)
    brightness(108%) contrast(84%);
}

.objectexp .react-datepicker__navigation--previous {
  outline: none;
}
.objectexp .react-datepicker__navigation--previous:active,
.objectexp .react-datepicker__navigation--previous:hover {
  filter: invert(0%) sepia(96%) saturate(250%) hue-rotate(66deg)
    brightness(108%) contrast(84%);
}
.objectexp .react-datepicker {
  height: 308px !important;
  border-radius: 4px !important;
  border-left: 2px solid var(--gray-2);
  border-right: 2px solid var(--gray-2);
  background-color: inherit;

  border-top: none;
  border-bottom: none;
}

.objectexp .react-datepicker__month-container {
  margin-right: -40px;
  height: auto;
  border-radius: 4px;
  border-right: 2px solid var(--gray-2);
  border-top: 2px solid var(--gray-2);
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-left: none;
  border-bottom: none;
  background-color: var(--gray-5);
}

.objectexpextend {
  margin-right: -40px;
  display: flex;
  height: 60px;
  background-color: var(--gray-5);
  border-radius: 4px;
  border-bottom: 2px solid var(--gray-2);
  border-top: 1px solid var(--gray-2);
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-right: 2px solid var(--gray-2);
}

.objectexp .react-datepicker__header {
  border-top-left-radius: 0px;
  border: none;
  background-color: var(--gray-5);
}
.objectexp .react-datepicker__time-container .react-datepicker__time {
  height: 231px;
}
.objectexp .react-datepicker__time-list {
  height: 231px;
}
.objectexp .react-datepicker__time-container {
  border-left: 1px solid var(--mint-6);
}
.hittime input.form-control {
  margin-left: -5px;
  width: 100%;
}
.canvas-row label {
  font-style: normal;
  font-weight: 700;
  font-size: 14px !important;
  display: block;
  align-items: center !important;
  color: var(--gray-7);
  line-height: 18px;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}
input.makeup {
  margin-left: 15px;
  width: 5%;
}
input.chkbox-readonly {
  width: 22%;
}
.hide-checkbox {
  display: none;
}
.liveshotwindow input {
  width: 48%;
  float: left;
}

input.form-control.name {
  border: none;
}
input.form-control.transmission {
  border: none;
}
.transmission {
  float: left;
  width: 100%;
}
input.form-control.location {
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  border: none;
}
input.form-control.send_receive,
input.form-control.comms {
  border: none;
}

.take-grab {
  border: none;
  color: white;
  margin: 1%;
  width: 7em;
  font-size: 12px;
}
.liveshotinfowrap {
  background-color: var(--gray-4);
  padding: 16px 0px 16px 16px;
  border-radius: 0px 0px 4px 4px;
  margin-top: 2px;
}
.liveshotinfo {
  display: flex;
  flex-direction: column;
  background-color: var(--gray-2);
  border-radius: 4px;
  padding: 8px 16px 12px 16px;
}

.liveshotinfo > .field-r,
.field {
  display: flex;
  padding-bottom: 4px;
}

.liveshotinfo > .field-r {
  flex-direction: row;
}

.liveshotinfo > .field {
  flex-direction: column;
}

.mtcdetail-container {
  padding: 0px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mtcdetails {
  background-color: var(--gray-2);
  padding: 8px 16px 12px 16px;
  border-radius: 4px;
}
.mtcdetails select {
  background-color: var(--gray-4);
  width: 70%;
}
.mtcdetails img {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.Outbound-Logo {
  width: 19px !important;
  height: 20px !important;
}
table {
  table-layout: fixed !important;
  width: 100% !important;
}
.table thead th {
  height: 22px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: var(--gray-7);
}
select.preset-select {
  width: max-content;
  margin-right: 6px;
  border: 1px solid #6469768f;
  border-radius: 15px;
  background-color: #31343d;
  color: white;
}
.mtcdetails h5 {
  float: left;
}
.take:focus {
  background-color: var(--mint-6);
  border-radius: 1em;
}
tbody {
  color: #d2e8dee8;
}
.table th,
.table td,
.table thead th,
.table thead {
  border: none;
  border-bottom: none;
  padding: 0rem;
  overflow-wrap: break-word;
  word-break: break-all;
}
.input-group {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.25;
  display: block;
}
.verbars {
  width: 30px;
  float: left;
  height: 139px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 12px 6px;
  background: var(--gray-5);
  border-radius: 30px;
  margin-right: 8px;
}
.verbars svg {
  height: 15px;
  width: 15px;
}
.dupdel-wrapper-hoststudio {
  width: 30px;
  float: left;
  height: 139px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  padding: 12px 6px;
  background: var(--gray-5);
  border-radius: 30px;
  margin-right: 8px;
}
.mtcdetails label {
  /* width: 6em; */
  /* justify-content: left !important; */
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  color: var(--gray-9);
}
.signals-heading {
  display: flex;
  width: 100%;
  align-items: center;
  height: 28px;
}
.receives .grab {
  visibility: hidden;
}
.sends .take {
  visibility: hidden;
}
.phones .ifb-path .take {
  visibility: hidden;
}
label {
  font-size: 15px !important;
  font-weight: bold;
}
.update-info-icon {
  cursor: pointer;
  vertical-align: sub !important;
  fill: var(--gray-7);
}
.update-info-icon:hover {
  fill: var(--gray-9);
}

.comms-labels-summary-row {
  color: var(--gray-7);
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  margin: 0px 10px;
  display: inline-block !important;
  max-width: 65px;
}

.sip-pill-container {
  display: inline-flex;
}

.comms-labels-summary-circle {
  border-radius: 60px;
  padding: 1px 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: var(--gray-4);
  color: var(--gray-9);
  cursor: default;
  height: 24px;
}

.comms-labels-circle-text {
  text-align: center;
  font-family: Karla;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: 92px;
  line-height: 22px;
  text-transform: uppercase;
  cursor: inherit;
  color: var(--gray-10);
}
.comms-labels-circle-text:empty::before {
  content: "----";
  color: var(--gray-7) !important;
}
.update-info-icon-outbound {
  cursor: pointer;
  vertical-align: sub !important;
}
.name_location-bottom,
.input-group,
input .form-comntrol .name {
  width: 100% !important;
  padding: 0;
}

.window-detail-leftpnl > input::placeholder {
  font-size: 11.5px;
}
::placeholder {
  font-size: 14px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
}
.venuename::placeholder,
.location::placeholder,
.venuecontact::placeholder,
.venuephone::placeholder,
.tx::placeholder,
.name::placeholder {
  color: var(--gray-7);
  font-weight: 400;
  font-size: 14px;
}
.venue_tx::placeholder {
  text-transform: capitalize;
}

.startTimeBox,
.endTimeBox,
.hitTimeBox,
.venueBox,
.contactBox,
.txBox,
.ifbBox,
.plBox,
.phonerBox,
.comms_box,
.send_receive_box {
  max-height: 77px !important;
}
.comms_box {
  float: left;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
}
.comms_box span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: var(--gray-9);
  padding-left: 4px;
}
.vertical-line-canvas {
  border-left: 2px solid #de425b;
  height: 70px;
  position: relative;
  margin-left: -3px;
}
.comms_box img {
  margin-left: 4px;
  width: 12px;
  height: 11px;
  margin-bottom: 3px;
}
.comms_box_div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.local_comms_box_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.local_comms_box_div div {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.send_receive {
  text-align: left;
}
.send_receive_box {
  width: 8%;
  border-right: 1px solid var(--gray-1);
  border-bottom: 1px solid var(--gray-1);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
}
.readyforair {
  width: 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  background-color: var(--rose-6);
}
.form-group-hoststudio .recording {
  background-color: var(--rose-6);
  border-radius: 50%;
  width: 6.5px;
  height: 6.5px;
  display: flex;
  margin-top: 0.8px;
  margin-left: 0.8px;
}
.form-group-hoststudio .record {
  border-radius: 50%;
  color: var(--rose-6);
  cursor: pointer;
  z-index: 1;
  border: 1.3px solid var(--rose-6);
  height: 12px;
  width: 12px;
  margin-left: 4px;
}
.venuename,
.location,
.venuecontact,
.venuephone,
.tx,
.name {
  padding: 0px 0px 0px 0px !important;
}

.liveshot-notes-leftpnl,
.hit-notes-leftpnl {
  display: block;
}

.btnstatus {
  color: var(--gray-1);
  font-weight: 900;
  border: none;
  font-size: 14px;
}

.css-1d8n9bt {
  font-weight: 700 !important;
}
.notready-color {
  background-color: var(--gray-4) !important;
  color: var(--gray-10) !important;
}
.notready-color .ls-status-dropdown__indicators,
.readypcr-color .ls-status-dropdown__indicators,
.canceled-color .ls-status-dropdown__indicators,
.ready-color .ls-status-dropdown__indicators {
  background: #ffffff0d;
}
.notready-color svg {
  color: var(--gray-10) !important;
}
.readymtc-color {
  background-color: var(--yellow-1) !important;
  color: var(--gray-3) !important;
}
.readymtc-color .ls-status-dropdown__indicators,
.clear-color .ls-status-dropdown__indicators {
  background: #00000012;
}
.readymtc-color svg {
  color: var(--gray-3) !important;
}
.readypcr-color {
  background-color: var(--blue-2) !important;
  color: var(--gray-10) !important;
}
.readypcr-color svg {
  color: var(--gray-10) !important;
}
.canceled-color {
  background-color: var(--gray-4) !important;
  color: var(--gray-10) !important;
}
.canceled-color svg {
  color: var(--gray-10) !important;
}
.ready-color {
  background-color: var(--green-4) !important;
  color: var(--gray-10) !important;
}
.ready-color svg {
  color: var(--gray-10) !important;
}
.clear-color {
  background-color: var(--blue-7) !important;
  color: var(--gray-3) !important;
}
.clear-color svg {
  color: var(--gray-3) !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: var(--gray-4);
  opacity: 1;
}
.form-group.studio.dropdown.col-sm-3.col-md-3.col-lg-2 {
  padding-left: 5px;
  display: flex;
}
.venuename,
.location,
.venuecontact,
.tx,
.venuephone {
  font-size: unset;
}
.venuename-container,
.location-container,
.venuecontact-container,
.tx-container,
.venuephone-container {
  flex-grow: 1;
  flex-wrap: wrap;
}
input.form-control.location {
  min-width: 60%;
  border: none;
  padding-left: 0px;
  flex-grow: 1;
  text-align: left;
}
input.form-control.venuename {
  border: none;
  flex-grow: 1;
}
input.form-control.venuecontact {
  min-width: 60%;
  border: none;
  flex-grow: 1;
  text-align: left;
  text-transform: uppercase;
}
input.form-control.tx {
  min-width: 70%;
  border: none;
  text-align: left;
}
input.form-control.venuephone {
  min-width: 60%;
  border: none;
  text-align: left;
  flex-grow: 1;
}
input.form-control.venuename:focus,
input.form-control.venuecontact:focus,
input.form-control.tx:focus,
input.form-control.venuephone:focus {
  background-color: var(--gray-4);
}
.objectexp label {
  height: 22px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #7d7d7d;
}
.expadd-date,
.date-picker-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
  gap: 4px;
  width: 24px;
  height: 24px;
  background: var(--gray-5);
  fill: var(--gray-9);
  border-radius: 4px;
  border: 1px solid var(--gray-5);
  outline: none;
  cursor: pointer;
  outline: none;
}
.expadd-date:disabled:active {
  background: var(--gray-5);
}
.date-picker-icon.restricted:active {
  background: var(--gray-5);
  fill: var(--gray-9);
}
.expadd-date:disabled:active img {
  filter: none;
}
.date-exp {
  margin-right: 12px;
}
.date-picker-icon:active {
  fill: var(--gray-1);
  background: var(--mint-6);
}

.date-picker-icon:hover,
.expadd-date:hover {
  border: 1px solid var(--gray-9);
}

.expadd-date img {
  width: 16px;
  height: 16px;
  padding: 0px;
}

.expadd-date:active,
.expadd-date:focus {
  outline: none !important;
}

.expadd-date:active {
  background-color: var(--mint-6);
}

.expadd-date:active img {
  filter: invert(1);
}
.date-picker-selected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
  gap: 4px;
  width: 24px;
  height: 24px;
  background: var(--mint-6);
  border-radius: 4px;
  border: 1px solid var(--mint-6);
  outline: none;
  cursor: pointer;
  margin-right: 12px;
}

.date-picker-selected {
  fill: var(--gray-1);
}
.moveUp,
.moveDown {
  background-color: transparent;
  cursor: pointer;
}
.moveUp:hover svg path,
.moveDown:hover svg path,
.moveUp:active svg path,
.moveDown:active svg path,
.duplicateLS:hover svg path,
.duplicateLS:active svg path,
.remove-liveshot:hover svg path,
.remove-liveshot:active svg path {
  fill: var(--mint-6);
}
.remove-liveshot {
  margin-bottom: -6px;
}
.remove-liveshot svg {
  height: 17px;
  width: 18px;
  fill: var(--gray-9);
}
.notReady-status {
  width: 90%;
  margin: 0;
  background-color: #8a8888;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid;
}
.mtc-status {
  width: 90%;
  margin: 0;
  background-color: #ffffff;
  color: #8a8888;
  font-weight: bold;
  border: 1px solid;
}
.pcr-status {
  width: 90%;
  margin: 0;
  background-color: #2853a7;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid;
}
.air-status {
  width: 90%;
  margin: 0;
  background-color: #00a700;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid;
}
.clear-status {
  width: 90%;
  margin: 0;
  background-color: orange;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid;
}
.cancel-status {
  width: 90%;
  margin: 0;
  background-color: black;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid;
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1300px) {
  .onset {
    font-size: 8px;
  }
  .input-group-addon > img {
    width: 15px;
  }
  .studio > select {
    padding-left: 8px;
    font-size: 11px;
  }
  .type > select {
    padding-left: 8px;
    font-size: 11px;
  }
  .comms {
    max-width: 10%;
  }
  .alignbtn > img {
    width: 14px;
  }
  input.form-control {
    font-size: 15px;
    padding: 2px 1px;
  }
  .mtcdetails {
    margin-left: 10px;
  }
  .take-grab {
    width: 73px;
  }
}
.send_receive {
  padding: 0;
}
.ellip {
  position: absolute;
  bottom: 0px;
}
.send_receive textarea.form-control {
  font-size: 13px;
  width: 100%;
  height: 100%;
  color: #d2e8dee8;
}
.comms textarea.form-control {
  font-size: 13px;
  width: 100%;
  height: 100%;
  color: #d2e8dee8;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .liveshotwindow input {
    float: left;
  }

  .onset {
    font-size: 8px;
  }
  .type {
    border-right: none;
  }
  .alignbtn > img {
    width: 14px;
  }
  .studio > select {
    padding-left: 8px;
    font-size: 12px;
  }
  .type > select {
    padding-left: 8px;
    font-size: 12px;
  }
  .name_location > div > span > img {
    width: 20px;
  }
  input.form-control {
    font-size: 12px;
  }
  .mtcdetails {
    margin-left: 10px;
    padding: 15px 15px 15px 20px;
  }
  .take-grab {
    width: 52px;
  }
  .form-inline {
    font-size: 12px;
  }
}

@media only screen and (min-device-width: 576px) and (max-device-width: 767px) {
  img.update-info-icon {
    width: 29px;
  }
  .liveshothittime {
    width: 35%;
    float: left;
    margin-right: 5px;
  }
  .hittimeinterval {
    width: 45%;
    float: left;
  }
  .liveshotwindow input {
    width: 45%;
    float: left;
  }

  .onset {
    font-size: 6px;
  }
  .alignbtn > img {
    width: 14px;
  }
  .studio > select {
    padding-left: 8px;
    font-size: 10px;
  }
  .type > select {
    padding-left: 8px;
    font-size: 10px;
  }
  .type {
    border-right: none;
  }
  .form-inline {
    font-size: 13px;
  }
  .take-grab {
    width: 60px;
  }
}

.timeerror {
  border: 1px solid var(--rose-6);
  background-color: var(--gray-4);
  font-size: 14px;
  outline: none;
  width: 100%;
  text-align: left;
  border-radius: 4px;
  padding-left: 4px;
}
.placeholder-text {
  color: var(--gray-7) !important;
}
.yellowBorder {
  border: 1px solid #d0b460;
  background-color: #21242d;
}

@media only screen and (max-device-width: 575px) {
  img.update-info-icon {
    width: 26px;
  }
  .liveshotwindow input {
    float: left;
  }
  .alignbtn {
    padding: 1px 1px;
  }
  .alignbtn > img {
    width: 12px;
  }
  .studio > select {
    padding-left: 8px;
  }
  .type > select {
    padding-left: 8px;
  }
  .form-group {
    margin-bottom: 5px;
    border-right: none;
  }
  .lsinfo-margin {
    margin: 0px 0px 0px 0px;
  }
  .name_location > div > span > img {
    width: 15px;
  }
  .mtcdetails {
    max-width: 100%;
    margin-left: 0px;
    margin-top: 10px;
    padding: 10px 10px 10px 20px;
  }
  .take-grab {
    width: 38px;
    font-size: 9px;
  }
  .form-inline {
    font-size: 12px;
  }
  .objectexp > label {
    font-size: 10px;
  }
  .mtcdetails select {
    width: 100%;
  }
  select.preset-select {
    width: max-content;
  }
  .radio-mtcreview div {
    padding: 1px 1px;
  }
}
.liveshotwindow {
  width: 50%;
  float: left;
}
@media only screen and (max-device-width: 375px) {
  .take-grab {
    width: 29px;
    font-size: 7px;
  }
}

@media only screen and (min-device-width: 992px) {
  .liveshothittime {
    width: 35%;
    float: left;
    margin-right: 12px;
  }
  .hittimeinterval {
    width: 50%;
    float: left;
  }
}

@media only screen and (min-device-width: 576px) and (max-device-width: 767px),
  (min-device-width: 992px) {
  @media print {
    div .form-group {
      padding-left: 0.2rem;
      padding-right: 0.2rem;
    }
    .form-group.studio.dropdown.col-sm-3.col-md-3.col-lg-2 {
      padding: 0;
    }
    .form-inline {
      color: black;
    }
    input.form-control {
      color: black;
    }
    input.hittime {
      color: black;
      width: 100%;
    }
    input.windowtime {
      color: black;
      width: 100%;
    }
    input.hittime-border {
      color: black;
    }
    select {
      color: black;
    }
    .form-group {
      border-bottom: none;
    }
    .alignbtn {
      display: none;
    }
    .showdata {
      display: none;
    }
    .onset {
      display: none;
    }
    .hittime {
      width: 8%;
    }
    .name_location-icon {
      display: none;
    }
    .studio {
      width: 25%;
    }
    .studio-top {
      display: block;
      width: inherit;
    }
    .studio-bottom {
      display: block;
      min-width: 80%;
      width: inherit;
    }
    input .form-control.name {
      display: inline;
      line-height: 1rem;
      height: 2rem;
      white-space: normal;
      overflow: hidden;
      width: 100%;
      word-break: normal;
    }
    .location {
      width: 39%;
    }
    .type {
      width: 9%;
    }
    input.form-control.location {
      padding: 0;
      min-width: 50%;
      max-width: 80%;
      text-align: left;
    }
    input.form-control.venuename {
      padding: 0 !important;
      min-width: 50%;
    }
    input.form-control.venuecontact {
      padding: 0;
      min-width: 50%;
      max-width: 80%;
      text-align: left !important;
    }
    input.form-control.tx {
      padding: 0;
      min-width: 70%;
      text-align: left !important;
    }
    input.form-control.venuephone {
      padding: 0;
      min-width: 50%;
      text-align: left !important;
    }
    .maintitle {
      background-color: grey;
    }
    .row {
      margin-bottom: 0px;
    }
    textarea.form-control::-webkit-input-placeholder {
      opacity: 0;
    }
    .save-tech-manager {
      display: none;
    }
    .manager-name label {
      margin-left: 15px;
    }
    i.fa .fa-bars {
      display: none;
    }

    .remove-liveshot {
      display: none;
    }

    .hide-delete {
      display: none;
    }

    .block-actions {
      display: none;
    }

    input.windowStartTime {
      margin-right: 1px;
    }

    input.windowEndTime {
      margin-left: 2px;
    }
  }
}
.ownButton,
.useButton {
  border-style: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 8px;
  gap: 4px;
  width: 49px;
  height: 24px;
  background: var(--gray-5);
  color: var(--gray-9) !important;
  cursor: pointer;
}
.ownButton {
  border-radius: 0px 4px 4px 0px;
}
.useButton {
  margin-left: 18px;
  border-radius: 4px 0px 0px 4px;
}
.useButton:hover,
.ownButton:hover {
  border: 1px solid var(--gray-9);
}
.useButton:focus,
.ownButton:focus {
  outline: none;
}
.useButton:active,
.ownButton:active {
  color: var(--gray-1) !important;
  border: none;
  outline: none;
  background-color: var(--mint-6) !important;
}
.lsInfoIcon {
  color: var(--gray-7);
}
.upIcon,
.downIcon {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
  gap: 4px;
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
.downIcon {
  background: var(--gray-9);
}
.upIcon,
.downIcon:hover,
.downIcon:active {
  background: var(--mint-6);
}
.upIcon:hover,
.upIcon:active {
  background: var(--mint-4);
}
.path-error {
  border: 1px solid var(--rose-6) !important;
  border-radius: 4px !important;
  padding: 1px 0px 1px 6px;
}
.green-btn {
  background: var(--mint-6);
  color: var(--gray-1);
}
.green-btn:hover,
.green-btn:active {
  background: var(--mint-4);
}
.allowed {
  cursor: pointer !important;
}
.top-canvas-row-normalobject {
  display: flex;
  width: 100%;
  flex-direction: row;
  background-color: var(--gray-5);
  min-height: 32px;
  justify-content: space-between;
  padding: 4px 16px;
  border-radius: 4px 4px 0px 0px;
}
.top-canvas-row-names-normalobject {
  width: 38.3%;
  display: flex;
  justify-content: flex-start;
}

.top-canvas-row-names-normalobject label {
  font-weight: 900 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(--gray-10) !important;
  text-transform: uppercase;
  word-break: break-all;
}
.top-canvas-normalliveshot {
  gap: 16px;
}
.top-canvas-row-pills-normalobject {
  width: 31%;
  margin-right: 4%;
  display: inline-flex;
  justify-content: inherit;
  align-items: center;
  gap: 10px 0px;
  flex-wrap: wrap;
  flex: 1 1 31%;
}
.comm-pills-row-normalobject {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.outbound-pills-row-normalobject {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.canvas-row-normalobject {
  background-color: var(--gray-4) !important;
  margin-bottom: 0px !important;
  border-top: 1px solid var(--gray-2);
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}
.canvas-row-normalobject label {
  font-style: normal;
  font-weight: 700;
  font-size: 14px !important;
  display: flex;
  align-items: center !important;
  color: var(--gray-7);
  line-height: 22px;
  text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}
.moveUp:hover svg path,
.moveDown:hover svg path,
.moveUp:active svg path,
.moveDown:active svg path,
.duplicateLS:hover svg path,
.duplicateLS:active svg path,
.remove-liveshot:hover svg path,
.remove-liveshot:active svg path,
.grabber-icon:hover path,
.grabber-icon:active path {
  fill: var(--mint-6);
}
.grabber-icon,
.extend-perm-icon {
  cursor: pointer;
}
.btns.save {
  background-color: var(--gray-4);
  color: var(--gray-10);
  font-weight: 500;
  margin-left: 1em;
  padding: 5px 20px 5px 20px;
  outline: none;
}
.extendiv > button {
  margin-left: 5px;
}
.textlocal {
  text-transform: uppercase;
  color: var(--gray-1);
  font-size: 14px;
  padding: 0px 4px 0px 4px;
  border-radius: 4px;
  border: 2px solid var(--mint-6);
  background: var(--mint-6);
}
.avail {
  width: 12%;
}
.unilat {
  width: 8%;
}
.unilateral,
.extend {
  float: left;
}

.extend:hover {
  border: 1px solid var(--gray-9);
  border-radius: 4px;
}

.extend {
  fill: var(--gray-7);
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.extend:hover {
  border: 1px solid var(--gray-9);
  fill: var(--gray-9);
  border-radius: 4px;
}

.availcheck {
  margin-top: 5px;
  display: none;
}
.availVc {
  border: none;
  color: white;
  width: 7.5em;
  font-size: 10px;
  background-color: #2b3e44;
  cursor: pointer;
  margin-right: 20px;
}
ul.avail-menu {
  border: 1px solid gray;
  border-top: none;
  background-color: #0d111a;
  list-style-type: none;
  padding-left: 0px;
  padding-top: 5px;
  width: 7%;
  height: 4rem;
  overflow-y: auto;
  position: absolute;
  font-size: small;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  z-index: 1;
}
.avail-menu > li:hover {
  background-color: #2b3043;
  cursor: pointer;
}
.avail-menu > li {
  padding-left: 9px;
}
.hide-availVc {
  display: none;
}
.readyforcontrolroom {
  background-color: var(--banana-6);
  text-align: center;
  width: 130px;
}
.readyforairstatus {
  background-color: var(--green-6);
  text-align: center;
  width: 130px;
}
.clearstyle {
  background-color: var(--blue-5);
  text-align: center;
  width: 90px;
}
.readyformediareview {
  background-color: var(--mango-6);
  text-align: center;
  width: 130px;
}
.notreadystyle {
  background-color: var(--rose-6);
  width: 95px;
  text-align: center;
}
.canceledstyle {
  background-color: var(--gray-6);
  width: 95px;
  text-align: center;
}
input.notreadystyle,
input.canceledstyle,
input.readyformediareview,
input.readyforairstatus,
input.readyforcontrolroom,
input.clearstyle {
  width: 100%;
  height: 100%;
}
.comm-outer-div {
  padding-left: 0%;
}
input.notreadystyle:focus,
input.readyforairstatus:focus,
input.readyformediareview:focus,
input.readyforcontrolroom:focus,
input.clearstyle:focus {
  outline: none;
}
i.fa.fa-angle-down {
  color: var(--gray-10);
}
.edit-input {
  border: 0px;
  border-bottom: 0.5px solid gray;
}
.red-border {
  border: 0.5px solid var(--gray-5);
}
.disable-input {
  border: none;
}
.circle_btn {
  font-size: 12px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border: 0.5px solid var(--gray-9);
  border-radius: 50%;
  background-color: var(--gray-3);
  color: var(--gray-9);
  padding: 0;
}
input.form-control.venuename {
  color: var(--gray-9);
}
input.form-control.name {
  color: var(--gray-9);
}
td.globalpath-info1 {
  text-align: left;
}
td.outbound-glpath select {
  width: 50%;
  margin: 0px;
}
td.outbound-crpath select {
  width: 90%;
  margin: 0px;
}

ul.signal-suggestion-list {
  border: 1px solid var(--gray-2);
  background-color: var(--gray-5);
  color: var(--gray-9);
  list-style-type: none;
  padding-left: 0px;
  padding-top: 2px;
  max-height: 200px;
  width: inherit;
  overflow-y: auto;
  position: absolute;
  z-index: 99;
  margin-top: 5px;
  border-radius: 4px 2px 2px 4px;
}
.signal-suggestion-list > li {
  padding: 4px 8px;
  background-color: var(--gray-5);
  border-bottom: 1px solid var(--gray-2);
}
.signal-suggestion-list > li:last-child {
  border-bottom: none;
}
.list-selected {
  background-color: var(--gray-2) !important;
  cursor: pointer;
}
.signal-suggestion-list::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}

.signal-suggestion-list::-webkit-scrollbar-thumb {
  background-color: var(--mint-6);
  border-radius: 4px;
}

.signal-suggestion-list::-webkit-scrollbar-track {
  border-radius: 4px;
  box-shadow: none;
  background-color: var(--gray-4);
}
.send_receive {
  padding: 0;
  font-weight: bold;
  text-align: center;
  font-size: 14px !important;
}
.comms {
  font-weight: bold;
  font-size: 14px;
  margin: auto;
  color: var(--gray-9);
  width: 100%;
  float: left;
}
@media screen {
  .comms-phone {
    display: none;
  }
}
.remove-liveshot {
  cursor: pointer;
}
.hide-delete {
  display: none;
}

@media print {
  .info-section i.fa.fa-bars {
    display: none;
  }

  .transmission {
    width: 9%;
  }

  .comms {
    color: unset;
    font-size: large;
  }
  .comms-phone {
    display: block;
    font-size: medium;
  }
  .readyforair > input {
    color: unset;
  }
  .canceledstyle {
    padding-left: 0;
  }
}

.manage {
  background-color: rgb(73, 71, 68);
  color: white;
  margin-right: 3px;
  border: none;
  cursor: pointer;
}
.input-error .input-field.ant-input {
  border: none !important;
  padding: 0px !important;
}

.input-error {
  vertical-align: middle;
  display: inline-block;
  border: 1px solid var(--rose-6) !important;
  border-radius: 4px !important;
  padding: 0px;
  padding-left: 5px;
  padding-right: 2px;
}

.form-group,
.form-group-hoststudio {
  position: relative;
  cursor: pointer;
  transition: 0.5s ease;
}
.form-group .overlay-icon,
.form-group-hoststudio .overlay-icon {
  display: none;
}
.form-group:hover .overlay-icon,
.form-group-hoststudio:hover .overlay-icon {
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
}
.up-arrow {
  fill: var(--gray-9);
}
