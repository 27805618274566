ul.download-menu {
  margin-top: 140px;
  right: 31px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  grid-gap: 2px;
  gap: 2px;
  width: 248px;
  height: fit-content;
  background: var(--gray-4);
  border-radius: 4px;
  position: absolute;
  z-index: 1;
}

.download-menu > li:hover {
  background-color: var(--gray-2);
  cursor: pointer;
}
.download-menu > li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 8px;
  grid-gap: 10px;
  gap: 10px;
  width: 240px;
  height: 26px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--gray-9);
}

.hide-download {
  display: none;
}
