.search-item {
  margin-bottom: 16px !important;
  text-align: left;
  background: var(--gray-4);
  border-radius: 4px !important;
  padding: 2px;
}
.search-item:last-child {
  margin-bottom: 0px !important;
}
.Liveshot-col {
  display: flex;
}

.col-border {
  border-top: none;
  height: inherit;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0px !important;
}
.col-border-starttime,
.col-border-endtime,
.col-border-hittime {
  border-left: none !important;
}
.col-border-crpath {
  border-right: none;
}

.send_receive_box_div {
  display: inline-flex;
}
.global-box {
  width: 10% !important;
}

.local-box {
  width: 10% !important;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.form-group-global {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  padding: 4px 12px !important;
  width: 10%;
  align-self: stretch;
}

.form-group-hoststudio .recording {
  background-color: var(--rose-6);
  border-radius: 50%;
  width: 6.5px;
  height: 6.5px;
  display: flex;
  margin-top: 0.8px;
  margin-left: 0.8px;
}
.form-group-hoststudio .record {
  border-radius: 50%;
  color: var(--rose-6);
  cursor: pointer;
  z-index: 1;
  border: 2px solid var(--rose-6);
  height: 12px;
  width: 12px;
  margin-left: 4px;
}
.signal-record {
  color: var(--rose-6);
  height: 15px;
  width: 15px;
}
.createliveshotmodal button:focus {
  outline: none;
}
.add-ls {
  background-color: var(--mint-6);
  color: var(--gray-1);
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 1px 12px;
  width: 72px;
  cursor: pointer;
  height: 24px;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-left: auto;
  gap: 4px;
}
.add-ls-new {
  background-color: var(--mint-6);
  color: var(--gray-1);
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 1px 8px 1px 7px;
  cursor: pointer;
  height: 24px;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-left: auto;
  gap: 4px;
}
.add-ls:hover,
.add-ls:focus,
.add-ls-new:hover,
.add-ls-new:focus {
  background: var(--mint-4);
  border: 1px solid transparent;
}

img.addicon {
  padding: 0px 5px 2px 0px;
}
.addicon {
  text-transform: uppercase;
  color: black !important;
}
.reference-info-count {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 8px;
  grid-gap: 4px;
  gap: 4px;
  width: 222px;
  height: 24px;
  background: var(--gray-3);
  border-radius: 4px;
}
.count-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: var(--gray-7);
  display: flex;
  align-items: center;
  text-align: center;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.searchbox_icon {
  width: 22px;
  float: left;
  height: 22px;
}
.label-text {
  color: var(--gray-7);
  font-size: 14px !important;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 0px !important;
  padding: 0px;
}
.playback {
  width: 22px;
  height: 22px;
}
.wordwrap {
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  text-align: left;
  margin-left: 35px;
}
p.searchlocation {
  margin-bottom: 0px;
  margin-top: 1rem;
}
.searchhittime p {
  margin: 0;
  margin-left: 15px;
}
.searchhittime > .tooltip-container {
  padding-top: 1rem;
  margin-left: 50px;
}
.last-used-time {
  text-transform: uppercase;
}
.no-last-used-time {
  color: var(--gray-7) !important;
}
.last-used-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: var(--gray-7);
  display: flex;
  align-items: center;
  text-align: center;
  flex: none;
  flex-grow: 0;
}

.search-tx-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: var(--gray-7);
  display: flex;
  align-items: center;
  text-align: center;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding-left: inherit;
}
.search-info-icon {
  cursor: pointer;
  vertical-align: middle !important;
}
.search-info-icon:hover {
  filter: brightness(0.5) invert(1);
}
.inbound-globalpath {
  margin-top: 8px;
  vertical-align: bottom;
}
.inbound-transmissionPath {
  margin-top: 13px;
  vertical-align: bottom;
}

.tooltip-title-light {
  font-size: 9px;
  text-transform: uppercase;
  color: var(--gray-1);
  text-align: center;
  display: flex;
  align-items: center;
  line-height: 6px;
  font-weight: 400;
}

.tooltip-message-light {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
  width: 106px;
  height: 12px;
  background: var(--gray-10) !important;
  border-radius: 4.74525px;
}
.search-time-placeholder::placeholder {
  color: var(--gray-7) !important;
}
.search-time-placeholder:hover::placeholder {
  color: var(--gray-7) !important;
}

.search-placeholder {
  color: var(--gray-7);
  cursor: not-allowed;
  line-height: 18px;
}

.searchbox-input:disabled,
.use-count:disabled {
  cursor: default !important;
}
.cursor {
  cursor: default;
}
