::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px black;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(54, 56, 58);
}
.trashInfo-container {
  color: var(--gray-3);
  width: 500px;
  height: 208px;
  position: fixed;
  top: 30%;
  left: calc(50% - 250px);
  z-index: 500;
  background-color: var(--gray-2);
  border: 2px solid var(--banana-6);
  border-radius: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.errorInfo-container {
  color: var(--gray-3);
  width: 500px;
  height: 208px;
  position: fixed;
  top: 30%;
  left: calc(50% - 250px);
  z-index: 500;
  background-color: var(--gray-2);
  border: 2px solid var(--rose-6);
  border-radius: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.warning-icon-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  width: 436px;
  height: 28px;
}
.warning-icon {
  width: 26px;
  height: 26px;
}
.warning-headline {
  width: 360px;
  height: 28px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: var(--gray-9);
}
.press-warning-Close-icon {
  position: absolute;
  left: 85.33%;
  top: 8.33%;
  bottom: 8.33%;
}
.warning-Close-icon {
  width: 26px;
  height: 26px;
  cursor: pointer;
}
.warning-Close-icon:hover {
  fill: var(--banana-6);
}
.warning-content {
  width: 436px;
  height: 44px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: var(--gray-9);
}
.warning-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 0px 0px;
  gap: 16px;
  width: 436px;
  height: 40px;
}
.warning-cancel-btn:hover {
  border: 1px solid var(--gray-9);
  color: var(--gray-9);
  background: var(--gray-1);
}
.warning-cancel-btn:active,
.warning-cancel-btn:focus {
  border: 1px solid var(--banana-6);
  color: var(--banana-6);
  outline: none;
}
.press-warning-cancel-btn {
  color: var(--banana-6);
  background: var(--gray-4);
  border-radius: 60px;
  width: 74px;
  height: 32px;
  margin-top: 8px;
  margin-bottom: 4px;
  position: absolute;
  margin-left: 40%;
  margin-top: 28%;
  border: 2px solid var(--banana-6);
}
.warning-submit-btn:hover {
  border: 1px solid var(--gray-9);
}
.warning-submit-btn:active,
.warning-submit-btn:focus {
  border: 1px solid var(--banana-6);
  color: var(--gray-1);
  outline: none;
  background-color: var(--banana-6);
}
.manage-shows-container {
  background-color: var(--gray-2);
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
}
.manage-shows-logo {
  margin-right: 12px;
}
.manage-shows-header-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 16px;
}
.manage-shows-title {
  font-size: 20px !important;
  font-weight: 700;
  color: var(--gray-9);
  line-height: 28px;
  margin-bottom: 0px;
  margin-right: auto;
}
.manage-shows-closeicon {
  cursor: pointer;
  float: inherit;
  fill: var(--gray-9);
}
.manage-shows-closeicon:hover {
  fill: var(--mint-6);
}
.manage-shows-closeicon:focus {
  fill: var(--mint-6);
}
.manage-shows-buttons {
  display: flex;
  margin-top: 32px;
}

.manage-show-search {
  margin-bottom: 16px;
}

.manage-showlist::-webkit-scrollbar {
  width: 8px !important;
}
.manage-showlist::-webkit-scrollbar-track {
  background: var(--gray-3) !important;
  border-radius: 4px !important;
  box-shadow: none;
}
.manage-showlist::-webkit-scrollbar-thumb {
  background: var(--mint-6) !important;
  border-radius: 4px !important;
}
.manage-showlist {
  font-size: 14px;
  background-color: var(--gray-2);
  overflow-y: scroll;
  height: 360px;
  font-weight: 400;
  border-radius: 4px;
  padding-right: 8px;
}
.manage-showlist ul {
  padding: 0px;
}
.manage-showlist-li {
  cursor: pointer;
  list-style: none;
  display: list-item;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  width: 100%;
  padding: 5px 16px;
  height: 32px;
  line-height: 22px;
  font-weight: 300;
  border-top: 1px solid var(--gray-4);
  border-right: 1px solid var(--gray-4);
  border-left: 1px solid var(--gray-4);
  border-bottom: 1px solid var(--gray-2);
  background: var(--gray-4);
  text-transform: uppercase;
  color: var(--gray-9);
}
.manage-showlist-li:hover {
  border-color: var(--gray-9);
}

.manage-showlist-li:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.manage-showlist-li:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.manage-showlist-li:active {
  border-color: var(--gray-3);
  background-color: var(--mint-6);
  color: var(--gray-1);
}
.selected-style {
  border-color: transparent;
  background-color: var(--mint-6);
  color: var(--gray-1);
}
.manage-shows-block {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
}
.manage-right-row {
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
  margin-bottom: 16px;
}
.blankshow {
  display: none;
}
.Button.newshow,
.deleteshow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: inherit;
  padding: 5px 12px 5px 9px;
  gap: 8px;
  width: 180px;
  height: 32px;
  background: var(--gray-4);
  border-radius: 60px;
  border: 1px solid transparent;
  font-size: 14px;
  line-height: 22px;
  color: var(--gray-9);
  cursor: pointer;
  margin-right: 8px;
  outline: none !important;
}
.deleteshow {
  width: 140px;
}
.manage-trash-icon {
  fill: var(--gray-9);
}
.manage-plus-icon {
  fill: var(--gray-9);
}
.deleteshow:active .manage-trash-icon {
  fill: var(--gray-2);
}
.Button.deleteshow:disabled {
  cursor: not-allowed;
  color: var(--gray-9);
}
.Button.newshow:hover,
.Button.deleteshow:hover {
  border-color: var(--gray-9);
}
.Button.newshow:active,
.Button.deleteshow:active {
  background-color: var(--mint-6);
  color: var(--gray-2);
  font-weight: 400;
  border: none;
}
.newshow:active .manage-plus-icon {
  fill: var(--gray-2);
}
.manage-shows-buttons-left {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}
.manage-shows-buttons-right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.manageshowback {
  color: var(--gray-7);
  background-color: var(--gray-2);
  border: 1px solid var(--gray-7);
  font-size: 14px !important;
  border-radius: 60px;
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 8px;
  height: 32px;
  width: 72px;
  font-weight: 400;
  outline: none !important;
}
.manageshowback:hover {
  border: 1px solid var(--gray-9);
  color: var(--gray-9);
}
.manageshowback:focus {
  color: var(--mint-6);
  border: 1px solid var(--mint-6);
}
.manageshowsave {
  color: var(--gray-9);
  background-color: var(--gray-4);
  border: 1px solid var(--gray-4);
  font-size: 14px !important;
  border-radius: 60px;
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 8px;
  height: 32px;
  width: 72px;
  font-weight: 400;
  outline: none !important;
}
.manageshowsave:hover {
  border: 1px solid var(--gray-9);
}
.manageshowsave:active {
  background: var(--mint-6);
  color: var(--gray-1);
  border: 1px solid var(--gray-1);
}
.searchmodal {
  background-color: transparent;
  width: 37%;
  height: 410px;
}
.manageshowdetails {
  background: var(--gray-3);
  width: 61%;
  padding: 24px;
  gap: 3%;
  height: 410px;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
}
.manageshowdetails label {
  color: var(--gray-7);
  font-size: 14px !important;
  line-height: 22px;
  margin-bottom: 2px !important;
}
.manageshowdetails-input,
.manage-time-row input {
  background-color: var(--gray-4) !important;
  border: 1px solid var(--gray-4);
  color: var(--gray-9);
  margin-bottom: 16px;
  border-radius: 4px;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 5px 8px 5px 12px;
  text-transform: uppercase;
}
.manageshowdetails-input:hover,
.manageshowdetails-input:focus-visible,
.manage-time-row input:hover {
  border-color: var(--gray-9);
  outline: none;
}
.manageshowdetails-input:hover::placeholder,
.manage-time-row input:hover::placeholder {
  color: var(--gray-9) !important;
}
.manageshowstarttime input.rc-time-picker-input:focus-visible,
.manageshowendtime input.rc-time-picker-input:focus-visible,
.manageshowdescp:focus,
.manageshowdescp:hover {
  border: 1px solid var(--gray-9);
  outline: none;
}
.manageshowdescp:hover::placeholder {
  color: var(--gray-9) !important;
}
.manage-left {
  width: 46%;
}
.manage-right {
  width: 51%;
}
.description-img-container {
  background: var(--gray-4);
  border-radius: 4px;
  width: 100%;
  height: 182px !important;
}
.description-img-innerdiv {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
}
.description-img {
  max-width: 100%;
  max-height: 88px;
}
.manageshowname,
.slateurl {
  width: 100%;
}

.network_manageshows,
.facility_manageshows {
  width: 47.5%;
}
.separator {
  width: 8%;
  float: left;
  margin-left: 25px;
  margin-top: 20px;
}
.manageshowstarttime,
.manageshowendtime {
  display: block !important;
}
.manage-time-boxes {
  width: 48%;
}
.manage-time-row {
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
}
.occurrence-box {
  width: 100%;
}
button.Button.weekday,
button.Button.weekendday {
  outline: none !important;
  cursor: pointer;
  width: 14.2%;
  border: none;
  background: var(--gray-4);
  color: var(--gray-7);
  font-size: 14px;
  padding: 5px 10px;
  height: 32px;
  line-height: 22px;
}
.weekendday:first-child {
  border-radius: 4px 0px 0px 4px;
}
.weekendday:last-child {
  border-radius: 0px 4px 4px 0px;
}
.weekendday:not(:first-child),
.weekday {
  border-left: 1px solid var(--gray-3) !important;
}
.manageshowdescp {
  background-color: var(--gray-4) !important;
  border: 1px solid var(--gray-4);
  color: var(--gray-9);
  margin-bottom: 16px;
  border-radius: 4px;
  height: 110px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 5px 8px;
  text-transform: uppercase;
  width: 100%;
  resize: none;
}
textarea::placeholder,
input::placeholder {
  color: var(--gray-7);
}
.manageshowdescp::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}

.manageshowdescp::-webkit-scrollbar-thumb {
  background-color: var(--mint-6);
  border-radius: 4px;
  width: 8px;
}

.manageshowdescp::-webkit-scrollbar-track {
  border-radius: 4px;
  box-shadow: none;
  background-color: var(--gray-5);
}
.manageshowoccurrence {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 16px;
}
.manageshows img {
  margin-right: 10px;
}
.canvasnamelist {
  overflow-y: auto;
  font-size: 13px;
  padding: 5px 5px;
}
button.Button.weekday.mon.toggleWeek,
button.Button.weekday.tue.toggleWeek,
button.Button.weekday.wed.toggleWeek,
button.Button.weekday.thur.toggleWeek,
button.Button.weekday.fri.toggleWeek,
button.Button.weekendday.sun.toggleWeek,
button.Button.weekendday.sat.toggleWeek {
  background-color: var(--mint-6);
  color: var(--gray-1);
}
.canvasnamelist ul {
  padding-left: 0px;
  height: 315px;
  line-height: 2.5;
}

.rc-time-picker-panel {
  z-index: 100000;
}
.rc-time-picker-panel-inner {
  background-color: var(--gray-4);
  color: var(--gray-9);
  box-shadow: none;
  border: 2px solid var(--mint-6);
  width: 201px;
  height: 260px;
  left: 1%;
  position: relative;
}
.rc-time-picker-panel-select::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 4px;
  background: #262626;
}
.rc-time-picker-panel-select::-webkit-scrollbar-thumb {
  background: var(--mint-6);
  border-radius: 4px;
}

.rc-time-picker-panel-input-wrap {
  border-bottom: 1px solid var(--gray-1);
  height: 40px;
  align-items: center;
  display: flex;
}
.rc-time-picker-panel-inner input {
  background-color: var(--gray-4);
  line-height: 22px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  text-align: center;
  border: none;
  font-size: 14px;
  color: var(--gray-9);
  font-weight: 700;
}
.rc-time-picker-panel-inner input::placeholder {
  color: var(--gray-9);
  font-size: 14px;
  font-weight: 700;
}
.rc-time-picker-panel-combobox {
  margin: 16px 9px 16px 9px;
}
.rc-time-picker-panel-select {
  max-height: 184px;
  border: none;
}
.rc-time-picker-panel-select:first-child {
  padding-left: 0px;
  width: 61px;
}
.rc-time-picker-panel-select:nth-child(2) {
  width: 67px;
  padding-left: 5px;
}
.rc-time-picker-panel-select:last-child {
  width: 49px;
  padding-left: 4px;
}
.rc-time-picker-panel-select:first-child
  .rc-time-picker-panel-select-option-selected {
  background: #87d1ae !important;
  border-radius: 4px !important;
  width: 34px !important;
  margin-left: 6px;
  padding-left: 10px !important;
  color: #1d1d1d;
}
.rc-time-picker-panel-select:nth-child(2)
  .rc-time-picker-panel-select-option-selected {
  background: #87d1ae !important;
  border-radius: 4px !important;
  width: 34px !important;
  margin-left: 7px;
  padding-left: 8.5px !important;
  color: #1d1d1d;
}
.rc-time-picker-panel-select:last-child
  .rc-time-picker-panel-select-option-selected {
  background: #87d1ae !important;
  border-radius: 4px !important;
  width: 34px !important;
  margin-left: 7px;
  padding-left: 8px !important;
  color: #1d1d1d;
}

.rc-time-picker-panel-select-option-selected:hover {
  border: none !important;
}

.rc-time-picker-panel-select:first-child li:hover {
  background: inherit;
  border-radius: 4px !important;
  width: 34px;
  margin-left: 6px;
  padding-left: 9.1px;
  border: 1px solid #dbdbdb;
  padding-top: 0px;
}

.rc-time-picker-panel-select:nth-child(2) li:hover {
  background: inherit;
  border-radius: 4px !important;
  width: 34px;
  margin-left: 7px;
  padding-left: 8.05px;
  border: 1px solid #dbdbdb;
  padding-top: 0px;
}

.rc-time-picker-panel-select:last-child li:hover {
  background: inherit;
  border-radius: 4px !important;
  width: 34px !important;
  margin-left: 7px;
  padding-left: 8px;
  border: 1px solid #dbdbdb;
  padding-top: 0px;
}

.rc-time-picker-panel-select li {
  padding: 0px 0 0 16px;
  align-items: center;
  display: flex;
}
