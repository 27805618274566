.comms-btn {
  cursor: pointer;
  /* margin-left: 10px;
    margin-top: 2px; */
}

.change {
  /* background-color: green; */
  background-color: #87d1ae;
}

.angle {
  margin-left: 10px;
}

.commPills {
  display: inline-block;
}

.comm-pills-row .pill,
.comm-pills-row-normalobject .pill {
  font-size: 12px;
  line-height: 22px;
  background-color: var(--gray-4);
  color: var(--gray-9);
  font-weight: 400;
  border-radius: 4px;
  /* border: 1px solid var(--gray-1); */
  padding: 1px 4px 1px 8px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: none;
  text-align: center;
  display: flex;
  align-items: center;
  height: 24px;
  width: max-content;
  grid-gap: 8px;
  gap: 4px;
}
.commPills img {
  width: 10px;
  height: 6px;
}
