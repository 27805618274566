.venue-contact-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  border-radius: 4px;
}

.venue-contact-form label {
  color: var(--gray-7);
  font-size: 14px !important;
  line-height: 22px;
  padding-bottom: 2px !important;
  margin: 0px !important;
}

.venue-contact-form .padding-top {
  padding-top: 16px;
}
.venue-contact-form .contact-tab-container {
  display: flex;
  gap: 12px;
}
.venue-contact-form .tab-selector {
  display: none;
  width: 100%;
  background: var(--gray-1);
  height: 32px;
  align-items: center;
  padding-left: 16px;
  padding-right: 8px;
  font-size: 14px;
  color: var(--gray-7);
  border-radius: 8px 8px 0px 0px;
  user-select: none;
  cursor: default;
}
.tab-selector-ctnr {
  display: flex;
  width: 100%;
}
.venue-contact-form .trash-icon-ctnr {
  display: flex;
  cursor: pointer;
  padding-right: 8px;
}
.venue-contact-form .trash-icon-ctnr:hover {
  filter: invert(78%) sepia(30%) saturate(371%) hue-rotate(99deg)
    brightness(185%) contrast(93%);
}
.venue-contact-form .add-contact-ctnr {
  display: flex;
  align-items: center;
}
.venue-contact-form .display-tab {
  display: flex;
}
.venue-contact-form .header-name {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.venue-contact-form .tab-selector.selected {
  background: var(--gray-3);
}
.venue-contact-form .tab-icon {
  cursor: pointer;
  fill: var(--gray-7);
}
.venue-contact-form .tab-icon:hover {
  fill: var(--mint-6);
  font-size: 16px;
}

.venue-contact-form .detail-container {
  background: var(--gray-3);
  padding: 16px 24px 24px;
  border-radius: 0px 8px 8px;
}
.venue-contact-form .detail-container .input {
  width: 100%;
  height: 32px;
  line-height: 22px;
  padding: 5px 8px 5px 12px;
  margin: 0px !important;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: var(--gray-9);
  border: 1px solid var(--gray-4);
  background-color: var(--gray-4) !important;
}

.venue-contact-form .detail-container .input.error,
.venue-contact-form .detail-container .input.error:hover,
.venue-contact-form .detail-container .input.error:focus-visible {
  outline: 1px solid var(--rose-6);
}

.venue-contact-form .detail-footer {
  padding-top: 24px;
}
.detail-footer input {
  float: right;
}

.venue-contact-form .venue-cancel-icon:disabled {
  background-color: var(--gray-3);
  border: 1px solid var(--gray-4);
  color: var(--gray-5);
  cursor: not-allowed;
}
.venue-contact-form .venue-cancel-icon:hover:disabled {
  border: 1px solid var(--gray-4);
}

.venue-contact-form ::-webkit-scrollbar-track {
  box-shadow: var(--gray-3);
  border-radius: 10px;
}
