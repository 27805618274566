.role_dropdown {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--gray-7);
  padding: 5px 8px 5px 12px;
  grid-gap: 8px;
  gap: 8px;
  width: 193px;
  height: 32px;
  background: var(--gray-4);
  border-radius: 4px;
  float: right;
}
