.ErrorCloseIcon {
  cursor: pointer;
  margin-left: auto;
}
.ErrorCloseIcon:hover {
  fill: white !important;
}
.ErrorCloseIcon:focus {
  fill: white !important;
}
.ErrorCloseIcon.warning {
  filter: brightness(0);
}
.blatant-error,
.blatant-warning,
.blatant-success {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 24px;
  gap: 12px;
  height: 40px;
  margin-bottom: 24px;
  flex: none;
  order: 2;
  flex-grow: 0;
  position: absolute;
  z-index: 99999;
  top: 0px;
  left: 0px;
  width: 100%;
}
.blatant-error {
  background: var(--rose-5);
}
.blatant-warning {
  background: var(--banana-6);
}
.blatant-success {
  background: var(--green-6);
}
.blatant-text {
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  color: var(--gray-10);
  font-family: "Karla";
}
.blatant-text-warning {
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  color: var(--gray-1);
  font-family: "Karla";
}

.blatant-icon {
  filter: brightness(100);
  width: 16px;
}
.blatant-icon-warning {
  filter: brightness(0);
  width: 16px;
}

.position-fixed {
  position: fixed;
}
.blatant-warning .refresh_btn {
  height: 26px;
  background: var(--gray-4);
  border-color: transparent;
  border-radius: 4px;
}
.blatant-warning .refresh_btn:focus {
  outline: none;
}
.blatant-warning .refresh_btn:active {
  border: 1px solid transparent;
  background: var(--gray-5);
}
.blatant-warning .tvright-space {
  filter: invert(0%) sepia(100%) saturate(1%) hue-rotate(1deg) brightness(100%)
    contrast(100%);
}
.blatant-warning .refresh_btn:active label,
.blatant-warning .refresh_btn label {
  color: #fff !important;
  user-select: none;
}

.blatant-icons {
  display: flex;
  gap: 16px;
}
