ul.hangUp-menu {
  border: 1px solid gray;
  border-top: none;
  background-color: #0d111a;
  width: inherit;
  font-size: small;
  z-index: 1;
  position: relative;
  padding-left: 0px;
  list-style-type: none;
  overflow-y: auto;
}

ul.li {
  font-size: small;
}

.hangUp-menu > li:hover {
  background-color: #2b3043;
  cursor: pointer;
}
.hangUp-menu > li {
  padding-left: 9px;
}

.hide-hangUp {
  display: none;
}
