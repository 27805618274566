.Notificationbox_container {
  background-color: #21242b;
  color: #b4b5ba;
  width: 46%;
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 99999 !important;
}
.Notificationbox_container > h4 {
  padding: 12px 8px;
  font-size: 22px;
  font-weight: 300;
  text-align: center;
}
.closebtn button {
  color: #fff;
  font-size: 42px;
  font-weight: lighter;
  float: right;
  padding: 5px;
}
.closebtn {
  position: absolute;
  width: 100%;
}
.disconnectbtn button {
  color: #fff;
  font-size: 42px;
  font-weight: lighter;
  float: right;
  padding: 5px;
}

.disconnectbtn {
  position: absolute;
  width: 100%;
}

.clear-msg {
  position: absolute;
  top: 15px;
  left: 10px;
  background-color: #31343d;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 12px;
  outline: none;
  padding: 5px 20px 5px 20px;
}

.notification-container {
  padding: 10px;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
}

.notification-item {
  margin-bottom: 10px;
}
