.Modal {
  position: fixed;
  z-index: 500;
  background-color: #161922;
  width: 70%;
  border: 1px solid #343539;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 15%;
  top: 15%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  box-shadow: 1px -1px 5px 2px #9eadb952;
}

.modal-container-ui {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  inset: 0;
}

@media (min-width: 600px) {
  .Modal {
    width: 500px;
    left: calc(50% - 250px);
  }
}
