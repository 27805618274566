.closeIcon {
  cursor: pointer;
  float: inherit;
  fill: var(--gray-9);
}
.closeIcon:hover {
  fill: var(--mint-6);
}
.closeIcon:focus {
  fill: var(--mint-6);
}
