.shownetwork {
  width: 200px !important;
  border: 1px solid var(--gray-4);
  height: 32px;
  border-radius: 4px;
  line-height: 22px;
  font-weight: 400 !important;
  font-size: 14px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px 5px 12px;
  background-color: var(--gray-4);
  color: var(--gray-7);
}
/* react-select syles; using classNamePrefix "shows-dropdown", for reference check class names in browser dev toold */
/* hover state when menu closed */
.shownetwork:hover {
  border-color: var(--gray-9) !important;
}
.shownetwork:hover .shows-dropdown__placeholder {
  color: var(--gray-9);
}
/* css-tlfecz-indicatorContainer is arrow when menu closed */
.shownetwork:hover .css-tlfecz-indicatorContainer {
  color: var(--gray-9) !important;
}
/* accesing placeholder when hovering and menu is open */
.shows-dropdown__control--is-focused
  .shows-dropdown__value-container
  .shows-dropdown__placeholder {
  color: var(--gray-1) !important;
}
.shows-dropdown__control,
.shows-dropdown__control--menu-is-open {
  background: transparent !important;
  border: none !important;
  color: var(--gray-7) !important;
  width: 175px !important;
  height: 32px !important;
  padding: 0px !important;
  display: contents !important;
  text-align: flex-start !important;
  color: inherit !important;
  caret-color: transparent !important;
}
.shows-dropdown__indicator-separator {
  display: none !important;
}
.shows-dropdown__value-container {
  padding: 0px 0px !important;
}
.shows-dropdown__single-value {
  margin: 0px !important;
  color: inherit !important;
}
.shows-dropdown__placeholder {
  margin-left: 0px;
  margin-right: 0px;
}
.shownetwork .shows-dropdown__placeholder {
  color: var(--gray-9);
}
/* menu list */
.shows-dropdown__menu {
  border-radius: 4px !important;
  background: var(--gray-4) !important;
  color: var(--gray-9) !important;
  font-weight: 400 !important;
  left: -1px;
  width: 432px;
  z-index: 99 !important;
}
.shows-dropdown__menu-list {
  text-transform: uppercase;
  background-color: var(--gray-3) !important;
  border-radius: 4px;
  padding: 0px !important;
}
.shownetwork .shows-dropdown__option {
  align-items: center !important;
  display: flex !important;
  padding: 6px 8px !important;
  background-color: var(--gray-5);
}
.shows-dropdown__option:not(:last-child) {
  border-bottom: 1px solid var(--gray-3);
}
.shows-dropdown__option:hover,
.shows-dropdown__option--is-selected {
  background-color: var(--gray-2) !important;
  align-items: center !important;
  display: flex !important;
  cursor: pointer;
}
/* styling arrow outer and inner div */
.shows-dropdown__indicator,
.shows-dropdown__dropdown-indicator {
  padding: 0px 0px !important;
  color: inherit !important;
  transition: none !important;
}
.grey-bg {
  background-color: var(--gray-4) !important;
}
.green-bg {
  background-color: var(--mint-6) !important;
}
.white-text {
  color: var(--gray-9) !important;
}
.grey-text {
  color: var(--gray-7) !important;
}
.black-text {
  color: var(--gray-3) !important;
  font-weight: 700 !important;
}
.showtime {
  color: var(--gray-7);
  background-color: var(--gray-2);
  border: 1px solid var(--gray-7);
  font-size: 14px !important;
  border-radius: 60px;
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  height: 32px;
  width: 93px;
  font-weight: 400;
  line-height: 22px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.showtime:hover {
  color: var(--gray-9);
  border-color: var(--gray-9);
}
.showtime.selected {
  color: var(--gray-1);
  border-color: var(--gray-1);
  background-color: var(--mint-6);
}
.showtime.selected:hover {
  background-color: var(--mint-4);
}
.show-dropdown-search {
  margin-bottom: 16px;
}
.search-ls-img-input {
  width: 100%;
  padding: 5px 12px 5px 12px;
  line-height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.search-ls-icon {
  cursor: pointer;
  height: 18px !important;
  padding: 2px;
  fill: var(--gray-7);
}
.select-ls-header-row,
.select-ls-buttons-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 16px;
}
.select-ls-buttons-row {
  gap: 15px;
}
.showlist::-webkit-scrollbar {
  width: 8px !important;
}
.showlist::-webkit-scrollbar-track {
  background: var(--gray-3) !important;
  border-radius: 4px !important;
  box-shadow: none;
}
.showlist::-webkit-scrollbar-thumb {
  background: var(--mint-6) !important;
  border-radius: 4px !important;
}
input.searchshow {
  border-bottom: 1px solid gray;
}
.networkdropdown option {
  background-color: #31343d;
}
.showlist {
  font-size: 14px;
  background-color: #1d1d1d;
  overflow-y: scroll;
  height: 296px;
  font-weight: 400;
  border-radius: 4px;
  padding-right: 8px;
}
.showlist ul {
  padding: 0px;
}
.showlist li {
  cursor: pointer;
  list-style: none;
  display: list-item;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  width: 100%;
  padding: 5px 16px;
  height: 32px;
  line-height: 22px;
  font-weight: 300;
  border-top: 1px solid var(--gray-4);
  border-right: 1px solid var(--gray-4);
  border-left: 1px solid var(--gray-4);
  border-bottom: 1px solid var(--gray-2);
  background: var(--gray-4);
  text-transform: uppercase;
  color: var(--gray-9);
}
.showlist li:first-child {
  border-radius: 4px 4px 0px 0px;
}
.showlist li:last-child {
  border-radius: 0px 0px 4px 4px;
}
.showlist li:hover {
  border-color: var(--gray-9);
}
.showlist li:active {
  border-color: transparent;
  background-color: var(--mint-6);
  color: var(--gray-1);
}
.select-ls-showname {
  width: 62%;
}
.select-ls-time {
  width: 140px;
  display: flex;
  justify-content: right;
}
.select-ls-facility {
  width: 140px;
  display: flex;
  justify-content: right;
}
.select-ls-row {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.manage-showlist-li,
.overflow-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
