@import url(../../../assets/overrides/date-picker.css);

.removeAutofillStyle input:-webkit-autofill,
.removeAutofillStyle input:-webkit-autofill:hover,
.removeAutofillStyle input:-webkit-autofill:focus,
.removeAutofillStyle input:-webkit-autofill:active {
  transition: background-color 0s 600000s, color 0s 600000s;
  -webkit-text-fill-color: white;
}

.App-header {
  background-color: var(--gray-1);
  overflow-x: clip;
  display: flex;
  flex-direction: column;
  color: white;
}
.App-header button {
  outline: none;
}
.header-info {
  font-size: 15px;
  font-weight: bold;
  width: 100%;
}

.ant-input-disabled,
.ant-input[disabled] {
  background-color: var(--gray-4);
}
/* block for left and right section */
.homepage-row {
  display: flex;
  flex-direction: row;
  margin: 80px 32px 2px;
  grid-gap: 24px;
  gap: 24px;
}
.liveshot-header {
  width: 40%;
}
.scrolldown_btn,
.fold-btn,
.refresh_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 12px 5px 9px;
  gap: 4px;
  width: 106px;
  height: 32px;
  background: var(--gray-4);
  border-radius: 60px;
  border: 1px solid;
  cursor: pointer;
}
.scrolldown_btn:hover,
.fold-btn:hover,
.refresh_btn:hover {
  border: 1px solid var(--gray-9);
}
.scrolldown_btn:active,
.fold-btn:active,
.refresh_btn:active {
  border: 1px solid var(--mint-6);
  background: var(--mint-6);
}
.scrolldown_btn:active label,
.fold-btn:active label,
.refresh_btn:active label {
  color: var(--gray-1) !important;
}
.scrolldown_btn:active img,
.fold-btn:active img,
.refresh_btn:active img {
  filter: brightness(0%);
}
.scrolldown_btn img,
.fold-btn img,
.refresh_btn img {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.scrolldown_btn label,
.fold-btn label,
.refresh_btn label {
  width: 53px;
  height: 22px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-9);
  margin-bottom: 0px;
  cursor: pointer;
}
.folding label {
  width: fit-content;
}
/* Content labels */
.text-title-labels {
  color: var(--gray-7);
  font-weight: 700;
  display: flex;
  width: fit-content;
}
.required-astrix {
  color: #de425b;
  margin-right: 2px;
}
.label-display-flex {
  flex-direction: row;
  width: 100%;
}
.label-display-flex-controlroom,
.label-display-flex-date,
.label-display-flex-tmname,
.label-display-flex-tmphone {
  flex-direction: row;
  width: 49%;
}

/* Control room section */
.text-title-labels-control {
  color: #7d7d7d;
  font-weight: 700;
  display: flex;
  width: 100%;
}

.top-title-section {
  display: inline-flex;
}
.control-room {
  font-size: 15px;
  display: flex;
  flex-direction: row;
  gap: 2%;
  width: 100%;
}

.control-room > select {
  font-weight: bold;
  font-size: 15px;
  height: 42px;
  width: 10rem;
  padding: 5px 8px 5px 12px;
  margin-left: 10px;
  width: 400px;
  height: 32px;
  background: var(--gray-4);
  border-radius: 4px;
}
.crdetails-btn {
  text-transform: uppercase;
  margin-top: 30px;
  width: 175px;
  margin-left: 12px;
  font-size: 14px;
  background-color: transparent;
  border-radius: 50px;
  padding: 5px 2px 5px 2px;
  color: var(--gray-7);
  border: 1px solid var(--gray-7);
  cursor: pointer;
  font-weight: bold;
}

.crdetails-btn:hover .liveshot-icon {
  filter: invert(3%) sepia(99%) saturate(0%) hue-rotate(236deg) brightness(200%)
    contrast(96%);
}

.crdetails-btn:focus .liveshot-icon,
.crdetails-btn:active .liveshot-icon {
  filter: invert(92%) sepia(5%) saturate(1962%) hue-rotate(89deg)
    brightness(149%) contrast(99%);
}
.control-room-details-btn {
  text-transform: uppercase;
  color: var(--gray-7);
  font-weight: 400;
  font-size: 14px;
}

.crdetails-btn:hover {
  color: var(--gray-9);
  border: 1px solid var(--gray-9);
  cursor: pointer;
}

.crdetails-btn:focus,
.crdetails-btn:active {
  color: var(--mint-6);
  border: 1px solid var(--mint-6);
  cursor: pointer;
}
/* notifications button */
.notificationsbtn {
  margin-left: auto;
  margin-top: 10px;
  margin-right: 40px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

/* select show section */

.select-show-dropdown-container {
  display: flex;
  width: 100%;
}

label.show-not-selected {
  display: flex;
  padding: 0px 0px 5px 0px;
  width: 100%;
  height: 32px;
  cursor: pointer;
  background: var(--mint-6);
  color: var(--gray-1);
  border: 1px solid var(--mint-6);
  font-weight: 400;
  border-radius: 4px;
  flex-direction: column;
}

label.show-not-selected:hover {
  border: 1px solid var(--mint-4);
  background: var(--mint-4);
}

.selected-canvas-set {
  display: flex;
  justify-content: flex-start;
  padding: 5px 16px 5px 16px;
  width: 100%;
  cursor: pointer;
  font-weight: 400;
  border: 1px solid var(--gray-4);
  border-radius: 2px;
  color: var(--gray-9);
  background: var(--gray-4);
}

.selected-canvas-set:hover {
  border: 1px solid var(--gray-9);
}

.select-show-align {
  padding-left: 12px;
  font-weight: 700;
  line-height: 22px;
  margin-top: 4px;
  width: 100%;
}

.selected-canvas-set .canvasname {
  float: left;
  background-color: var(--gray-4);
  color: var(--gray-9);
  text-transform: uppercase;
}

/* date input */
.picker-container {
  position: relative;
}

.calendar-date-picker {
  position: absolute;
  right: 10px;
  z-index: 1;
  margin-top: 8px;
  fill: var(--gray-7);
}

.calendar-date-picker.date-selected {
  fill: var(--gray-9);
  cursor: default;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  display: none;
}

.date-flex:hover.datePickerClose .calendar-date-picker {
  /* filter: invert(97%) sepia(1%) saturate(1949%) hue-rotate(225deg)
    brightness(205%) contrast(72%); */
  fill: var(--gray-9);
}
.date-flex:hover.datePickerClose .calendar-date-picker.date-not-selected {
  cursor: not-allowed;
}

/* show name section */
.tech-mgr-form {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2%;
}
.managerdetails {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
}
.date-value:hover,
.date-disable:hover {
  outline: 1px solid var(--gray-9);
  color: var(--gray-9);
}
.datePickerOpen .calendar-date-picker {
  fill: var(--gray-1);
}

.date-flex .react-datepicker__month-container {
  border: none;
  background-color: var(--gray-5);
}
.date-flex .react-datepicker__navigation--next:active {
  filter: invert(0%) sepia(96%) saturate(200%) hue-rotate(66deg)
    brightness(108%) contrast(84%);
}
.date-flex .react-datepicker__navigation--previous:active {
  filter: invert(0%) sepia(96%) saturate(200%) hue-rotate(66deg)
    brightness(108%) contrast(84%);
}
.date-value:active,
.date-value:focus,
.picker-open {
  background-color: var(--mint-6) !important;
  color: var(--gray-1) !important;
  font-weight: 700 !important;
}

/* first last and phone form section */

.manager-name label,
.canvas-notes label {
  padding-left: 0;
  padding-right: 5px;
}
.manager-name input {
  min-width: 12em;
}
.phone-number input {
  width: 625px;
}

.manager-name,
.phone-number {
  color: var(--gray-9) !important;
}

.phone-number,
.manager-name,
.phone-number-disabled,
.manager-name-disabled {
  display: inline-flex;
  margin: 0px 12px 16px 0px;
  align-items: center;
  padding: 5px 8px 5px 12px;
  grid-gap: 8px;
  width: 100%;
  text-transform: uppercase;
  height: 32px;
  background: var(--gray-4);
  border-radius: 4px;
  border: none;
}
.phone-number-disabled,
.phone-number-disabled,
.manager-name-disabled,
.manager-name-disabled,
.canvas-notes-textarea-disabled {
  cursor: not-allowed;
  width: 100%;
  color: var(--gray-7) !important;
}

.phone-number:hover,
.manager-name:hover,
.phone-number:focus,
.manager-name:focus,
.phone-number:active,
.manager-name:active,
.canvas-notes-textarea:active,
.canvas-notes-textarea:focus {
  outline: 1px solid var(--gray-9);
  transition: none;
}

.date-flex input:focus-visible {
  outline: none;
}

.phone-number label {
  padding-left: 10px;
  padding-right: 5px;
}
.save-tech-manager {
  margin-left: 10px;
}

/* edit button */
.edit-button {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  border: 1px solid var(--gray-7) !important;
  color: var(--gray-7) !important;
  border-radius: 60px;
  width: 49px;
  font-size: 14px;
  height: 32px;
  cursor: pointer;
  display: flex;
}

.edit-button:hover {
  color: var(--gray-9) !important;
  border: 1px solid var(--gray-9) !important;
}
/* canvas notes section */
.canvas-notes > textarea {
  width: 100%;
  height: 120px;
  display: flex;
  margin-left: 0;
  color: var(--gray-9);
  font-size: 14px;
  font-weight: 400;
  resize: none;
  padding: 5px 12px;
  background: var(--gray-4);
  flex: 1 1;
  border-radius: 4px;
  font-family: "Karla";
  border: none;
}
.canvas-notes-textarea:disabled {
  cursor: not-allowed;
}

.canvas-notes-textarea:hover,
.canvas-notes-textarea:focus,
.canvas-notes-textarea:active {
  outline: 1px solid var(--gray-9) !important;
}

.canvas-notes > .canvas-notes-textarea:hover::placeholder {
  color: var(--gray-9);
}

.canvas-notes-textarea-limit-error,
.canvas-notes-textarea-limit-error:hover,
.canvas-notes-textarea-limit-error:focus {
  outline: 1px solid var(--rose-6) !important;
}

.canvas-notes-label {
  font-size: 14px;
  color: var(--gray-7);
  width: 100%;
  padding: 0px;
  display: flex;
  justify-content: flex-start;
  line-height: 18px;
  font-weight: 700;
  margin-bottom: 6px;
}

.canvas-notes > textarea::placeholder,
.canvas-notes-textarea-disabled::placeholder,
.phone-number::placeholder,
.phone-number-disabled::placeholder,
.manager-name::placeholder,
.manager-name-disabled::placeholder {
  color: var(--gray-7);
  text-transform: uppercase;
}
.manager-name-disabled {
  color: var(--gray-9);
}

.canvas-chars {
  position: relative;
  float: right;
  right: 12px;
  bottom: 30px;
  background-color: unset;
  border: unset;
  text-align: right;
  width: 80px;
  height: 18.2px;
}
.canvas-chars:hover {
  background: var(--gray-4);
}

.canvas-notes {
  width: 60%;
}

/* page action icons */
.page-action-icons {
  display: flex;
  width: 100%;
}

.create-btn {
  font-size: 13px;
  border: 1px solid var(--gray-7);
  border-radius: 50px;
  color: var(--gray-7);
  margin-right: 1px;
  padding: 5px 9px 5px 9px;
  background-color: transparent;
}

.flex-btn-container-under-canvas {
  justify-content: flex-end;
}

.notes-save input {
  color: #853f35;
  border: 1px solid #853f35;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  border-radius: 60px;
  width: 49px;
  font-size: 14px;
  height: 32px;
  display: flex;
  cursor: pointer;
}

.react-datepicker__tab-loop {
  display: -webkit-inline-box !important;
}
.addliveshotbtnblock Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--gray-4);
  border-radius: 30px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 3px;
}

.addliveshotbtnblock Button:hover {
  outline: 1px solid var(--gray-9);
}
.addliveshotbtnblock Button:focus {
  outline: none;
}
.addliveshotbtnblock Button:active {
  background: var(--mint-6);
  outline: none;
}
.addliveshotbtnblock Button:hover .add-plus-icon {
  fill: var(--gray-9);
}
.addliveshotbtnblock Button:active .add-plus-icon {
  fill: var(--gray-1);
}

.add-plus-icon {
  fill: var(--gray-9);
}

.manage-shows-buttons .newshow:active .add-plus-icon {
  fill: var(--gray-1);
}

.addliveshotbtnblock .add-plus-icon {
  width: 18px;
  height: 18px;
}

.addliveshotbtn Button,
.addliveshotbtn-disabled Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 12px 5px 9px;
  gap: 4px;
  width: auto;
  height: 32px;
  border-radius: 60px;
  border: 1px solid transparent;
  cursor: pointer;
}
.addliveshotbtn Button {
  background: var(--gray-4);
}
.addliveshotbtn-disabled Button {
  background: var(--gray-3);
}
.addliveshotbtn button:hover {
  border-color: var(--gray-9);
}
.addliveshotbtn button:focus {
  border-color: var(--gray-1);
  background-color: var(--mint-6);
}
.addliveshotbtn button:focus span {
  color: var(--gray-1);
}
.addliveshotbtn button:focus svg {
  filter: brightness(12%);
}
.addliveshotbtn svg,
.addliveshotbtn-disabled svg {
  width: 22px;
  height: 22px;
}

.addliveshotbtn span,
.addliveshotbtn-disabled span {
  width: 94px;
  height: 22px;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
}
.addliveshotbtn span {
  color: var(--gray-9);
}
.addliveshotbtn-disabled span {
  color: var(--gray-5);
}

.cr-dropdown-style {
  width: 305px;
  border: 1px solid var(--gray-4);
  height: 32px;
  text-transform: uppercase;
  border-radius: 4px;
  line-height: 22px;
  font-weight: 400 !important;
  font-size: 14px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--gray-4);
  color: var(--gray-7);
}
.cr-dropdown-style svg {
  justify-content: flex-end;
}
/* hover state when menu closed */
.cr-dropdown-style:hover,
.facilitydropdown:hover {
  border-color: var(--gray-9) !important;
}
.cr-dropdown-style:hover .manage-shows-dropdown__placeholder,
.facilitydropdown:hover .manage-facilities-dropdown__placeholder {
  color: var(--gray-9);
}
/* css-tlfecz-indicatorContainer is arrow when menu closed */
.pill {
  font-size: 12px;
  background-color: #2e323b;
  color: white;
  border-radius: 30px;
  border: 10px;
  padding: 5px 10px 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.print-icon-btn:hover img,
.download-icon-btn.download-btn:hover img,
.download-icon-btn.download-btn.selected:hover img,
.invoice-icon-btn:hover img,
.copy-icon-btn:hover img,
.modify-icon-btn.modify-enabled:hover img,
.keyboard-green-active:hover img,
.keyboard:hover img {
  filter: brightness(0) invert(1);
}

.print-icon-btn:active img,
.download-icon-btn.download-btn.selected img,
.copy-icon-btn:active img,
.modify-icon-btn:active.modify-enabled img,
.download-icon-btn.download-btn:focus-within img,
.invoice-icon-btn:focus img,
.copy-canvas-btn-selected {
  filter: invert(83%) sepia(10%) saturate(1078%) hue-rotate(99deg)
    brightness(93%) contrast(87%);
}

.print-icon-btn,
.download-icon-btn,
.invoice-icon-btn,
.copy-icon-btn,
.modify-icon-btn,
.keyboard,
.keyboard-green-active {
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 22px;
  height: 22px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.print-icon-btn img,
.download-icon-btn img,
.invoice-icon-btn img,
.copy-icon-btn img,
.modify-icon-btn img,
.keyboard img,
.keyboard-green-active img {
  width: 18px;
  height: 18px;
}

.canvas-actions-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 18px;
  gap: 16px;
  width: fit-content;
  height: 32px;
  background: var(--gray-3);
  border-radius: 4px;
}

.canvas-action-tooltip {
  width: fit-content;
}

.triangle-tip-canvas {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid white;
}
.canvas-datepicker-container {
  height: 22px;
}

.tooltip-bottom-canvas-actions {
  display: none;
  top: 100%;
  left: 10px;
  padding-top: 5px;
  transform: translateX(-50%);
  min-width: max-content;
}
.tooltip-message-canvas-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  text-align: center;
  height: 20px;
  border-radius: 10px;
  background: var(--gray-10) !important;
}
.tooltip-title-canvas-actions {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--gray-1);
  text-align: center;
  display: flex;
  align-items: center;
  line-height: 6px;
  font-weight: 400;
}
.createbtn {
  margin: 0px 10px;
}

.keyboard-green-active img {
  filter: invert(83%) sepia(10%) saturate(1078%) hue-rotate(99deg)
    brightness(93%) contrast(87%);
}

.edit-template-green img {
  filter: invert(83%) sepia(10%) saturate(1078%) hue-rotate(99deg)
    brightness(93%) contrast(87%);
}

.stream-deck-icon {
  background-color: #2e323b;
  font-size: 12px;
  color: white;
  border-radius: 50px;

  border: 10px;
  padding: 7px 5px 7px 5px;
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
}

.manageshowsbtn {
  margin: 0px 10px;
}
.icon-selection {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.date-value {
  font-size: 13px;
  border: 10px;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  height: 32px;
  background: var(--gray-4);
  border-radius: 4px;
  font-family: "Karla";
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--gray-9);
}

.date-flex .react-datepicker-wrapper {
  width: 100%;
  left: 100px !important;
  right: 0px;
}
.date-flex .react-datepicker {
  border-radius: 4px !important;
  border: 2px solid var(--gray-2);
}
.date-flex .react-datepicker__header {
  border-top: none;
  background-color: var(--gray-5);
}
.date-disable {
  font-size: 13px;
  margin-right: 2px;
  border: 10px;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px 5px 12px;
  height: 32px;
  background: var(--gray-4);
  border-radius: 4px;
  font-family: "Karla";
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--gray-9);
  cursor: not-allowed;
}

.date-disable::placeholder {
  color: var(--gray-7);
}

.select-show-dropdown-container > select {
  height: 40px;
  font-size: 15px;
  padding-left: 13px;
  line-height: inherit;
  background-color: #21242d;
  color: #d2e8dee8;
}
.fa-file-excel-o:hover {
  cursor: pointer;
}
.create-btn:hover {
  cursor: pointer;
}

.liveshot-btn {
  width: 10em;
  text-align: left;
  margin-top: 5px;
}

.notifications-bell-icon {
  width: 28px;
  text-align: left;
  padding-left: 7px;
  margin-top: 5px;
}

.liveshot-icon {
  margin-left: 0em;
  margin-bottom: 0.2em;
}

.manage-shows-text-margin {
  margin-top: 0.2em;
}
.header-border {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  padding-left: 36px;
  position: absolute;
  right: 240px;
  margin-bottom: 2px;
}
.fa-search {
  cursor: pointer;
}
.tvright-space {
  margin-right: 5px;
  font-size: 15px;
}
.container {
  height: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 5px;
  border-radius: 10px;
}
.container:focus-visible,
.drop-container:focus-visible {
  outline: none;
}
.container-fluid {
  display: flex;
}
.search_icon {
  cursor: pointer;
  margin-top: 5px;
  width: 17px;
}
.style-title {
  background-color: #2b3348;
  color: gray;
  padding: 5px 10px 5px 10px;
  width: 95%;
}
.righticons_wrapper icon {
  cursor: pointer;
}
.notification > i {
  font-size: 18px;
}
.notification {
  cursor: pointer !important;
  padding: 2px 10px;
  background-color: var(--gray-7);
}
.manageshow {
  cursor: pointer;
  display: inline-block;
  width: 36px;
  padding: 0 2px;
}

.fa-tv {
  color: #8490ac !important;
}

.showdate {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
.header-date > span {
  font-weight: 500;
}

.arm-routing-container {
  width: 200px;
  margin-top: 20px;
  margin-right: 20px;
  display: inline-flex;
}
.arm-routing-on-toggle {
  margin-left: 6px;
  padding-top: 2px;
}

.arm-routing-off-toggle {
  margin-right: 6px;
  padding-top: 1px;
}

.arm-routing-on-toggle,
.arm-routing-off-toggle {
  font-style: normal;
  font-size: 15px;
  color: var(--gray-9);
  font-weight: 700;
  display: flex;
  margin-right: 4px;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  line-height: 12px;
}
.arm-routing-span {
  color: var(--gray-7);
}

.Modal.manageshowsmodal {
  position: absolute;
  z-index: 500;
  background-color: var(--gray-2) !important;
  width: 90%;
  height: 590px;
  border: 2px solid var(--mint-6);
  left: 5%;
  top: 5%;
  transition: all 0.3s ease-out;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 8px;
  overflow: hidden;
  padding: 32px;
  box-shadow: none;
}
/* .Modal.showdropdown {
  width: 45%;
  position: fixed;
  z-index: 500;
  top: 9%;
  left: 0%;
} */
.increase-date {
  cursor: pointer;
  margin-top: 4px;
  margin-right: 2px;
  margin-left: 2px;
}
.download-btn,
.download-btn img {
  cursor: pointer;
}

.download-btn-disabled,
.download-btn-disabled img {
  cursor: not-allowed;
}

.print-icon-btn-disabled,
.download-icon-btn-disabled,
.download-btn-disabled img,
.invoice-icon-btn-disabled,
.copy-icon-btn-disabled,
.modify-icon-btn-disabled,
.keyboard-disabled {
  cursor: not-allowed;
  border: none;
  background-color: transparent;
  width: 22px;
  height: 22px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.arm-routing-left-canvas-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  height: 56px;
  width: 300px;
  margin-left: 32px;
}

.left-canvas-top-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  width: 382px;
  height: 56px;
  background: var(--gray-1);
  border: 2px solid var(--gray-4);
  border-radius: 30px;
  /* margin-left: 32px; */
}
.left-canvas-top-actions-inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 350px;
  height: 32px;
}

.canvas-actions-container {
  height: 80px;
  background-color: var(--gray-2);
  padding: 12px 32px;
}
.notifications-sidenav-icon {
  float: right;
  padding: 0 6px;
  cursor: pointer;
}

.routing-toggles.react-toggle {
  vertical-align: middle;
  margin: 0 0.5em 0 0.5em;
}

.react-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  padding: 5px 8px;
  gap: 4px;
  width: 68px;
  height: 32px;
  border-radius: 60px;
  background-color: var(--rose-6);
}

.toggle-notif:hover .react-toggle-track,
.toggle-notif:active .react-toggle-track {
  border: solid 1px var(--gray-9);
}

.toggle-notif:hover .react-toggle-thumb,
.toggle-notif:active .react-toggle-thumb {
  border: solid 2px var(--gray-9);
}

.react-toggle--checked .react-toggle-track {
  background-color: #0c7c59;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  gap: 4px;
  width: 68px;
  height: 32px;
  border-radius: 60px;
}
.routing-toggles:hover .react-toggle-track,
.react-toggle--checked:hover .react-toggle-track {
  border: solid 1px var(--gray-9);
  /* background-color: #0C7C59; */
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 16px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  text-align: center;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  width: 15px;
  bottom: 2px;
  height: 14px;
  left: 10px;
}

.react-toggle-track-x {
  position: absolute;
  width: 16px;
  height: 0px;
  color: var(--gray-9);
  top: 0px;
  bottom: 10px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  left: 10px;
  top: 6px;
  width: 18.33px;
  height: 18.33px;
  border-radius: 50%;
  background-color: var(--gray-3);

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 40px;
  top: 6px;
  background-color: var(--gray-3);
}

.react-toggle--checked .react-toggle-track:focus .react-toggle-thumb,
.react-toggle--checked:active .react-toggle-thumb {
  border: 1px solid var(--gray-9);
}

.react-toggle .react-toggle-track:focus .react-toggle-thumb,
.react-toggle:active .react-toggle-thumb {
  border: 1px solid var(--gray-9);
}

.react-toggle--focus .react-toggle-thumb {
  border-color: solid 1px var(--gray-9);
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  border-color: solid 1px var(--gray-9);
}

.print-action-btn,
.download-action-btn,
.copy-date-action-btn,
.copy-template-action-btn,
.modify-canvas-action-btn,
.add-keybord-action-btn {
  position: relative;
}
.print-action-btn:hover > .tooltip-bottom-canvas-actions,
.download-action-btn:hover > .tooltip-bottom-canvas-actions,
.copy-date-action-btn:hover > .tooltip-bottom-canvas-actions,
.copy-template-action-btn:hover > .tooltip-bottom-canvas-actions,
.modify-canvas-action-btn:hover > .tooltip-bottom-canvas-actions,
.canvas-datepicker-container:hover > .tooltip-bottom-canvas-actions,
.add-keybord-action-btn:hover > .tooltip-bottom-canvas-actions {
  display: block;
}

.copy-date-action-btn:hover + .tooltip-bottom-canvas-actions {
  display: block;
}

@media screen and (max-device-width: 1318px) {
  .homepage-row {
    display: block !important;
  }

  .container {
    height: 100%;
    display: contents;
  }
  .icon-selection {
    margin: 0px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .Modal.manageshowsmodal {
    width: 600px;
    left: calc(45% - 250px);
  }
  .select-show-dropdown-container > select {
    height: 40px;
    font-size: 14px;
    line-height: inherit;
    width: 95%;
  }
  .header-info {
    font-size: 14px;
  }
  .add-liveshot {
    position: absolute;
    z-index: 1;
    top: 38px;
  }
  .managerdetails {
    font-size: 14px;
    padding-left: 30px;
  }
  .hidecursor {
    font-size: 12px;
    padding: 6px 15px 6px 15px;
  }
  .date-value {
    padding: 5px 5px 6px 5px;
  }
  input.create-btn {
    font-size: 12px;
  }
  .print-btn {
    padding: 4px 15px 5px 15px;
  }
  .download-btn {
    padding: 4px 16px 5px 16px;
  }
  .pill {
    font-size: 12px;
  }
  .managerdetails {
    margin-bottom: 10px;
  }
}

.form-field-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 2px;
  grid-gap: 16px;
  gap: 6px;
  width: 100%;
}

@media screen and (min-device-width: 576px) and (max-device-width: 767px) {
  .managerdetails {
    margin-bottom: 10px;
  }
  .Modal.manageshowsmodal {
    width: 550px;
    left: calc(45% - 246px);
  }
  .managerdetails {
    font-size: 13px;
    padding-left: 10px;
  }
  .print-btn {
    margin-left: 10px;
  }
  .select-show-dropdown-container > select {
    width: 95%;
    font-size: 13px;
  }
  .header-info {
    font-size: 13px;
  }
  .create-btn {
    font-size: 10px;
  }
  .create-btn.hidecursor {
    padding: 7px 10px 7px 10px;
  }
  .date-value {
    padding: 3px 5px 7px 5px;
  }
  input.create-btn {
    font-size: 10px;
    padding: 6px 5px;
  }
  .print-btn {
    padding: 6px 9px 5px 9px;
  }
  .download-btn {
    padding: 6px 11px 5px 11px;
  }
  .refresh_btn {
    margin-left: 10px;
  }
  .pill {
    font-size: 10px;
    margin-left: 5px;
  }
  img.notificationicon {
    padding: 6px 10px;
  }
}

@media print {
  .App-header {
    color: black;
  }

  /*  .add-liveshot {
    display: none;
  } */

  .notificationicon {
    display: none;
  }

  .print-btn {
    display: none;
  }

  .download-btn {
    display: none;
  }

  .createbtn {
    display: none;
  }

  .search-btn {
    display: none;
  }

  .header-info {
    display: none;
  }

  .select-show-dropdown-container > select {
    color: black;
  }

  .create-btn {
    color: black;
  }
  .date-value {
    color: black;
  }

  .manageshow {
    display: none;
  }

  .showdate {
    display: none;
  }

  .canvas-chars {
    display: none;
  }

  .copy-template-btn,
  .copy-date-btn,
  .edit-template-green,
  .edit-template-btn {
    display: none;
  }
}

@media screen and (max-device-width: 575px) {
  .Modal.manageshowsmodal {
    height: 450px;
    overflow-y: scroll;
  }
  .managerdetails {
    font-size: 15px;
    padding-left: 10px;
  }
  .create-btn {
    font-size: 10px;
  }
  .create-btn.hidecursor {
    padding: 7px 10px 7px 10px;
  }
  .date-value {
    padding: 3px 5px 7px 5px;
    width: 172px;
  }
  .print-btn {
    margin-left: 5px;
    padding: 6px 9px 5px 9px;
  }
  .pill {
    font-size: 10px;
    margin-left: 10px;
    padding: 5px 10px 5px 10px;
  }
  .select-show-dropdown-container {
    width: 94%;
  }
  .canvasname {
    font-size: 13px;
    padding-left: 0px;
    display: inline-table;
    z-index: 999;
  }
  .canvastimes {
    font-size: 13px;
    z-index: 999;
  }
  .phone-number {
    margin-top: 10px;
    width: 70%;
  }
  .save-tech-manager {
    margin-top: 10px;
    width: 30%;
  }

  .manager-name > label,
  .phone-number > label {
    font-size: 13px !important;
  }
  .header-info {
    font-size: 11px;
    padding: 5px 10px;
  }

  .showdate {
    padding-left: 10px;
    margin-top: 20px;
  }
  .createbtn {
    padding-left: 0px;
    margin-top: 5px;
  }
  img.notificationicon {
    padding: 5px 7px;
  }
  .download-btn {
    padding: 6px 10px 5px 10px;
  }
}

.show-not-selected {
  font-size: 15px;
  background-color: #21242d;
  display: inline-block;
  cursor: pointer;
  color: white;
}

.canvastimes {
  text-align: right;
  z-index: 0;
  margin-left: auto;
  min-width: 150px;
}
.sorticon {
  float: right;
  color: #1d1d1d !important;
  margin-right: 6px;
  margin-top: 4px;
}

.disabled {
  cursor: not-allowed;
}

.blatant-warning-master {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 24px;
  gap: 12px;
  height: 40px;
  margin-bottom: 24px;
  background: var(--rose-5);
  flex: none;
  order: 2;
  position: initial;
  flex-grow: 0;
}
.blatant-warning-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  color: var(--gray-10);
  font-family: "Karla";
}

.warning-icon-blatant {
  filter: brightness(100);
  width: 16px;
}

@media screen and (min-device-width: 992px) and (max-device-width: 1186px) {
  .save-tech-manager {
    margin-left: 10px;
    margin-top: 10px;
  }
}

@media screen and (min-device-width: 992px) and (max-device-width: 1160px) {
  .phone-number {
    margin-top: 10px;
  }
}

.manageshow.manageshowdisable {
  opacity: 0.4;
  cursor: auto;
}

.hide-chevron {
  opacity: 0.4;
  cursor: auto;
  margin-top: 4px;
}
.text-title-labels,
.text-title-labels-control {
  margin-bottom: 0.3rem;
}
