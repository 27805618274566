.venue-close-icon {
  margin: 2px;
  margin-left: 81%;
}
.full-width {
  width: 100%;
}
.width-32 {
  width: 32%;
}
.width-33 {
  width: 33.33%;
}
.margin-left-2percent {
  margin-left: 2%;
}
.margin-right-2percent {
  margin-right: 2%;
}
.displayBlock {
  display: block;
}
.displayNone {
  display: none;
}

.black-text .vm-dropdown-styles__placeholder,
.black-text .vm-dropdown-styles__single-value {
  color: var(--gray-1) !important;
}

.venue-modal-error .popup-modal {
  top: 35% !important;
}
.popup-modal .stcontainer {
  top: calc(20% - 108px);
}

.liveshot-notes {
  resize: none !important;
  overflow: hidden;
  text-overflow: clip;
  background-color: var(--gray-4) !important;
  border: 1px solid var(--gray-4);
  color: var(--gray-9);
  margin-bottom: 16px;
  border-radius: 4px;
  padding-left: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 5px 8px 5px 12px;
  overflow: auto;
}

.liveshot-notes::placeholder {
  color: var(--gray-7);
}

.liveshot-notes::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}

.liveshot-notes::-webkit-scrollbar-thumb {
  background-color: var(--mint-6);
  border-radius: 4px;
  width: 8px;
}

.liveshot-notes::-webkit-scrollbar-track {
  border-radius: 4px;
  box-shadow: none;
  background-color: var(--gray-5);
}
