@import url(../../assets/overrides/select-dropdown.css);

input:-webkit-autofill {
  transition: background-color 0s 600000s, color 0s 600000s;
  -webkit-text-fill-color: white;
}
.studiotruck-contact {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-left: 2px solid var(--rose-6) !important;
  padding-left: 8px;
}
.studiotruck-phone {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-left: 2px solid var(--rose-6) !important;
  padding-left: 8px;
}
.stuck {
  border: 1px solid gray;
  width: 92% !important;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-left: 2px solid var(--rose-6) !important;
  padding-left: 8px;
}
.v-type {
  border: none;
  align-items: center;
  padding: 1px 8px;
  height: 24px;
  background: var(--gray-1);
  border-radius: 0px 4px 4px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown-req {
  border-left: 2px solid var(--rose-6) !important;
}

.dropdown-filled,
.dropdown-focused-bg.dropdown-filled {
  border-left: 2px solid var(--green-6) !important;
}
.modal-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: var(--gray-9);
  padding-left: 12px;
}
.model-title-icons-row {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.venue-top-logo {
  height: 22px;
  width: 22px;
  margin: 2px;
}
.venue-dropdown {
  width: 100% !important;
  border: 1px solid var(--gray-4);
  margin-bottom: 16px;
  height: 32px;
  border-radius: 4px;
  line-height: 22px;
  font-weight: 400 !important;
  font-size: 14px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px 5px 12px;
}
/* hover state in dropdown */
.venue-dropdown:hover {
  border-color: var(--gray-9);
  cursor: pointer;
}
/* accesing react-select styles  */
/* css-1s2u09g-control is a div that contains select text and arrow when menu closed; css-1pahdxg-control when menu is open */
.venue-dropdown:hover .css-1s2u09g-control {
  color: var(--gray-9) !important;
}
.venue-dropdown .css-1s2u09g-control,
.venue-dropdown .css-1pahdxg-control {
  border: none !important;
  background-color: transparent !important;
  width: initial;
  padding: 0px !important;
  display: contents;
  text-align: flex-start !important;
  color: inherit;
  caret-color: transparent;
}
.venue-dropdown .css-1okebmr-indicatorSeparator {
  display: none !important;
}
/* accesing text in select div */
.venue-dropdown .css-319lph-ValueContainer {
  padding: 0px 0px !important;
}
/* css-qc6sy-singleValue  is the text of selected option in select tag */
.venue-dropdown .css-qc6sy-singleValue {
  margin: 0px !important;
  color: inherit !important;
}
/* css-14el2xx-placeholder is the placeholder in select text */
.venue-dropdown:hover .css-14el2xx-placeholder {
  color: inherit !important;
}
.venue-dropdown .css-14el2xx-placeholder {
  color: inherit !important;
  margin-left: 0px;
  margin-right: 0px;
}
/* styling options hover and selected styles */
/* menu list */
.venue-dropdown .css-26l3qy-menu {
  border-radius: 4px !important;
  background: var(--gray-4) !important;
  color: var(--gray-9) !important;
  font-weight: 400 !important;
  left: -1px;
  width: 432px;
  max-height: 290px;
}

.venue-dropdown .css-4ljt47-MenuList {
  box-shadow: 0px 10px 10px var(--gray-2);
}

/* css-1n7v3ny-option is hover in unselected option */
/* css-9gakcf-option is selected option */
.venue-dropdown .css-1n7v3ny-option,
.venue-dropdown .css-9gakcf-option {
  background-color: var(--gray-2) !important;
  width: 98% !important;
  margin-left: 4px !important;
  border-radius: 4px !important;
  padding-left: 8px !important;
  height: 26px;
  line-height: 22px;
  align-items: center;
  display: flex;
}
/* menu list, css-yt9ioa-option access options list not selected not hover  */
.venue-dropdown .css-yt9ioa-option {
  height: 26px;
  line-height: 22px;
  align-items: center;
  display: flex;
}
/* styling arrow outer and inner div */
.venue-dropdown .css-tlfecz-indicatorContainer,
.venue-dropdown .css-1gtu0rj-indicatorContainer {
  padding: 0px 0px !important;
  color: inherit !important;
  transition: none !important;
}
.grey-bg {
  background-color: var(--gray-4) !important;
}
.green-bg {
  background-color: var(--mint-6) !important;
}
.white-text {
  color: var(--gray-9) !important;
}
.grey-text {
  color: var(--gray-7) !important;
}
.black-text {
  color: var(--gray-3) !important;
  font-weight: 700 !important;
}
.venue-modal-label-left {
  width: 51%;
}
.venue-modal-label-right {
  width: 49%;
}
.venue-pin {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}
.vc-container {
  display: inline-flex;
  width: inherit;
}
.vc-container div {
  /* width: 42%; */
  margin-bottom: 0;
}
input.venuepin {
  width: 20%;
}
.suggestions {
  border: 2px solid var(--gray-3);
  color: var(--gray-9);
  z-index: 1;
  background-color: var(--gray-3);
  list-style: none;
  width: 100%;
  padding: 0px;
  max-height: 200px;
  overflow: auto;
  top: 0px;
  position: absolute;
  border-radius: 4px;
}

.scroll-pad {
  padding-right: 2px;
}

.suggestions > li {
  white-space: pre;
}

.suggestions > li:hover,
.selected-list {
  background-color: var(--gray-2) !important;
  cursor: pointer;
}
.fa.fa-phone {
  color: #000 !important;
  margin-right: 2px;
}
button.Button.studiotruckbtn {
  background-color: var(--mint-6);
  color: black;
  border: 1px solid gray;
  cursor: pointer;
  outline: none;
  border-radius: 10px !important;
}
button.Button.studiotruckbtnread {
  background-color: #0d111a;
  color: var(--mint-6);
  border: 1px solid var(--mint-6);
  cursor: pointer;
  outline: none;
  border-radius: 10px !important;
  margin-left: 4px;
}
button.Button.studiotruckbtn.stdelbtn {
  margin-left: 2px;
  width: 22px;
}
button.Button.studiotruckbtn.stdelbtn.disabledelbtn {
  opacity: 0.4;
  cursor: auto;
}
button.Button.studiotruckbtn.staddbtn {
  margin-left: 5px;
}
button.Button.studiotruckbtn:disabled {
  cursor: not-allowed;
}
.venue-modal-error {
  position: absolute;
  z-index: 501;
}
.venue-modal-error .venue-modal-content {
  min-height: 312px;
}
.venue-modal-content {
  background-color: var(--gray-2);
  color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
}
.venue-modal-icons-row {
  display: flex;
  justify-content: flex-end;
  padding-top: 25px;
}
.venue-label-star {
  font-size: 14px;
  color: var(--rose-6);
}
.venue-closeicon {
  width: 22px;
  height: 22px;
  margin: 2px;
  margin-left: 72%;
  cursor: pointer;
  fill: var(--gray-9);
}
.venue-closeicon:hover {
  fill: var(--mint-6);
}
.v-type-select {
  width: 98%;
  border: none !important;
  padding-bottom: 0px !important;
  height: 22px !important;
  background: var(--gray-2) !important;
  vertical-align: middle;
  margin-top: 3px;
}
.stcontainer .btn:disabled {
  opacity: 0.95;
}
.stcontainer .modal-header {
  padding: 0px 5px;
}
.stcontainer {
  height: auto !important;
  width: 700px !important;
  top: 10% !important;
  position: absolute !important;
  border: var(--mint-6) 2px solid !important;
  background-color: var(--gray-2) !important;
  /* color: #ffffff; */
  border-radius: 8px;
  padding: 32px;
  left: calc(50% - 350px) !important;
}

.stcontainer .intl-label {
  color: #ffffff !important;
}
.close-btn {
  position: absolute;
  left: 90% !important;
  margin-top: 5px !important;
  border: 2px solid white !important;
  border-radius: 25px;
  font-size: smaller;
  padding: 2px;
}
.stcontainer .modal-header {
  padding: 0px 5px;
}

.venue-save-icon {
  color: var(--gray-9);
  background-color: var(--gray-4);
  border: 1px solid var(--gray-4);
  font-size: 14px !important;
  border-radius: 60px;
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 8px;
  height: 32px;
  width: 72px;
  font-weight: 400;
}

.venue-save-icon:hover {
  border: 1px solid var(--gray-9);
}
.venue-save-icon:active {
  background: var(--mint-6);
  color: var(--gray-1);
  border: 1px solid var(--gray-1);
}
.venue-save-icon:disabled {
  background-color: var(--gray-3);
  color: var(--gray-5);
  cursor: not-allowed;
  border: none;
}
.venue-save-icon:hover:disabled {
  border: none;
}
.venue-cancel-icon {
  color: var(--gray-7);
  background-color: transparent;
  border: 1px solid var(--gray-7);
  font-size: 14px !important;
  border-radius: 60px;
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 8px;
  height: 32px;
  width: 72px;
  font-weight: 400;
}
.venue-cancel-icon:hover {
  border: 1px solid var(--gray-9);
  color: var(--gray-9);
  background-color: transparent;
}
.venue-cancel-icon:active {
  color: var(--mint-6);
  border: 1px solid var(--mint-6);
}
.checkbox-intl {
  width: 25px !important;
}
.venue-input,
.venue-input-error {
  background-color: var(--gray-4) !important;
  color: var(--gray-9);
  margin-bottom: 16px;
  border-radius: 4px;
  padding-left: 8px;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 5px 8px 5px 12px;
}
.venue-position {
  position: relative;
}
.venue-input {
  border: 1px solid var(--gray-4);
}
.venue-input-error {
  border: 1px solid var(--rose-6);
}
.venue-name-input,
.venuecontactname,
.Studiophone-input,
.Studioemail-input {
  width: 100%;
}
.venue-contactinfo {
  display: flex;
  width: 100%;
  margin-bottom: 8px;
}
.venue_cityState {
  margin-right: 6px;
}
.venue-txpath-field {
  width: 48.5%;
}
.venue-vcid1 {
  width: 48.5%;
  margin-right: 6px;
}
.venue-vcid2 {
  width: 48.5%;
  margin-left: 6px;
}
.venue-po {
  width: 100%;
}
.save-icon:disabled {
  background-color: var(--gray-3);
  color: var(--gray-5);
  cursor: not-allowed;
}
.save-icon:hover:disabled {
  border: 1px solid var(--gray-4);
}
.venue-contact-form .detail-container .input:focus,
.venue-input:focus,
.liveshot-notes:focus {
  outline: none;
  border: 1px solid var(--gray-9);
}
.venue-input-error:focus {
  outline: none;
  border: 1px solid var(--rose-6);
}
.venue-contact-form .detail-container .input:hover,
.venue-input:hover,
.liveshot-notes:hover {
  outline: none;
  border: 1px solid var(--gray-9);
}
.venue-input-error:hover {
  outline: none;
  border: 1px solid var(--rose-6);
}
.venue-contact-form .detail-container .input:hover::placeholder,
.venue-contact-form .detail-container .input:focus::placeholder,
.liveshot-notes:focus::placeholder,
.venue-input:hover::placeholder,
.liveshot-notes:hover::placeholder {
  color: var(--gray-9);
}
.venue-modal-body {
  overflow: auto;
  padding: 10px 20px 0px 0px;
  max-height: calc(100vh - 330px);
}
.venue-modal-content div::-webkit-scrollbar {
  width: 6px;
}
.venue-modal-content div::-webkit-scrollbar-thumb {
  background: var(--mint-6);
}
.venue-modal-content div::-webkit-scrollbar-track {
  border-radius: 4px;
  box-shadow: none;
  background-color: var(--gray-4);
}
.venue-modal-body label {
  margin-bottom: 0 !important;
  color: var(--gray-7);
  font-size: 14px !important;
  line-height: 22px;
  margin-bottom: 2px !important;
}

.stcontainer .modal-footer {
  padding: 5px 8px;
}
.liveshotstudiophone {
  width: 100%;
  float: left;
  margin-bottom: 5px;
}
.liveshotstudiocontact {
  clear: both;
  margin-top: 5px;
  width: 100%;
  display: flex;
  border-radius: 5px;
}
.contactInput {
  width: 48%;
  margin-right: 2%;
  border-radius: 5px !important;
  border-left: #d0b460 !important;
}
.liveshotstudio {
  width: 100%;
  float: left;
}
.liveshotstudio > .field,
.studio-contact > .field {
  display: flex;
  flex-direction: column;
}

i.fa.fa-phone {
  color: var(--gray-10);
  margin-left: 1em;
}
.cancelstudiobtn {
  margin-left: 2% !important;
}
.liveshotstudio label {
  display: block;
  background-color: gray;
  margin-bottom: 3px;
  padding-left: 3px;
}

.liveshotstudio input {
  width: 100%;
  margin-right: 16px;
}
.button-bg {
  background-color: var(--mint-6) !important;
  color: #000 !important;
}
.button-bg-readonly {
  border-color: var(--mint-6) !important;
  color: var(--mint-6);
}
.liveshotstudiorate {
  width: 100%;
}
.liveshotstudiorate > input {
  height: fit-content;
}
.venuecost {
  width: 36% !important;
  display: flex;
  margin-right: 2px;
  border-radius: 5px;
}
.circle {
  height: 25px;
  width: 25px;
  border: 1px solid white !important;
  border-radius: 50%;
  display: inline-block;
}
.Venuecost {
  background-color: #0e1119 !important;
  color: #ffff;
  width: 25% !important;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid gray;
}
.studio-truck-rate {
  width: 50%;
  margin-bottom: 2px !important;
}

.hourrate {
  margin-bottom: 2px !important;
}
.studionotes input {
  width: 100% !important;
}

.liveshotinfo .checkbox-intl {
  margin-left: 2px;
}
.intl-label {
  color: #000;
  margin-left: 3px;
}
.liveshotstudiophone .Button {
  font-size: 11px;
}

.studio-contact > div {
  padding-bottom: 4px;
}

.tx-number-container {
  padding-bottom: 8px;
}

.venue-contact-name {
  padding-bottom: 8px;
}

.venue-contact-phone {
  padding-bottom: 7px;
}

.side-live-contact {
  padding-bottom: 8px;
}

@media only screen and (max-device-width: 575px) {
  .liveshotstudio {
    margin-bottom: 5px;
    width: 100%;
    float: none;
  }
  .liveshotstudiophone {
    margin-bottom: 2px;
    width: 75%;
    float: left;
    margin-bottom: 5px;
  }
  .liveshotstudiophone input {
    width: 80%;
  }

  button.Button.studiotruckbtn.stdelbtn {
    margin-left: 2px;
    width: 22px;
  }
  button.Button.studiotruckbtn.staddbtn {
    margin-left: 10px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .liveshotstudio {
    margin-bottom: 5px;
    width: 100%;
    float: none;
  }
  .liveshotstudiophone {
    margin-left: 0px;
    width: 79%;
    float: left;
    margin-bottom: 5px;
  }
  .liveshotstudiophone input {
    width: 75%;
  }
  button.Button.studiotruckbtn {
    margin-left: 1px;
    margin-bottom: 5px;
  }
}
@media only screen and (min-device-width: 992px) and (max-device-width: 1300px) {
  .liveshotstudio {
    width: 100%;
  }
  .studio-contact {
    display: inline-flex;
    width: inherit;
  }

  .liveshotstudiophone {
    clear: both;
    margin-bottom: 5px;
    width: 100%;
    float: left;
  }
  button.Button.studiotruckbtn {
    margin-left: 10px;
    margin-bottom: 3px;
  }
  button.Button.studiotruckbtn.stdelbtn {
    margin-left: 5px;
    width: 22px;
  }
  button.Button.studiotruckbtn.staddbtn {
    margin-left: 10px;
  }
}

button.Button.studioAddBgd {
  /* background-color: green; */
  background-color: var(--mint-6);
}
.studioTruckNotes {
  background-color: var(--gray-3) !important;
  width: 100%;
  height: inherit;
  color: var(--gray-9) !important;
  border-radius: 4px !important;
  border-color: var(--gray-3) !important;
  padding: 1px 8px !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-overflow: ellipsis;
  resize: none;
  overflow: auto;
}
.studioTruckNotes:disabled {
  cursor: not-allowed;
}
.venueContactInput,
.venueTxInput {
  overflow: auto !important;
}
.studioTruckNotes::-webkit-scrollbar,
.venueContactInput::-webkit-scrollbar,
.venueTxInput::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}

.studioTruckNotes::-webkit-scrollbar-thumb,
.venueContactInput::-webkit-scrollbar-thumb,
.venueTxInput::-webkit-scrollbar-thumb {
  background-color: var(--mint-6);
  border-radius: 4px;
  width: 8px;
}

.studioTruckNotes::-webkit-scrollbar-track,
.venueContactInput::-webkit-scrollbar-track,
.venueTxInput::-webkit-scrollbar-track {
  border-radius: 4px;
  box-shadow: none;
  background-color: var(--gray-4);
}

.studioTruckNotes:hover,
.studioTruckNotes:focus {
  border: none !important;
  outline: 1px solid var(--gray-9) !important;
  border-radius: 4px 4px 4px 4px !important;
}
.studioTruckNotes:hover::placeholder,
.studioTruckNotes:focus::placeholder {
  color: var(--gray-9) !important;
}
.studioTruckNotes::placeholder {
  color: var(--gray-7) !important;
}
textarea:focus {
  outline: none !important;
}

.liveshot-type {
  background-color: rgb(13, 17, 26);
  width: 100%;
  margin-bottom: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  /* border-left: 4px solid green !important; */
  border-left: 4px solid var(--mint-6) !important;
}

.venue-country-container {
  display: flex;
  flex-direction: row-reverse;
}

.dropdown-focused-bg {
  background-color: var(--gray-1);
  color: var(--gray-9) !important;
  font-weight: 700 !important;
  border: 1px solid var(--gray-9) !important;
  border-left: 2px solid var(--rose-6) !important;
  border-radius: 0px 4px 4px 0px;
}

.info {
  display: inline-block;
  font-weight: 400;
}

.wrap-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 5px;
}

.venue-placeholder {
  display: inline-block;
  font-weight: 400;
  color: var(--gray-7);
}

.v-type:hover .venue-placeholder,
.dropdown-focused-bg .venue-placeholder {
  color: var(--gray-9) !important;
}

.list-item-bg {
  background-color: var(--gray-2);
  border-radius: 4px;
  padding-left: 6px;
  margin-left: 2px;
  margin-right: 2px;
}

.ul-list-item-container {
  list-style: none;
  padding: 8px 0px 8px 0px;
  border-radius: 4px;
  overflow-wrap: break-word;
  margin: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 10;
}
.ul-list-item {
  padding: 5px;
  font-weight: 400;
  font-size: 14px;
  color: var(--gray-9);
  background-color: var(--gray-5);
  border-bottom: 1px solid var(--gray-2);
}

.ul-list-item:hover {
  background-color: var(--gray-2);
  cursor: pointer;
}

.ul-list-item:first-child {
  border-radius: 4px 4px 0px 0px;
}

.ul-list-item:last-child {
  border-radius: 0px 0px 4px 4px;
}

.v-type {
  width: 100%;
  font-size: 14px;
  padding-left: 8px;
  height: 24px;
  color: var(--gray-9);
}
i.fa.fa-angle-down {
  color: white;
}

.v-type:hover {
  border: 1px solid var(--gray-9);
  color: var(--gray-9);
}

.venuedrop {
  position: relative;
  float: right;
  margin-right: 2%;
  margin-top: 1%;
}
.vtype-container {
  display: flex;
}

.v-type:hover .dropIcon {
  fill: var(--gray-9) !important;
}
.dropIcon {
  fill: var(--gray-9);
}

.close_btn {
  cursor: pointer;
  height: 22px;
  width: 7%;
  float: right;
  margin-left: 80%;
  margin-top: 12px;
  position: absolute;
  color: #dbdbdb !important;
}

.close-sidebar-btn {
  cursor: pointer;
  height: 22px;
  width: 7%;
  float: right;
}
.venue_tx {
  width: 48.5%;
  margin-right: 6px;
}
.window-detail-leftpnl,
.venue-vc-container {
  display: flex;
  gap: 16px;
  padding-bottom: 4px;
}

.Skype-input,
.Quicklink-input,
.Web-input {
  height: 24px;
  background: var(--gray-1) !important;
  color: var(--gray-9) !important;
  border-radius: 4px;
  width: 100%;
  gap: 8px !important;
  padding: 1px 8px !important;
}
.venue-type-container > .field,
.venue-country-container > .field {
  flex-direction: column;
}

.venue-type-container > .field,
.venue-type-container > .field-r {
  padding-bottom: 4px;
}

.venue-country-leftpnl {
  width: 100%;
}

.venue-clear-button {
  padding-left: 16px;
  display: flex;
  flex-direction: column-reverse;
}

.venue-city-container {
  display: flex;
}

.venue-city-leftpnl {
  padding-right: 8px;
  width: 100%;
}
.venue-region-leftpnl {
  padding-left: 8px;
  width: 100%;
}

.contact-icon {
  position: absolute;
  right: 26px;
  padding-top: 4px;
  z-index: 1;
}
.arrow-align {
  position: absolute;
  right: 24px;
  padding-top: 7px;
  z-index: 1;
}
.contact-container {
  display: inline-grid;
}
