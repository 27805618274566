.react-datepicker__navigation--next {
  background: url(../../assets/icons/calendar-next-icon.svg) no-repeat;
  width: 15px;
  height: 15px;
  padding: 10px;
  outline: none;
  cursor: pointer;
  border: none;
}

.react-datepicker__navigation--previous {
  background: url(../../assets/icons/calendar-prev-icon.svg) no-repeat;
  width: 15px;
  height: 15px;
  padding: 10px;
  cursor: pointer;
  outline: none;
  border: none;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  color: var(--mint-6);
  border-bottom-color: var(--mint-6);
}
.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #dbdbdb;
  font-weight: bold;
  border-bottom: solid 1px #1d1d1d;
  font-size: 0.944rem;
  padding-bottom: 6px;
}

.datepicker-calendar-icon {
  padding-right: 50px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #dbdbdb;
}

.react-datepicker__day--disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  background-color: transparent;
}

.react-datepicker__input-container {
  display: flex;
}

.react-datepicker__header {
  background-color: #303030;
  color: #dbdbdb;
  border-bottom: 1px solid #1d1d1d;
  background-color: var(--gray-5);
}

.react-datepicker__day--selected {
  background-color: #87d1ae;
  color: #1d1d1d;
  font-weight: 400;
}
.react-datepicker__day--selected:hover {
  background-color: var(--mint-5) !important;
  color: #1d1d1d;
  border: none !important;
}

.react-datepicker__day:hover {
  border: solid 1px #dbdbdb;
  margin-top: 0;
  padding: 0;
  margin-bottom: 0;
  background-color: var(--gray-5);
}

.react-datepicker__month-container {
  color: #dbdbdb;
  font-size: 1rem;
  font-family: "Karla";
  border: none;
  background-color: var(--gray-5);
}

.react-datepicker__month-text {
  display: inline-block;
  margin: 0.5rem;
  font-size: 1rem;
  padding: 0.2rem;
  width: 5rem;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: var(--mint-6);
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow
  .canvas-datepicker-container
  .react-datepicker {
  right: 27px;
  border: 2px solid var(--gray-2);
}
.canvas-datepicker-container
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle {
  margin-left: 43px;
}
.canvas-datepicker-container
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle {
  border-top-color: var(--mint-6);
  margin-left: 43px;
}

.canvas-datepicker-container
  .react-datepicker-popper[data-placement^="bottom"] {
  margin-left: 0px !important;
  left: -50px !important;
}

.canvas-datepicker-container
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle {
  margin-left: 65px;
}

.canvas-datepicker-container .react-datepicker {
  border: 2px solid var(--gray-2);
}

.react-datepicker__navigation--next:active,
.react-datepicker__navigation--previous:active,
.react-datepicker__navigation:hover {
  filter: invert(0%) sepia(96%) saturate(250%) hue-rotate(66deg)
    brightness(108%) contrast(84%);
}

.canvas-datepicker-container .react-datepicker__triangle {
  display: none;
}
