.popup-modal {
  background-color: #21242d;
  color: #21242d;
  height: 180px;
  width: 500px;
  position: fixed;
  top: calc(50% - 108px);
  left: calc(50% - 250px);
  z-index: 500;
}
.close {
  color: #000000 !important;
}
.modal-content.signal-msg-content-box {
  z-index: 9999;
  cursor: auto;
}
.successMsg {
  color: green;
}
.failureMsg {
  color: #d64020;
}

.delete-submit-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  width: 65px;
  height: 32px;
  background: var(--gray-4);
  border-radius: 60px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-9);
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
}
.delete-submit-btn:hover {
  border: 1px solid var(--gray-9);
  color: var(--gray-9);
  background-color: var(--gray-4);
}
.delete-submit-btn:active,
.delete-submit-btn:focus {
  border: 1px solid var(--rose-6);
  color: var(--gray-1);
  outline: none;
  background-color: var(--rose-6) !important;
}
.warning-popup {
  position: absolute;
  border: 2px solid var(--rose-6) !important;
  top: calc(50% - 108px) !important;
}
.warning-btn {
  width: 83px !important;
}

@media screen and (max-device-width: 575px) {
  .popup-modal {
    margin-left: 20%;
    width: 350px;
  }
}
