.controlroomdropdown,
.venue-dropdown {
  width: 100%;
  border: 1px solid var(--gray-4);
  height: 32px;
  border-radius: 4px;
  line-height: 22px;
  font-weight: 400 !important;
  font-size: 14px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px 5px 12px;
  background-color: var(--gray-4);
  color: var(--gray-7);
}

.createCanvasDropdown {
  width: 100%;
  border: 1px solid var(--gray-4);
  height: 32px;
  border-radius: 4px;
  line-height: 22px;
  font-weight: 400 !important;
  font-size: 14px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px 5px 6px;
  background-color: var(--gray-4);
  color: var(--gray-7);
}

.networkdropdown {
  width: 100% !important;
  border: 1px solid var(--gray-4);
  height: 32px;
  border-radius: 4px;
  line-height: 22px;
  font-weight: 400 !important;
  font-size: 14px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px 5px 12px;
  background-color: var(--gray-4);
  color: var(--gray-7);
}
/* hover state when menu closed */
.networkdropdown:hover,
.networkdropdown:focus-within,
.facilitydropdown:hover,
.facilitydropdown:focus-within,
.controlroomdropdown:hover,
.createCanvasDropdown:hover,
.venue-dropdown:hover {
  border-color: var(--gray-9) !important;
}

/* hover state when menu closed */
.createCanvasDropdown:hover {
  border-color: var(--gray-9) !important;
}
.createCanvasDropdown:hover {
  color: var(--gray-9);
}
.networkdropdown:hover .manage-shows-dropdown__placeholder,
.createCanvasDropdown:hover .create-canvas-dropdown__placeholder,
.facilitydropdown:hover .manage-facilities-dropdown__placeholder {
  color: var(--gray-1);
}

.controlroomdropdown .cr-dropdown-styles__placeholder {
  color: var(--gray-7);
}

.venue-dropdown:hover .vm-dropdown-styles__placeholder,
.grey-bg:hover .vm-dropdown-styles__indicator svg {
  color: var(--gray-9);
}

.venue-dropdown__control--menu-is-open
  .vm-dropdown-styles__value-container
  .vm-dropdown-styles__placeholder {
  color: var(--gray-1) !important;
}

/* css-tlfecz-indicatorContainer is arrow when menu closed */
.networkdropdown:hover .css-tlfecz-indicatorContainer,
.facilitydropdown:hover .css-tlfecz-indicatorContainer {
  color: var(--gray-9) !important;
}
/* REACT SELECT STYLE */
/* react-select syles; using classNamePrefix "manage-shows-dropdown", for reference check class names in browser dev tools */
/* accesing placeholder when hovering and menu is open */
.manage-shows-dropdown__control--is-focused
  .manage-shows-dropdown__value-container
  .manage-shows-dropdown__placeholder {
  color: var(--gray-1) !important;
}
.manage-facilities-dropdown__control--is-focused
  .manage-facilities-dropdown__value-container
  .manage-facilities-dropdown__placeholder {
  color: var(--gray-1) !important;
}

.manage-shows-dropdown__control,
.manage-shows-dropdown__control--menu-is-open,
.cr-dropdown-styles__control,
.cr-dropdown-styles--menu-is-open,
.create-canvas-dropdown__control,
.create-canvas-dropdown--menu-is-open,
.vm-dropdown-styles__control,
.vm-dropdown-styles--menu-is-open {
  background: transparent !important;
  border: none !important;
  color: var(--gray-7) !important;
  width: 175px !important;
  height: 32px !important;
  padding: 0px !important;
  display: contents !important;
  text-align: flex-start !important;
  color: inherit !important;
  border: none !important;
  box-shadow: none !important;
  caret-color: transparent !important;
}

.manage-facilities-dropdown__control,
.manage-facilities-dropdown__control--menu-is-open,
.cr-dropdown-styles__control,
.cr-dropdown-styles--menu-is-open,
.create-canvas-dropdown__control,
.create-canvas-dropdown--menu-is-open,
.vm-dropdown-styles__control,
.vm-dropdown-styles--menu-is-open {
  background: transparent !important;
  border: none !important;
  color: var(--gray-7) !important;
  width: 175px !important;
  height: 32px !important;
  padding: 0px !important;
  display: contents !important;
  text-align: start !important;
  color: inherit !important;
  caret-color: transparent !important;
}
.manage-shows-dropdown__indicator-separator,
.manage-facilities-dropdown__indicator-separator,
.cr-dropdown-styles__indicator-separator,
.create-canvas-dropdown__indicator-separator,
.vm-dropdown-styles__indicator-separator,
.ls-status-dropdown__indicator-separator {
  display: none !important;
}
.manage-shows-dropdown__value-container,
.manage-facilities-dropdown__value-container,
.cr-dropdown-styles__value-container,
.vm-dropdown-styles__value-container {
  padding: 0px 0px !important;
}
.manage-shows-dropdown__single-value,
.manage-facilities-dropdown__single-value,
.cr-dropdown-styles__single_value,
.create-canvas-dropdown__single_value,
.vm-dropdown-styles__single_value {
  margin: 0px !important;
  color: inherit !important;
}

.create-canvas-dropdown__single-value,
.vm-dropdown-styles__single-value {
  margin: 0px !important;
  color: var(--gray-9) !important;
}

.white-text .cr-dropdown-styles__single-value {
  color: var(--gray-9) !important;
}

.black-text .cr-dropdown-styles__single-value {
  color: var(--gray-1) !important;
}

.manage-shows-dropdown__placeholder,
.manage-facilities-dropdown__placeholder,
.cr-dropdown-styles__placeholder,
.create-canvas-dropdown__placeholder,
.vm-dropdown-styles__placeholder {
  margin-left: 0px;
  margin-right: 0px;
}
/* menu list */
.create-canvas-dropdown__menu,
.vm-dropdown-styles__menu {
  border-radius: 4px !important;
  background: var(--gray-5) !important;
  color: var(--gray-9) !important;
  font-weight: 400 !important;
  border: 1px var(--gray-5) solid !important;
  left: -1px;
  border-radius: 4px;
  width: 432px;
}
.manage-shows-dropdown__menu,
.manage-facilities-dropdown__menu {
  border-radius: 4px !important;
  background-color: var(--gray-3) !important;
  color: var(--gray-9) !important;
  border: 2px solid var(--gray-3) !important;
  font-weight: 400 !important;
  left: -1px;
  border-radius: 4px;
  margin-top: 6px !important;
  width: 432px;
  box-shadow: 0px 1px 1px 1px var(--gray-3) !important;
  border: 2px var(--gray-3) solid !important;
}
.manage-shows-dropdown__menu-list,
.manage-facilities-dropdown__menu-list {
  background-color: var(--gray-3) !important;
  border-radius: 4px;
  padding: 0px !important;
}

.cr-dropdown-styles__menu {
  border-radius: 4px !important;
  background: var(--gray-5) !important;
  color: var(--gray-9) !important;
  font-weight: 400 !important;
  left: -1px;
  width: 432px;
  border-radius: 4px !important;
}
.manage-facilities-dropdown__menu-list,
.create-canvas-dropdown__menu-list,
.vm-dropdown-styles__menu-list {
  box-shadow: 0px 8px 18px 12px var(--gray-1);
  max-height: 388px !important;
  text-transform: uppercase;
}
.vm-dropdown-styles__menu-list {
  padding: 1px !important;
  max-height: 240px !important;
  overflow: auto;
}

.cr-dropdown-styles__menu-list {
  box-shadow: 0px 8px 18px 12px var(--gray-1);
  max-height: 350px !important;
  text-transform: uppercase;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  background: var(--gray-2);
  padding-right: 2px;
  border-radius: 4px !important;
  border: 2px var(--gray-2) solid !important;
}
.cr-dropdown-styles__option:nth-child(2) {
  border-radius: 4px 4px 0px 0px;
}
.cr-dropdown-styles__option:last-child {
  border-radius: 0px 0px 4px 4px;
}
.manage-shows-dropdown__option:hover,
.manage-shows-dropdown__option--is-selected,
.manage-facilities-dropdown__option:hover,
.manage-facilities-dropdown__option--is-selected {
  background-color: var(--gray-2) !important;
  align-items: center !important;
  display: flex !important;
}

.ls-status-dropdown__menu-list {
  padding: 0px !important;
}

.cr-dropdown-styles__option:hover,
.create-canvas-dropdown__option:hover,
.vm-dropdown-styles__option:hover {
  border: 1px solid var(--gray-9) !important;
  cursor: pointer !important;
}

.cr-dropdown-styles__option:active,
.vm-dropdown-styles__option:active {
  background-color: var(--mint-6) !important;
  border: 1px solid var(--mint-6) !important;
  color: var(--gray-1) !important;
}

.ls-status .ls-status-dropdown__option:active {
  border-color: var(--mint-6) !important;
  background-color: var(--mint-6) !important;
  color: var(--gray-1) !important;
}

.create-canvas-dropdown__option--is-selected,
.vm-dropdown-styles__option--is-selected {
  background-color: var(--mint-6) !important;
  color: var(--gray-1) !important;
}

.cr-dropdown-styles__option--is-selected {
  background-color: var(--mint-6) !important;
  color: var(--gray-1) !important;
  font-weight: 700;
}
.networkdropdown .manage-shows-dropdown__option,
.facilitydropdown .manage-facilities-dropdown__option {
  align-items: center !important;
  display: flex !important;
  padding: 6px 8px !important;
  background-color: var(--gray-5);
}

.create-canvas-dropdown__option,
.cr-dropdown-styles__option {
  border: 1px solid transparent;
}

.vm-dropdown-styles__option {
  padding: 6px 8px !important;
}
.vm-dropdown-styles__option:not(:last-child) {
  border-bottom: 1px solid var(--gray-3);
}
.manage-shows-dropdown__option:not(:last-child),
.manage-facilities-dropdown__option:not(:last-child) {
  border-bottom: 1px solid var(--gray-3);
}
.manage-shows-dropdown__option {
  border-right: 2px solid var(--gray-3);
}

.cr-dropdown-styles__option:not(:last-child) {
  border-bottom: 1.2px solid var(--gray-2);
  padding: 5px 16px !important;
  gap: 16px;
  border-radius: 0px;
}

.cr-dropdown-styles__option:first-child {
  border-radius: 4px 4px 0px 0px;
}

.cr-dropdown-styles__option:last-child {
  border-radius: 0px 0px 4px 4px;
}

.controlroomdropdown .cr-dropdown-styles__option,
.venue-dropdown .vm-dropdown-styles__option {
  background-color: var(--gray-5);
}

/* styling arrow outer and inner div */
.manage-shows-dropdown__indicator,
.manage-shows-dropdown__dropdown-indicator,
.manage-facilities-dropdown__indicator,
.manage-facilities-dropdown__dropdown-indicator,
.cr-dropdown-styles__dropdown-indicator,
.create-canvas-dropdown__dropdown-indicator,
.vm-dropdown-styles__dropdown-indicator {
  padding: 0px 0px !important;
  color: inherit !important;
  transition: none !important;
}
.grey-bg {
  background-color: var(--gray-4) !important;
}
.green-bg {
  background-color: var(--mint-6) !important;
}

.white-text
  .cr-dropdown-styles__value-container
  .cr-dropdown-styles__placeholder {
  color: var(--gray-9) !important;
}
.grey-text {
  color: var(--gray-7) !important;
}
.black-text {
  color: var(--gray-3) !important;
  font-weight: 700 !important;
}

.controlroomdropdown div::-webkit-scrollbar,
.ls-status div::-webkit-scrollbar,
.networkdropdown div::-webkit-scrollbar,
.shownetwork div::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}

.controlroomdropdown div::-webkit-scrollbar-thumb,
.ls-status div::-webkit-scrollbar-thumb,
.networkdropdown div::-webkit-scrollbar-thumb,
.shownetwork div::-webkit-scrollbar-thumb {
  background-color: var(--mint-6);
  border-radius: 4px;
}

.controlroomdropdown div::-webkit-scrollbar-track,
.ls-status div::-webkit-scrollbar-track,
.networkdropdown div::-webkit-scrollbar-track,
.shownetwork div::-webkit-scrollbar-track {
  border-radius: 4px;
  box-shadow: none;
  background-color: var(--gray-4);
}

.facilitydropdown {
  width: 100% !important;
  border: 1px solid var(--gray-4);
  height: 32px;
  border-radius: 4px;
  line-height: 22px;
  font-weight: 400 !important;
  font-size: 14px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px 5px 12px;
  width: 100%;
  background-color: var(--gray-4) !important;
}
.facilitydropdown div,
.networkdropdown div,
.controlroomdropdown div,
.createCanvasDropdown div,
.venue-dropdown div {
  text-transform: uppercase;
  box-shadow: none;
}
::-webkit-box-shadow {
  box-shadow: none;
}
/* react-select styles for canvas summary dropdown */
/* container is ls-status */
.ls-status {
  width: 12%;
  font-weight: 900 !important;
  font-size: 14px !important;
  color: inherit;
  border-bottom: 1px solid #0a0b10;
  max-height: 77px !important;
}
/* control is a div that contains select text and arrow when menu closed; css-1pahdxg-control when menu is open */
.ls-status .ls-status-dropdown__control {
  height: 77px !important;
  text-align: center !important;
  cursor: pointer;
  caret-color: transparent;
  transition: none !important;
  width: 95% !important;
  background-color: transparent !important;
  border-radius: 0px !important;
  border: none !important;
  box-shadow: none !important;
  margin-left: 0px !important;
  font-weight: 900 !important;
}
.ls-status .ls-status-dropdown__single-value {
  white-space: normal;
}
.ls-status .ls-status-dropdown__indicators {
  margin: 8px 0px;
  border-radius: 4px;
}
.ls-status .ls-status-dropdown__indicator {
  padding: 0px !important;
}
.ls-status .ls-status-dropdown__indicatorSeparator {
  display: none !important;
}
/* ValueContainer holds text div in select */
.ls-status .ls-status-dropdown__ValueContainer {
  padding: 0px 0px !important;
}
/* styling arrow outer and inner div */
.ls-status .ls-status-dropdown__indicatorContainer {
  padding: 0px 0px !important;
  color: inherit !important;
  transition: none !important;
}
/* styling menu list */
.ls-status .ls-status-dropdown__menu {
  border-radius: 4px !important;
  background: var(--gray-2) !important;
  color: var(--gray-9) !important;
  font-weight: 400 !important;
  border: 2px solid var(--gray-2);
  border-radius: 4px;
}
/* option is hover in unselected option */
/* option is selected option */
.ls-status .ls-status-dropdown__option:hover {
  border: 1px solid var(--gray-9);
}
.ls-status .ls-status-dropdown__option--is-selected {
  background-color: var(--mint-6) !important;
  font-weight: 700;
  color: var(--gray-1) !important;
}
.ls-status .ls-status-dropdown__option {
  padding: 8px !important;
  border: 1px solid var(--gray-5);
  border-bottom: 1px solid var(--gray-2);
  color: var(--gray-9);
  cursor: pointer;
  outline: none;
  background-color: var(--gray-5);
}
.ls-status .ls-status-dropdown__option:last-child {
  border-radius: 0px 0px 4px 4px;
}
.ls-status .ls-status-dropdown__option:first-child {
  border-radius: 4px 4px 0px 0px;
}
/* singleValue  is the text of selected option in select tag */
.ls-status .ls-status-dropdown__single-value {
  margin: 0px !important;
  color: inherit !important;
}
.showdropdown-modal {
  height: 100%;
}
.showdropdown {
  width: 900px;
  height: 508px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  padding: 32px;
  border: 2px solid var(--mint-6);
  background: var(--gray-2);
  position: relative;
  left: unset;
  margin: auto;
}

.shownetwork {
  width: 200px !important;
  border: 1px solid var(--gray-4);
  height: 32px;
  border-radius: 4px;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 400 !important;
  font-size: 14px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px 5px 12px;
  background-color: var(--gray-4);
  color: var(--gray-7);
}
/* react-select syles; using classNamePrefix "shows-dropdown", for reference check class names in browser dev toold */
/* hover state when menu closed */
.shows-dropdown__option:not(:last-child) {
  border-bottom: 1px solid var(--gray-3);
}
.shownetwork:hover {
  border-color: var(--gray-9) !important;
}
.shownetwork:hover .shows-dropdown__placeholder {
  color: var(--gray-9);
}
.controlroomdropdown:hover .cr-dropdown-styles__placeholder {
  color: var(--gray-9);
}
.controlroomdropdown:hover .cr-dropdown-styles__dropdown-indicator {
  color: var(--gray-9) !important;
}

.controlroomdropdown.cr-dropdown-styles--is-disabled {
  pointer-events: auto;
  cursor: not-allowed;
}

.controlroomdropdown .cr-dropdown-styles__control {
  cursor: inherit;
}

.controlroomdropdown:hover
  .cr-dropdown-styles__control--menu-is-open
  .cr-dropdown-styles__dropdown-indicator {
  color: var(--gray-1) !important;
}

/* css-tlfecz-indicatorContainer is arrow when menu closed */
.shownetwork:hover .css-tlfecz-indicatorContainer {
  color: var(--gray-9) !important;
}
/* accesing placeholder when hovering and menu is open */
.shows-dropdown__control--is-focused
  .shows-dropdown__value-container
  .shows-dropdown__placeholder {
  color: var(--gray-1) !important;
}
.cr-dropdown-styles__control--menu-is-open
  .cr-dropdown-styles__value-container
  .cr-dropdown-styles__placeholder {
  color: var(--gray-7);
}
.shows-dropdown__control,
.shows-dropdown__control--menu-is-open {
  background: transparent !important;
  border: none !important;
  color: var(--gray-7) !important;
  width: 175px !important;
  height: 32px !important;
  padding: 0px !important;
  display: contents !important;
  text-align: flex-start !important;
  color: inherit !important;
  caret-color: transparent !important;
}
.shows-dropdown__indicator-separator {
  display: none !important;
}
.shows-dropdown__value-container {
  padding: 0px 0px !important;
}
.shows-dropdown__single-value {
  margin: 0px !important;
  color: inherit !important;
}
.shows-dropdown__placeholder {
  margin-left: 0px;
  margin-right: 0px;
}
/* menu list */
.shows-dropdown__menu {
  border-radius: 4px !important;
  background: var(--gray-4) !important;
  color: var(--gray-9) !important;
  font-weight: 400 !important;
  left: -1px;
  width: 432px;
  z-index: 99;
}
.shows-dropdown__menu-list {
  text-transform: uppercase;
  border: 2px solid var(--gray-2);
  border-radius: 4px;
}
.shows-dropdown__option:hover,
.shows-dropdown__option--is-selected {
  background-color: var(--gray-2) !important;
  align-items: center !important;
  display: flex !important;
  cursor: pointer;
}
.shownetwork .shows-dropdown__option {
  align-items: center !important;
  display: flex !important;
  padding: 6px 8px !important;
  background-color: var(--gray-5);
}
/* styling arrow outer and inner div */
.shows-dropdown__indicator,
.shows-dropdown__dropdown-indicator {
  padding: 0px 0px !important;
  color: inherit !important;
  transition: none !important;
}
.grey-bg {
  background-color: var(--gray-4) !important;
}
.green-bg {
  background-color: var(--mint-6) !important;
}
.white-text {
  color: var(--gray-9) !important;
}
.grey-text {
  color: var(--gray-7) !important;
}
.black-text {
  color: var(--gray-3) !important;
  font-weight: 700 !important;
}
