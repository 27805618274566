@media screen and (max-device-width: 575px) {
  .blankbox img {
    width: 20px;
    opacity: 0.2;
  }
  .blankbox h1 {
    font-size: 25px;
    font-weight: 900;
  }
  .blankbox p {
    font-size: 150%;
  }
  .blankbox {
    text-align: center;
    width: 70%;
    margin: 0 auto;
  }
}
@media screen and (min-device-width: 576px) {
  .blankbox img {
    width: 20px;
    opacity: 0.2;
  }
  .blankbox h1 {
    font-size: 35px;
    font-weight: 900;
  }
  .blankbox p {
    font-size: 150%;
  }
  .blankbox {
    text-align: center;
    width: 70%;
    margin: 0 auto;
  }
}
@media screen and (min-device-width: 768px) {
  .blankbox img {
    width: 20px;
    opacity: 0.2;
  }
  .blankbox h1 {
    font-size: 40px;
    font-weight: 900;
  }
  .blankbox p {
    font-size: 150%;
  }
  .blankbox {
    text-align: center;
    width: 70%;
    margin: 0 auto;
    padding-top: 0px;
  }
}
@media screen and (min-device-width: 992px) {
  .blankbox img {
    width: 20px;
    opacity: 0.2;
  }
  .blankbox h1 {
    font-size: 35px;
    font-weight: 900;
  }
  .blankbox p {
    font-size: 150%;
  }
  .blankbox {
    text-align: center;
    width: 70%;
    margin: 0 auto;
    margin-top: 2%;
  }
}

@media print {
  .blankbox img {
    width: 20px;
  }
  .blankbox h1 {
    font-size: 35px;
    font-weight: 900;
  }
  .blankbox p {
    font-size: 150%;
  }
  .blankbox {
    text-align: center;
    width: 70%;
    margin: 0 auto;
    color: #0a0b0f;
  }
}
