.warning-icon-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  width: 436px;
  height: 28px;
}
.warning-icon {
  width: 26px;
  height: 26px;
}
.warning-headline {
  width: 360px;
  height: 28px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: var(--gray-9);
}
.warning-Close-icon {
  width: 26px;
  height: 26px;
  cursor: pointer;
}
.warning-Close-icon:hover {
  fill: var(--banana-6);
}
.warning-content {
  width: 436px;
  height: 44px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: var(--gray-9);
}
.warning-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 0px 0px;
  gap: 16px;
  width: 436px;
  height: 40px;
}
.delete-cancel-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  width: 65px;
  height: 32px;
  background: var(--gray-2);
  border-radius: 60px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-7);
  border: 2px solid var(--gray-4);
  outline: none;
  cursor: pointer;
}
.delete-cancel-btn:hover {
  border: 1px solid var(--gray-9);
  color: var(--gray-9);
  background: var(--gray-1);
}
.delete-cancel-btn:active {
  border: 1px solid var(--banana-6);
  color: var(--banana-6);
  outline: none;
}

.delete-cancel-btn:focus {
  outline: none;
}

.delete-submit-btn,
.duplicate-submit-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  width: 65px;
  height: 32px;
  background: var(--gray-4);
  border-radius: 60px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-9);
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
}
.delete-submit-btn:hover,
.duplicate-submit-btn:hover {
  border: 1px solid var(--gray-9);
  color: var(--gray-9);
  background-color: var(--gray-4);
}
.delete-submit-btn:active,
.delete-submit-btn:focus,
.duplicate-submit-btn:active,
.duplicate-submit-btn:focus {
  border: 1px solid var(--banana-6);
  color: var(--gray-1);
  outline: none;
  background-color: var(--banana-6) !important;
}
.warning-popup {
  position: absolute;
  border: 2px solid var(--rose-6) !important;
  top: calc(50% - 108px) !important;
}
.warning-btn {
  width: 83px !important;
}
