.pop-container-div {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  visibility: visible;
  display: block;
  background-color: rgba(22, 22, 22, 0.5);
}

.common-modal-postion {
  position: relative;
  margin: 0 auto;
  top: 25%;
  z-index: 100;
}

.pin-modal-position {
  position: relative;
  margin: 0 auto;
  z-index: 100;
}

.addPinModalcontainer {
  height: 100%;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pin-modal-content {
  border: var(--mint-6) 2px solid !important;
  background-color: var(--gray-2) !important;
  color: #ffffff;
  border-radius: 8px;
  padding: 32px;
  pointer-events: auto;
  background-clip: padding-box;
}

.pin-modal-content div::-webkit-scrollbar {
  width: 6px;
}
.pin-modal-content div::-webkit-scrollbar-thumb {
  background: var(--mint-4);
}

.addPinModalcontainer .btn:disabled {
  opacity: 0.95;
}
.addPinModalcontainer .modal-header {
  padding: 0px 5px;
}
.addPinModalcontainer .modal-footer {
  padding: 5px 8px;
}

.pin-modal-body {
  padding: 0;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.addPinIcon {
  fill: var(--mint-6);
  width: 23px;
  height: 23px;
}
.editPinIcon {
  filter: invert(60%) sepia(21%) saturate(565%) hue-rotate(96deg)
    brightness(200%) contrast(93%);
}
.modal-title {
  font-size: 20px !important;
}
