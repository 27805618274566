.modal-container {
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 500px;
  background: var(--gray-2);
  border-radius: 8px;
  position: fixed;
  top: calc(50% - 108px);
  left: calc(50% - 250px);
  z-index: 500;
}
.modal-type-error {
  border: 2px solid var(--rose-6);
}
.modal-type-warning {
  border: 2px solid var(--banana-6);
}
.modal-type-success {
  border: 2px solid var(--green-6);
}
.modal-type-info {
  border: 2px solid var(--blue-6);
}

.modal-container .modal-header-row {
  display: flex;
  margin: 0px;
  display: block;
}

.modal-container .modal-icon {
  height: 26px;
  width: 26px;
  float: left;
}

.modal-type-success .modal-icon {
  filter: invert(65%) sepia(70%) saturate(358%) hue-rotate(100deg)
    brightness(88%) contrast(75%);
}

.modal-type-info .modal-icon {
  filter: invert(45%) sepia(88%) saturate(1341%) hue-rotate(160deg)
    brightness(93%) contrast(101%);
}

.modal-container .modal-label {
  padding-left: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: var(--gray-9);
  margin: 0px;
  float: left;
}

.modal-container .modal-close-icon {
  font-size: 12px;
  cursor: pointer;
  height: 26px;
  width: 26px;
  color: var(--gray-9);
  float: right;
}
.modal-type-success .modal-close-icon:hover {
  filter: invert(65%) sepia(70%) saturate(358%) hue-rotate(100deg)
    brightness(88%) contrast(75%);
  cursor: pointer;
}

.modal-type-info .modal-close-icon:hover {
  filter: invert(45%) sepia(88%) saturate(1341%) hue-rotate(160deg)
    brightness(93%) contrast(101%);
  cursor: pointer;
}

.modal-type-error .modal-close-icon:hover {
  filter: invert(65%) sepia(100%) saturate(3571%) hue-rotate(327deg)
    brightness(90%) contrast(90%);
  cursor: pointer;
}

.modal-type-warning .modal-close-icon:hover {
  filter: invert(15%) sepia(60%) saturate(300%) hue-rotate(4deg) brightness(97%)
    contrast(86%);
  cursor: pointer;
}

.modal-container .modal-btn-container {
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
}

.modal-container .modal-primary-btn {
  color: var(--gray-9);
  background: var(--gray-4);
  border-radius: 16px;
  width: 65px;
  height: 32px;
  border: none;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}
.modal-container .modal-primary-btn:hover {
  color: var(--gray-9);
  border: 1px solid var(--gray-9);
  border-radius: 60px;
}

.modal-type-error .modal-primary-btn:focus {
  color: var(--gray-1);
  background: var(--rose-6);
  border: none;
}
.modal-type-success .modal-primary-btn:focus {
  color: var(--gray-1);
  background: var(--mint-6);
  border: none;
}
.modal-type-info .modal-primary-btn:focus {
  color: var(--gray-1);
  background: var(--blue-6);
  border: none;
}
.modal-type-warning .modal-primary-btn:focus {
  color: var(--gray-1);
  background: var(--banana-6);
  border: none;
}
.modal-container .modal-content-container {
  font-size: 14px;
  line-height: 22px;
  padding: 16px 0px 16px 0px;
  color: var(--gray-9);
  display: flex;
  flex-direction: column;
}

.modal-secondary-btn {
  color: var(--gray-7);
  background-color: var(--gray-2);
  border: 1px solid var(--gray-7);
  font-size: 14px !important;
  border-radius: 60px;
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  height: 32px;
  width: 65px;
  font-weight: 400;
}
.modal-secondary-btn:hover {
  border: 1px solid var(--gray-9);
  color: var(--gray-9);
}
.modal-type-error .modal-secondary-btn:focus {
  color: var(--rose-6);
  border: 1px solid var(--rose-6);
}

.modal-type-success .modal-secondary-btn:focus {
  color: var(--mint-6);
  border: 1px solid var(--mint-6);
}

.modal-type-info .modal-secondary-btn:focus {
  color: var(--blue-6);
  border: 1px solid var(--blue-6);
}

.modal-type-warning .modal-secondary-btn:focus {
  color: var(--banana-6);
  border: 1px solid var(--banana-6);
}
