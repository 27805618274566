ul.manage-menu {
  border: 1px solid gray;
  border-top: none;
  background-color: #0d111a;
  width: 40%;
  height: 2rem;
  font-size: small;
  z-index: 1;
  position: absolute;
  padding-left: 0px;
  padding-top: 5px;
  margin-bottom: 1rem;
  margin-left: 4rem;
  list-style-type: none;
  overflow-y: auto;
}

ul.li {
  font-size: small;
}

.manage-menu > li:hover {
  background-color: #2b3043;
  cursor: pointer;
}
.manage-menu > li {
  padding-left: 9px;
}

.hide-manage {
  display: none;
}

.outbound-pills-row .pill {
  font-size: 12px;
  line-height: 22px;
  background-color: transparent;
  color: var(--gray-1);
  border-radius: 4px;
  border: 1px solid var(--gray-1);
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: none;
  text-align: center;
  display: flex;
  align-items: center;
  height: 24px;
  width: max-content;
  gap: 8px;
}

.outbound-pills-row img {
  width: 10px;
  height: 6px;
}
