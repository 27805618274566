.venueTypeDropdown {
  margin-left: 17.67px;
  background: #141414;
  color: #dbdbdb;
  border-bottom: 0.5px solid gray;
  border-radius: 0px;
  width: 100%;
  height: 22px;
  line-height: 22px;
  font-weight: 400 !important;
  font-size: 14px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px 5px 12px;
}
.venueTypeContainer,
.availVcContainer,
.typeDropdownContainer,
.commsTypeContainer {
  background: var(--gray-1);
  color: var(--gray-9);
  border-bottom: 0.5px solid var(--gray-4);
  border-radius: 0px;
  width: 100%;
  line-height: 22px;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding-bottom: 2px;
}
.availVcContainer {
  color: var(--gray-5);
}
.availVcEnabled {
  color: var(--gray-9);
}
.availVcDisabled {
  pointer-events: none;
}
.displayNone {
  display: none;
}
.showPlaceholderDisabled {
  pointer-events: none;
  display: inline-block;
  color: var(--gray-5);
}
.showPlaceholder:hover + svg {
  fill: var(--gray-9);
}
.showPlaceholder {
  display: inline-block;
  color: var(--gray-5);
}
.venueTypeContainer:hover,
.availVcContainer:hover,
.typeDropdownContainer:hover,
.commsTypeContainer:hover {
  border-bottom: 0.5px solid var(--gray-9);
  color: var(--gray-9);
}
.availVcContainer:hover .up-arrow,
.availVcContainer:hover .grey7Color {
  fill: var(--gray-9);
}
.availVcEnabled .info + .grey7Color {
  fill: var(--gray-9);
}
.typeDropdownContainer:hover .showPlaceholder,
.venueTypeContainer:hover .showPlaceholder,
.availVcContainer:hover .showPlaceholder,
.typeDropdownContainer:hover .outboundDrop,
.venueTypeContainer:hover .venuedrop,
.availVcContainer:hover .availDrop {
  color: var(--gray-9);
}

.venueTypeContainer:active,
.availVcContainer:active,
.typeDropdownContainer:active,
.commsTypeContainer:active {
  border-bottom: 0.5px solid var(--mint-6);
  color: var(--mint-6);
}
.btn-header {
  display: flex;
}
.inboundPlusIcon,
.outboundPlusIcon,
.pinCommsSyncIcon {
  margin-right: 10px;
  fill: var(--gray-7);
  cursor: pointer;
}
.minusIcon {
  fill: var(--gray-7);
  cursor: pointer;
}
.inboundPlusIcon:hover,
.outboundPlusIcon:hover,
.pinCommsSyncIcon:hover,
.minusIcon:hover,
.outboundLogo:hover {
  fill: var(--gray-9);
}
.signals-heading {
  margin-bottom: 8px;
  padding-left: 16px;
  height: 24px;
}
.inboundTableHeaderRow {
  padding-bottom: 16px;
}
.inboundTableContainer,
.outboundTableContainer,
.commsTableContainer {
  background: var(--gray-1);
  padding: 8px 16px 12px;
  border-radius: 4px;
}
.empty-header {
  width: 3%;
}
.venueTypeHeaderCell,
.outboundTypeHeaderCell {
  width: 20%;
  display: table-cell;
  padding-left: 16px !important;
}
.availVcHeaderCell {
  width: 20%;
  padding-left: 16px !important;
}
.labelHeaderCell {
  width: 20%;
  padding-left: 16px !important;
}
.labelHeaderCell-outbound {
  width: 20%;
  padding-left: 16px !important;
}

.commsPinHeaderCell,
.commsSipHeaderCell {
  width: 20%;
  padding-left: 16px !important;
}
.txpath,
.txpath-comm {
  width: 20%;
}

.globalpath,
.globalpath-comm {
  width: 20%;
}
.localHeaderCell {
  width: 20%;
}
.extend-icon {
  fill: var(--gray-7);
}
.extend-icon:hover {
  fill: var(--gray-9);
}
.extendHeaderCell,
.unilatHeaderCell {
  width: 67px;
  padding-left: 16px !important;
}
.pin-comm,
.info-col {
  width: 60px;
}
.useHeaderCell,
.ownHeaderCell,
.commsUseHeaderCell,
.commsOwnHeaderCell {
  width: 50px;
  padding-left: 16px !important;
}
.ownHeaderCell,
.commsOwnHeaderCell {
  color: var(--gray-3) !important;
}

.pathname,
.pathname-comm {
  width: 20%;
}

.crpath,
.crpath-comm {
  width: 14%;
}
.venueTypeCell,
.outboundTypeCell,
.commsTypeCell,
.commslabelHeaderCell,
.globalpath,
.txpath,
.localHeaderCell {
  padding-left: 16px !important;
}
.availVcCell {
  padding-left: 16px !important;
}

.availVcCell-outbound {
  padding-left: 0px !important;
}
.inbound-info-cell {
  padding-left: 16px !important;
}
.labelCell,
.commsLocalpathnameHeaderCell {
  padding-left: 16px !important;
}

.extendCell {
  padding-left: 28px !important;
}

.extendCellLocal {
  padding-left: 16px !important;
  overflow-wrap: normal;
}

.globalpathCell {
  text-align: left;
}
.globalpathCell .redAsterisk,
.localCell .redAsterisk {
  margin-top: 4px;
}
.globalpathCell select {
  width: 40%;
  margin: 0px;
}
.inbound-info-cell img {
  width: 20px !important;
  margin-left: 12px;
  height: 20px !important;
  vertical-align: middle !important;
}

.inbound-info-cell:hover img {
  filter: brightness(0.01) invert(1);
}

.inBoundLabelClass:focus {
  border: 0px;
  border-bottom: 0.5px solid gray;
}
.use-radio-input,
.own-radio-input {
  border: 1px solid var(--gray-1);
  height: 16px;
}
.use-radio-input:hover,
.own-radio-input:hover {
  border: 1px solid var(--gray-9);
}
.use-radio-input:active,
.own-radio-input:active {
  border: 1px solid var(--mint-6);
}
.use-radio-input-cell,
.own-radio-input-cell,
.use-radio-input-cell-outbound {
  padding-top: 7px !important;
}
.use-radio-input-cell,
.own-radio-input-cell,
.use-radio-input-cell-outbound {
  padding-left: 16px !important;
}
.inboundTable,
.outboundTable,
.commsTable {
  margin: 0px;
}
.inboundTable td,
.outboundTable td,
.commsTable td {
  padding-top: 0px !important;
  padding-bottom: 4px !important;
  vertical-align: bottom;
}
.inboundTable th,
.outboundTable th,
.commsTable th {
  padding-bottom: 4px !important;
}
.radioFill {
  background-color: var(--gray-9);
  border-radius: 100%;
  width: 10px;
  height: 10px;
  display: inline-flex;
  margin: 2px;
}
.activeFill {
  background-color: var(--mint-6) !important;
}

.useRadioDiv,
.ownRadioDiv,
.useOutboundRadioDiv,
.ownOutboundRadioDiv {
  border-radius: 100%;
  color: var(--gray-7);
  cursor: pointer;
  z-index: 1;
  margin-left: 4px;
  width: 16px;
  height: 16px;
}
.useRadioDiv,
.ownOutboundRadioDiv {
  border: 1px solid var(--gray-7);
}
.ownRadioDiv,
.useOutboundRadioDiv {
  border: 1px solid var(--gray-4);
  cursor: default;
}
.localCell img {
  width: 15px;
}
.disableField {
  pointer-events: none;
}
.cursorNotAllowed {
  cursor: not-allowed !important;
}
.grey3Color {
  color: var(--gray-5) !important;
}
.grey9 {
  color: var(--gray-9);
}
.grey {
  color: grey;
}

.inBoundLabelClass,
.inboundTxPath,
.outboundTxPath,
.globalpathField,
.sipPathField,
.crPathField {
  outline: 0;
  border: 0px;
  border-bottom: 0.5px solid var(--gray-2);
}
.inboundOutboundInputFields {
  outline: 0;
  border: 0px;
  border-bottom: 0.5px solid var(--gray-4);
  color: var(--gray-9) !important;
}

.inboundOutboundInputFields:hover,
.inboundOutboundInputFields:focus,
.inboundOutboundInputFields:not(input):hover::placeholder,
.inboundOutboundInputFields:not(input):focus::placeholder {
  border-bottom: 0.5px solid var(--gray-9);
  color: var(--gray-9) !important;
}
.inboundOutboundInputFields::placeholder {
  color: var(--gray-7);
}
.sends {
  margin-top: 8px;
}
.outboundLogo {
  width: 19px !important;
  height: 20px !important;
  margin-right: 10px;
  fill: var(--gray-7);
  cursor: pointer;
}
.unilatCheckbox {
  width: 0px !important;
  height: 20px !important;
  margin-left: 10px;
  cursor: pointer;
}
.mint6border {
  border: 1px solid var(--mint-6) !important;
}
.gray9border {
  border: 1px solid var(--gray-9);
}
.unilatCheckbox:not(:checked)::before {
  content: url("../../../assets/icons/checkbox-default.svg");
}
.unilatCheckbox:not(:checked):hover::before {
  content: url("../../../assets/icons/checkbox-hover.svg");
}
.unilatCheckbox:checked::after {
  content: url("../../../assets/icons/checkbox-checked.svg");
}
.pinCommsSyncIcon {
  height: 18px !important;
  width: 18px !important;
}
.commsWarningIcon {
  width: 15px !important;
  height: 15px !important;
  margin-right: 10px;
  vertical-align: text-top;
}
.commsTypeHeaderCell {
  width: 20% !important;
  padding-left: 16px !important;
}
.commsLocalpathnameHeaderCell {
  width: 20% !important;
}
.commslabelHeaderCell {
  width: 40%;
}
.commsInfoCol {
  width: 60px !important;
}
.commsOwnHeaderCell,
.commsUseHeaderCell {
  width: 50px !important;
}
.greenColor {
  color: var(--mint-6) !important;
}
.green-icon {
  fill: var(--mint-6);
}
.greenBorder {
  border-bottom: 0.5px solid var(--mint-6) !important;
}
.venuedrop,
.availDrop,
.outboundDrop {
  position: relative;
  float: right;
  margin-right: 2%;
  margin-top: 1%;
  margin-bottom: 4%;
}
.venue-up-icon {
  position: relative;
  float: right;
  margin-top: 1%;
}
.grey7Color {
  fill: var(--gray-5);
}
.radio-btn-allowed {
  cursor: pointer;
}
.hoverState:hover {
  border: 1px solid var(--gray-9) !important;
}
.hoverState:hover .radioHover {
  background-color: var(--gray-9);
  border-radius: 100%;
  width: 10px;
  height: 10px;
  display: inline-flex;
  margin: 2px;
}
.mint6border:hover {
  border: 1px solid var(--mint-4) !important;
}
.mint6border:hover .activeFill {
  background-color: var(--mint-4) !important;
}
.select-type {
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-end;
  width: inherit;
}

.input-field.ant-input {
  background: inherit;
  width: 100%;
  border-radius: 0px;
  border: none;
  outline: none;
  border-bottom: 0.5px solid var(--gray-4);
  box-shadow: none;
  min-height: 0px;
  padding: 0px;
  overflow: hidden;
  color: inherit;
  white-space: normal;
  word-wrap: break-word;
}

.input-field.ant-input:hover,
.input-field.ant-input:focus {
  border-bottom: 1px solid var(--gray-9);
}

.input-field.ant-input::placeholder {
  color: var(--gray-7);
}

.list-container {
  position: relative;
  width: 100%;
}

.list {
  position: absolute;
  z-index: 50;
  top: 5px;
  min-width: 100%;
  max-height: 50vh;
  overflow: auto;
}

.wrap-text {
  width: 85%;
  overflow-wrap: break-word;
}

.inboundOutboundInputFields {
  width: 100% !important;
}

.list::-webkit-scrollbar {
  width: 8px;
  border-radius: 4px;
}

.list::-webkit-scrollbar-thumb {
  background-color: var(--mint-6);
  border-radius: 4px;
}

.list::-webkit-scrollbar-track {
  border-radius: 4px;
  box-shadow: none;
  background-color: var(--gray-4);
}

.list-item-bg {
  background-color: var(--gray-2);
  margin: 0px !important;
  border-radius: 0px;
}

input:hover::placeholder,
input:focus::placeholder {
  color: var(--gray-9) !important;
}

@media screen and (min-width: 0px) and (max-width: 850px) {
  .commsPinHeaderCell,
  .commsSipHeaderCell {
    width: 20%;
  }
}
.mtcdetails input {
  background-color: var(--gray-1);
  width: 85%;
  outline: none;
  color: #d2e8dee8;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 19px;
}
.comms-canvas-right {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.align-label {
  width: 19px;
  height: 20px;
  margin-right: 10px;
}
