/* Styling for Antd Select */
.role-select.ant-select {
  width: 100%;
  border-radius: 4px;
  padding: 0px;
  text-transform: uppercase;
  text-align: left;
  left: 1px;
  font-family: "Karla", sans-serif;
  transition: none;
  bottom: 2px;
  font-size: 12px;
  line-height: 22px;
}

.role-select.ant-select .ant-select-selector {
  box-shadow: none !important ;
  border: none !important;
  border-radius: 4px;
  height: 26px;
  padding-left: 10px;
  outline: inherit;
}

.menu-close.ant-select .ant-select-selector {
  background: var(--gray-4);
  color: var(--gray-9);
}

.menu-close.ant-select:hover .ant-select-selector {
  outline: 1px solid var(--gray-9) !important;
}

.menu-open.ant-select .ant-select-selector {
  color: var(--gray-3);
  font-weight: bolder;
  background: var(--mint-6);
  transition: none;
}

.menu-open.ant-select .ant-select-selection-item {
  color: var(--gray-3);
  font-weight: bolder;
}

.ant-select .ant-select-selection-item {
  line-height: 27px !important;
  font-size: 12px;
  font-family: "Karla", sans-serif;
  transition: none !important;
}

.ant-select .ant-select-arrow {
  inset-inline-end: 8px;
}

.popup-menu {
  border-radius: 4px;
  border: 2px solid var(--gray-2);
  background: var(--gray-5);
  color: var(--gray-9);
  margin-top: 8px;
  overflow: auto;
  transition: none;
}

.popup-menu.ant-select-dropdown .ant-select-item-option-selected {
  background: var(--gray-2);
  color: var(--gray-9);
}

.popup-menu .ant-select-item-option {
  color: var(--gray-9);
  text-transform: uppercase;
  transition: none;
  font-size: 12px;
  font-family: "Karla", sans-serif;
  font-weight: normal;
}

.popup-menu .ant-select-item {
  padding: 4px 8px;
  min-height: 0px;
}

.popup-menu .ant-select-item-option:hover {
  background: var(--gray-2);
}
