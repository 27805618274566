.label-container {
  width: 71px;
  height: 24px !important;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-3);
  border-radius: 4px;
  padding: 1px 15px 1px 7px;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  margin-bottom: 0px !important;
  span {
    color: var(--gray-5);
  }
  svg {
    width: 16px;
    height: 16px;
  }
  svg path {
    fill: var(--gray-5);
  }
  &:active {
    background-color: var(--blue-6) !important;
    span {
      color: var(--gray-1) !important;
    }
    svg path {
      fill: var(--gray-1) !important;
    }
    &:hover {
      background-color: var(--blue-5) !important;
      border-color: transparent !important;
    }
  }
  &:hover {
    background-color: var(--gray-5);
    border-color: var(--gray-9);
    color: var(--gray-9);
    cursor: pointer;
    span {
      color: var(--gray-9);
    }
    svg path {
      fill: var(--gray-9);
    }
  }
}

.label-icon {
  margin: auto;
  padding-right: 4px;
  padding-left: 7px;
  display: flex;
}

.label-container.active {
  background-color: var(--blue-6) !important;
  span {
    color: var(--gray-1) !important;
  }
  svg path {
    fill: var(--gray-1) !important;
  }
  &:hover {
    background-color: var(--blue-5) !important;
    border-color: transparent !important;
  }
}

.no-hover {
  pointer-events: none;
}
