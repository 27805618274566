html {
  height: 100%;
}

body {
  margin: 0;
  overflow-x: hidden;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Karla", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--gray-1);
  height: 100%;
  color: var(--gray-4);
}

#root,
:root {
  height: 100%;
  height: 100%;
  /* live shot status */
  --yellow-1: #fff5e1;
  --green-4: #005536;
  --green-5: #006847;
  /* nav bar notifications icon */
  --green-6: #0c7c59;
  /* add button hovering color */
  --mint-4: #64ad8b;
  /* modal borders, buttons, scroll bar */
  --mint-5: #64ad8b;
  --mint-6: #87d1ae;
  /* button hover */
  --mint-7: #418a6a;
  /* buttons grey almost black */
  --gray-1: #000000;
  /* modal bg, live shot status */
  --gray-2: #161819;
  /* references add/search live shots, scrollbars track */
  --gray-3: #24282a;
  /* buttons bg, dropdowns select, canvas summary containers bg, tooltip text, tooltip title bg */
  --gray-4: #343a3d;
  /* live shot status grey */
  --gray-5: #444d50;
  /* live shot status */
  --gray-6: #556065;
  /* labels, inputs borders */
  --gray-7: #7e888e;
  /* titles, inputs text, buttons text, inputs border hover state, tooltip bg, tooltip title text, live shot status */
  --gray-9: #dee0e1;
  /* live shots names in canvas summaries  */
  --gray-10: #ffffff;
  /* comms canvas */
  --rose-5: #c32547;
  /* red in modals, record buttons */
  --rose-6: #de425b;
  /* left side panel live shot contact left borders */
  --banana-6: #e1c467;
  /* live shot status */
  --mango-5: #d76531;
  /* live shot status */
  --mango-6: #fa824c;
  /* live shot status */
  --blue-2: #002e6a;
  --blue-5: #0067ab;
  --blue-6: #067bc2;
  --blue-7: #87c8eb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-notif {
  position: relative;
  word-wrap: break-word;
  pointer-events: auto;
  width: 470px;
  border-radius: 5px;
  color: white;
  background: #2853a7;
  padding-left: 30px;
  overflow: hidden;
  z-index: 9999;
  margin-bottom: 2px;
  max-height: 150px;
}

.custom-notif-content {
  padding-right: 1em;
}
.col-content {
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--gray-10);
}
.redAsterisk {
  color: var(--rose-6);
  padding-right: 3px;
  vertical-align: sub;
}
.restricted {
  cursor: not-allowed !important;
}
input:disabled {
  cursor: not-allowed !important;
}

.ag-row-focus,
.ag-row-hover {
  --ag-row-hover-color: transparent !important;
}
.ag-theme-alpine-dark {
  --ag-input-focus-box-shadow: none;
}

.hide-scroll::-webkit-scrollbar,
.loader-scroll-hide::-webkit-scrollbar {
  visibility: hidden;
  width: 0px !important;
}
