.sd-container {
  height: 100%;
  color: var(--gray-10);
  padding-top: 60px;
  background-color: var(--gray-1);
  display: flex;
  flex-direction: column;
}
.sd_table_container {
  height: calc(100% - 254px);
  flex-direction: column;
  align-items: flex-start;
  top: 164px;
  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
}
.ag-layout-normal {
  border-radius: 4px;
}
.router_buttons button {
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 56px;
  grid-gap: 2px;
  grid-gap: 2px;
  gap: 2px;
  background: var(--gray-2);
  color: var(--gray-5);
  outline: none;
  flex-direction: row;
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
  border-color: transparent;
  font-size: 16px;
  font-weight: 700;
}
.router_buttons_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 96%;
  height: 56px;
  border-radius: 0px;
  max-width: 100%;
  margin-left: 2%;
  margin-right: 2%;
}
.router_buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2px;
}
.sd-container .ag-header-row-floating-filter {
  height: 32px !important;
  top: 35px !important;
}
.sd-container .ag-header-row-column {
  height: 42px !important;
}
.sd-container .ag-header {
  height: 74px !important;
  min-height: 75px !important;
}
.sd-container .ag-header {
  height: 95px !important;
  min-height: 95px !important;
}
.sd-container .ag-center-cols-viewport {
  background-color: var(--gray-1);
}
.ag-root-wrapper {
  border: none !important;
}
.ag-header-cell {
  border-top: none !important;
}
.ag-header {
  border-bottom: none !important;
}
.ag-theme-alpine-dark input {
  border: none !important;
}
.router_buttons .active {
  color: var(--gray-9);
  background: var(--gray-4);
}
.ag-header-container {
  background: var(--gray-4);
}
.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}

.no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}
.ag-header-container {
  background: var(--gray-4);
}

.Addperson-img {
  height: 20px;
  width: 20px;
  margin-top: 5%;
}
.Addperson-imgage {
  height: 25px;
  width: 25px;
  margin-top: 5%;
  margin-right: 5px;
}

.user-label {
  width: 360px;
  height: 28px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  color: var(--gray-9);
}
.Addperson-image {
  height: 25px;
  width: 25px;
  margin-top: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.adduser {
  color: var(--mint-6);
  z-index: 100;
}

.close-icon {
  font-size: 12px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  fill: var(--gray-9);
  padding: 0;
  margin-left: 415px;
  position: absolute;
  margin-top: 12px;
}
.close-icon:hover {
  fill: var(--mint-6);
  cursor: pointer;
}
.presse_Close_icon {
  font-size: 12px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  border: 2px solid var(--mint-6);
  border-radius: 50%;
  background-color: var(--gray-2);
  color: var(--mint-6);
  padding: 0;
  margin-left: 86%;
  position: absolute;
  margin-top: 12px;
}
.bg-col {
  background-color: var(--gray-2);
}
.mandatory-sso {
  display: flex;
  margin-top: 10px;
}
.mandatory_icon {
  color: var(--rose-6);
}
.sso-number {
  width: 95px;
  height: 22px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: right;
  color: var(--gray-7);
}

.AddUser-modal-icons-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.Adduser-cancel-icon {
  color: var(--gray-7);
  background-color: transparent;
  border: 1px solid var(--gray-7);
  font-size: 14px;
  border-radius: 60px;
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 8px;
  height: 32px;
  width: 72px;
  font-weight: 400;
}
.Adduser-cancel-icon:hover {
  border: 1px solid var(--gray-9);
  color: var(--gray-9);
}
.Adduser-cancel-icon:focus {
  color: var(--mint-6);
  border: 1px solid var(--mint-6);
}
.User-Submit-icon {
  color: var(--gray-5);
  background-color: var(--gray-3);
  border: none;
  font-size: 14px;
  border-radius: 60px;
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 8px;
  height: 32px;
  width: 72px;
  font-weight: 400;
}
.User-Submit-icon:hover {
  border: 1px solid var(--gray-9);
  background-color: var(--gray-4);
  color: var(--gray-9);
}
.User-Submit-icon:focus {
  background: var(--mint-6);
  color: var(--gray-1);
  border: 1px solid var(--gray-1);
}

.sso-input {
  width: 436px;
  height: 32px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  background-color: var(--gray-4);
  border: none;
  color: var(--gray-9);
  padding: 5px 8px 5px 12px;
  outline: none;
}
.sso-input:hover,
.sso-input:active {
  border: 1px solid var(--gray-10);
}

.Warning_Close_icon {
  font-size: 12px;
  cursor: pointer;
  height: 22px;
  width: 22px;
  color: var(--gray-9);
  padding: 0;
  margin-left: 410px;
  position: absolute;
  margin-top: 2px;
}
.Warning_Close_icon:hover {
  filter: invert(66%) sepia(53%) saturate(236%) hue-rotate(77deg)
    brightness(260%) contrast(88%);
  cursor: pointer;
}

.presse_Warning_Close_icon {
  font-size: 12px;
  cursor: pointer;
  height: 22px;
  width: 22px;
  border: 2px solid var(--gray-9);
  border-radius: 50%;
  background-color: var(--gray-2);
  color: var(--mint-6);
  padding: 0;
  margin-top: 10px;
}

.addperson-icon {
  height: 20px;
  width: 20px;
  margin-right: 0px;
}
.Submit-icon {
  color: var(--gray-9);
  background-color: var(--gray-4);
  border: none;
  font-size: 14px;
  border-radius: 60px;
  padding: 5px 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 8px;
  height: 32px;
  width: 72px;
  font-weight: 400;
}
.Submit-icon:hover {
  border: 1px solid var(--gray-9);
  color: var(--gray-9);
}
.Submit-icon:focus {
  background: var(--mint-6);
  color: var(--gray-1);
  border: 1px solid var(--gray-1);
}

.adduser-container {
  height: 220px;
  top: 30%;
  width: 500px;
  z-index: 500;
  background-color: var(--gray-2);
  border: 2px solid var(--mint-6);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mandatory_icon {
  width: 6px;
  height: 22px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: right;
  color: var(--rose-6);
}
.trashInfo-container {
  color: var(--gray-3);
  width: 500px;
  height: 208px;
  position: fixed;
  top: 30%;
  left: calc(50% - 250px);
  z-index: 500;
  background-color: var(--gray-2);
  border: 2px solid var(--banana-6);
  border-radius: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.errorInfo-container {
  color: var(--gray-3);
  width: 500px;
  height: 208px;
  position: fixed;
  top: 30%;
  left: calc(50% - 250px);
  z-index: 500;
  background-color: var(--gray-2);
  border: 2px solid var(--rose-6);
  border-radius: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.warning-headline {
  width: 360px;
  height: 28px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: var(--gray-9);
}

.ssoIdMsg {
  color: var(--gray-10);
  text-align: center;
  font-weight: bold;
  padding-top: 15%;
}

.mandatory-icon {
  font-size: 14px;
  color: var(--rose-6);
}
.div-label {
  color: var(--gray-7);
  font-size: 14px !important;
  line-height: 22px;
  margin-bottom: 2px !important;
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
}
.loader img {
  top: 35%;
  position: absolute;
  left: 46%;
}
.restore-row {
  cursor: pointer;
}
.Import {
  margin-right: 40px;
}
.Import:disabled {
  color: var(--gray-9);
  cursor: not-allowed;
}
.source-mgt-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 2%;
  width: 100%;
  height: 52px;
  margin-top: 24px;
  margin-bottom: 24px;
}
.source-mgt-header-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0px;
  width: 100%;
  grid-gap: 12px;
  gap: 12px;
  height: 52px;
  justify-content: space-between;
}
.source-header-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  width: 968px;
  height: 28px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: var(--gray-9);
  margin-right: auto;
}
.source-upload-btn,
.source-save-btn,
.source-import-btn {
  width: 130px;
  display: flex;
  flex-direction: row;
  height: 24px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 1px 8px 1px 7px;
  gap: 4px;
  background: var(--gray-4);
  border-radius: 60px;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
}
.source-import-btn {
  margin-right: 0px;
}
.source-upload-btn:focus,
.source-save-btn:focus,
.source-import-btn:focus {
  outline: none;
}

.source-upload-btn:hover,
.source-save-btn:hover,
.source-import-btn:hover {
  border-color: var(--gray-9);
}
.source-upload-btn:active,
.source-save-btn:active,
.source-import-btn:active {
  background: var(--mint-6);
  outline: none;
  border-color: var(--gray-4);
}
.source-upload-btn:active span,
.source-save-btn:active span,
.source-import-btn:active span {
  color: var(--gray-4);
  font-weight: 500;
}
.source-upload-btn:active img,
.source-save-btn:active img,
.source-import-btn:active img {
  filter: brightness(0%);
}
.source-upload-btn span,
.source-save-btn span,
.source-import-btn span {
  width: fit-content;
  height: 22px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-9);
}
/* @media (min-resolution: 140dpi) {
  .source-mgt-header-row {
    margin-top: 82px;
  }
} */
