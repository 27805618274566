.home_page_icon i {
  margin-top: 32px;
  padding-left: 3px;
  font-size: 17px;
}

.manage_rsd {
  padding: 0 10px;
  background-color: var(--gray-4);
}

.home_page_icon,
.manage_ct,
.manage_vm .manage_ab,
.manage_bridge {
  padding: 0 8px;
  background-color: var(--gray-7);
}

.manage_ct_disabled,
.manage_rsd_disabled {
  opacity: 1;
  cursor: default !important;
}

/* Navbar Icon Overrides */

.btn {
  background-color: transparent;
  justify-content: space-between;
  border: none;
  outline: none;
}

.btn:hover {
  background-color: transparent;
}

.dropdown-navbar:hover .tools-text {
  font-style: normal;
  font-weight: 400;
  color: var(--gray-9);
}
.btn-primary:focus {
  outline: none !important;
  border: none !important;
}
.admin-tools-nav-bg:hover .btn {
  color: var(--gray-9);
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-primary {
  color: none;
  border: none;
}
.btn-primary:active,
.btn-primary:focus {
  background-color: var(--mint-6);
}

.dropdown .btn-primary:active,
.btn-primary:focus {
  background-color: transparent;
  border: 1px solid transparent;
  border-color: transparent;
  box-shadow: none;
}

.dropdown .btn-primary:not(:disabled):not(.disabled).active:focus,
.dropdown .btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  border: 0px;
}

/* Dropdown Overrides */
  .nav .dropdown {
  cursor: pointer;
  position: absolute;
  left: 88%;
  top: 30%;
}

.admin-tools-menu {
  flex-direction: row;
  align-items: center;
  position: absolute;
  width: auto;
  right: 0;
  height: 56px;
  display: flex;
  background: var(--gray-5);
}

.dropdown-toggle::after {
  display: none;
}
.dropdown {
  margin-left: 10px;
}

.user-dropdown-menu {
  cursor: pointer;
  position: absolute !important;
  left: auto;
  top: 3.8em;
  right: 10px;
}

.dropdown-toggle::after {
  display: none;
}
#dropdown-basic::after {
  display: none;
}
.admin-tools-dropdown-item-bar,
.manage-tools-dropdown-item-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 196px;
  height: 32px;
}

.manage-tools-dropdown-item-bar-arrivals,
.manage-tools-dropdown-item-bar-arrivals-disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 196px;
  height: 32px;
}
.manage-tools-dropdown-item-bar-arrivals-disabled {
  cursor: auto;
}
.manage-tools-dropdown-item-bar-arrivals-disabled a {
  pointer-events: none;
}

.user-dropdown-item {
  z-index: 999;
  align-items: center;
  padding: 8px 16px;
  width: 242px;
  height: 40px;
  display: inline-flex;
  flex-direction: row;
}

.user-panel-btns-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 1px;
  width: 279px;
  height: 99px;
  border-radius: 4px;
}

.admin-tools-dropdown-item {
  border: 1px solid var(--gray-9);
  background-color: var(--gray-5);
  color: var(--gray-9);
}

.user-dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  width: 279px;
  height: 48px;
  background: var(--gray-4);
  border: 1px solid var(--gray-4);
  justify-content: space-between;
}

.dropdown-item:hover {
  color: transparent;
  background-color: var(--gray-4);
}
.user-dropdown-item:hover {
  border-color: var(--gray-9);
  background-color: var(--gray-4);
  color: var(--gray-9);
}

.user-dropdown-item:active,
.user-dropdown-item:focus {
  background-color: var(--mint-6);
  color: var(--gray-4);
  border: none;
}
.user-dropdown-item:active,
.user-dropdown-item:focus .nav-dropdown-text,
.user-dropdown-item:active,
.user-dropdown-item:active .nav-dropdown-text {
  color: var(--gray-4);
  font-weight: 700;
}

.nav-dropdown-item-margin-icon {
  color: var(--gray-9);
  margin-right: 10px;
  display: inline-flex;
}

.userProfile-text {
  font-size: 14px;
}

.userProfile-icon {
  display: flex;
  height: 22px;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: var(--mint-6);
  box-shadow: none;
}

.show > .user-dropdown-icon-style {
  background-color: var(--mint-6);
  color: var(--gray-1);
  border: none !important;
}

.notif-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: var(--gray-10);
  margin: 0px;
}

.toggle-notif {
  top: 0;
}
.align-notif {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px 8px;
  gap: 77px;
  width: 279px;
  height: 39.08px;
  border-radius: 4px;
}
.notif-text-toggle {
  font-style: normal;
  font-size: 14px;
  color: var(--gray-9);
}
.admin-dropdown-menu.show,
.manage-dropdown-menu.show {
  width: inherit;
  display: flex;
  flex-direction: row;
  grid-gap: 24px;
  gap: 24px;
  background-color: var(--gray-5);
  padding: 16px 32px;
  height: 64px;
  align-items: center;
  margin-top: 0;
  top: 0;
  justify-content: flex-end;
  border: 0;
}

.triangle-poly-admin {
  bottom: 33px;
  margin-left: auto;
  right: 400px;
  position: relative;
  display: flex;
}

.triangle-poly-manage {
  bottom: 33px;
  margin-left: auto;
  right: 260px;
  position: relative;
  display: flex;
}

.user-menu-container-dropdown {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  gap: 24px;
  position: relative;
  width: 343px;
  height: fit-content;
  background: var(--gray-2);
  border: 2px solid var(--mint-6);
  border-radius: 8px;
  display: flex;
}

.user-dropdown-menu {
  background-color: transparent;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0px;
}
.nav-dropdown-item-margin {
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
}
.nav-dropdown-item-margin-support {
  color: var(--gray-9);
}

.admin-tools-nav-bg {
  background-color: var(--gray-5);
  position: fixed;
  z-index: 1;
  left: 0;
  top: 64px;
  height: 56px;
  width: -webkit-fill-available;
}
.manage-tools-nav-bg {
  background-color: var(--gray-5);
  position: fixed;
  z-index: 1;
  left: 0;
  top: 64px;
  height: 56px;
  width: -webkit-fill-available;
}

.user-dropdown-icon-style.btn:hover,
.user-dropdown-icon-style.btn:focus {
  background-color: var(--gray-4);
}

.user-dropdown-icon-style {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-direction: row;
  text-align: right;
  padding-left: 12px;
  padding-right: 9px;
  height: 32px;
  margin-top: 16px;
  margin-bottom: 16px;
  background: var(--gray-4);
  border-radius: 4px;
}

.notifications-bell-icon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.notification-btn-margin {
  padding: 0;
}

.notifications-bell-icon img {
  height: 18px;
  width: 16px;
}

.user-dropdown-icon-style:hover {
  outline: 1px solid var(--gray-9);
}

.user-dropdown-item-link-override:hover {
  text-decoration: none;
}

.nav-dropdown-text {
  color: var(--gray-9);
  margin-top: 14px;
}

.fa-tv {
  color: var(--gray-9) !important;
}
.disabledCursor {
  cursor: default;
}

/* Navbar Styles */
.navbar-background {
  background: var(--gray-3);
  display: flex;
  padding: 16px 32px;
  gap: 24px;
  height: 64px;
  top: 0px;
  width: 100%;
  border-bottom: 2px solid var(--gray-7);
  position: fixed;
  z-index: 99;
  justify-content: space-between;
  align-items: center;
  flex-wrap: inherit;
}
.navbar-title {
  color: var(--mint-6);
}
.navbar-title:hover {
  color: var(--mint-6);
}
.me-auto {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}
.dropdown-navbar {
  margin-left: 0px;
}

.profile-dropdown {
  color: var(--gray-9);
}

.profile-dropdown > .show {
  display: flex;
}

.navbar-time {
  color: var(--gray-9);
  font-size: 14px;
  float: left;
  width: 80%;
  cursor: pointer;
  font-weight: 700;
}
.navbar.affix {
  margin-top: 0 !important;
  left: 0;
  right: 0;
  top: 0;
}

.admin-tools-dropdown-item-inline,
.manage-tools-dropdown-item-inline,
.manage-tools-dropdown-item-inline-disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 12px 5px 9px;
  gap: 4px;
  width: 196px;
  height: 32px;
  background: var(--gray-4);
  border-radius: 4px;
}

.admin-tools-dropdown-item-inline:hover {
  border: 1px solid var(--gray-9);
  border-radius: 4px;
}

.admin-tools-dropdown-item-inline:active,
.admin-tools-dropdown-item-inline:focus,
.admin-tools-dropdown-item-inline:active .nav-dropdown-text-menu,
.admin-tools-dropdown-item-inline:focus .nav-dropdown-text-menu {
  background-color: var(--mint-6);
  color: var(--gray-1);
  border: 0;
}

.admin-tools-dropdown-item-inline:active img,
.admin-tools-dropdown-item-inline:focus img,
.manage-tools-dropdown-item-inline:active img,
.manage-tools-dropdown-item-inline:focus img {
  filter: brightness(0%);
}

.manage-tools-dropdown-item-inline-disabled img {
  filter: brightness(0.4);
}

.manage-tools-dropdown-item-inline:hover {
  border: 1px solid var(--gray-9);
  border-radius: 4px;
}

.manage-tools-dropdown-item-inline:active,
.manage-tools-dropdown-item-inline:focus,
.manage-tools-dropdown-item-inline:active .nav-dropdown-text-menu,
.manage-tools-dropdown-item-inline:focus .nav-dropdown-text-menu {
  background-color: var(--mint-6);
  color: var(--gray-1);
  border: 0;
}

.source-dest-item:focus .nav-dropdown-text-source-dest,
.source-dest-item:active .nav-dropdown-text-source-dest {
  color: var(--gray-4);
}
.source-dest-inline {
  display: inline-flex;
  height: 25px;
}

.nav-dropdown-text-source-dest {
  font-size: 14px;
}

.nav-dropdown-text-menu {
  height: 22px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-9);
  margin-top: 0px;
  margin-bottom: 0px;
}

.nav-dropdown-text-menu-disabled {
  font-size: 14px;
  margin-top: 15px;
  color: var(--gray-6);
}

.nav-dropdown-text-source-dest {
  padding-bottom: 14px;
  color: var(--gray-9);
}

.nav-dropdown-item-margin-disabled {
  filter: opacity(0.5);
}
/* Link overrides */
a:hover {
  text-decoration: none;
}

/* Bootstrap Navbar Overrides */
.navbar-light .navbar-brand {
  color: var(--mint-6);
  font-family: "Karla";
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
}
.navbar-light .navbar-brand:hover {
  color: var(--mint-6);
  font-family: "Karla";
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
}

.navbar-btn-icon {
  padding: 0px 1px 0px 1px;
}

.navbar-btn-img {
  padding: 1px 0px 1px 0px;
}

.navbar-btn-img-user {
  width: 22px;
  height: 22px;
}

.user-img-inactive {
  filter: none !important;
}

.user-img-active {
  filter: invert(10%) sepia(0%) saturate(1723%) hue-rotate(138deg)
    brightness(11%) contrast(78%);
}

.navbar-btn-dropdown-img,
.navbar-btn-dropdown-img-source,
.navbar-btn-dropdown-img-control,
.navbar-btn-dropdown-img-disabled,
.navbar-btn-dropdown-img-arrival {
  display: flex;
  align-items: center;
}
.navbar-btn-dropdown-img {
  width: 18px;
  height: 16px;
}
.navbar-btn-dropdown-img-source {
  height: 18px;
  width: 16px;
}
.navbar-btn-dropdown-img-control,
.navbar-btn-dropdown-img-disabled,
.navbar-btn-dropdown-img-arrival {
  height: 18px;
  width: 18px;
}
.navbar-btn-dropdown-img-support {
  margin-top: 10px;
}

.navbar-btn-dropdown-img-support {
  margin-top: 10px;
}

.tools-text {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: var(--gray-7);
  margin-top: 21px;
  margin-bottom: 21px;
}

.user-dropdown-item:active .navbar-btn-dropdown-img,
.user-dropdown-item:focus .navbar-btn-dropdown-img {
  filter: invert(10%) sepia(0%) saturate(1723%) hue-rotate(138deg)
    brightness(11%) contrast(78%);
}

.user-dropdown-item:active .navbar-btn-dropdown-img-support,
.user-dropdown-item:focus .navbar-btn-dropdown-img-support {
  filter: invert(10%) sepia(0%) saturate(1723%) hue-rotate(138deg)
    brightness(11%) contrast(78%);
}

.profile-dropdown:active .navbar-btn-img-user,
.profile-dropdown:focus .navbar-btn-img-user {
  filter: invert(10%) sepia(0%) saturate(1723%) hue-rotate(138deg)
    brightness(11%) contrast(78%);
}

.navbar-branding {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-logo {
  display: flex;
}

/* Alert Icon */
.note-alert {
  float: right;
  margin-right: 32px;
}
.note-alert span {
  margin-right: 2px;
  font-size: 14px;
  vertical-align: super;
}

.react-toggle-track-x {
  width: 21px !important;
  height: 4px !important;
}

.toggle-notif {
  height: 45px !important;
  top: 8px;
  right: -6px;
}

/* Notification Slider Styles */

.notifications-off-img {
  width: 18.33px;
  height: 18.33px;
}

/* Modal Styles */
.Modal.manageshowsmodal {
  position: absolute;
  z-index: 500;
  background-color: #161922;
  width: 85%;
  border: 1px solid #343539;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 7%;
  top: 10%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  box-shadow: 1px -1px 5px 2px #9eadb952;
  margin-top: 52px;
}

/* Tooltip styles */
.tooltip-styles {
  width: 180px;
}

.navbar-branding {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-logo {
  display: flex;
}

/* .user-menu-container-dropdown .user-dropdown-item:first-child {
  border-radius: 5px 5px 0px 0px;
}

.user-menu-container-dropdown .user-dropdown-item:last-child {
  border-radius: 0px 0px 5px 5px;
} */
