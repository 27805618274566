.grab-container {
  margin: 6px;
  text-align: left;
  background-color: #161922;
  border-radius: 5px;
  padding: 8px;
  box-shadow: 1px 1px 5px 1px #80808085;
}

.grab-detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.grab-text {
  text-overflow: ellipsis;
  text-align: left;
  font-size: 13px;
  width: 97%;
}

.button-section {
  margin-top: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btns.release {
  background-color: #31343d;
  color: white;
  font-size: 12px;
  outline: none;
  padding: 5px 20px 5px 20px;
  margin-left: 0.8em;
}

.btns.ignore {
  background-color: #31343d;
  color: white;
  font-size: 12px;
  outline: none;
  padding: 5px 20px 5px 20px;
}

button.close {
  margin-top: -0.2em;
  color: #fff;
  font-weight: lighter;
}
