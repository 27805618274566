.opacity-100 {
  opacity: 1;
}
.opacity-10 {
  opacity: 0.1;
}
.table_container {
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 32px;
}
.plus-icon {
  float: right;
  margin-right: 20.5px;
  margin-top: 3.2px;
}
.active-icon {
  fill: var(--gray-9);
}
.disabledPlusIcon {
  fill: var(--gray-5);
}
.active-icon:hover {
  fill: var(--mint-6);
}
.plus-icon:hover,
.restore-row:hover,
.trashIcon:hover {
  fill: var(--mint-6);
}
.trashIcon,
.restore-row {
  fill: var(--gray-9);
  width: 14px;
  height: 17px;
  vertical-align: middle;
}
.trashIcon,
.editIcon {
  cursor: pointer;
}
.editIcon:hover {
  filter: invert(60%) sepia(21%) saturate(565%) hue-rotate(96deg)
    brightness(200%) contrast(93%);
}
.nonClickable,
.disabledPlusIcon {
  pointer-events: none;
}
