.tooltip-container {
  display: flex;
  position: relative;
  /* padding-right: 16px; */
}

.tooltip-trigger {
  display: flex;
}

.tooltip-bubble {
  min-width: 120px;
  max-width: 210px;
  position: absolute;
  z-index: 10;
  &::after {
    content: "";
    position: absolute;
  }
}

.tooltip-top {
  bottom: 100%;
  left: 50%;
  padding-bottom: 9px;
  transform: translateX(-50%);

  &::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #21242d;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.tooltip-bottom {
  top: 100%;
  left: 52%;
  padding-top: 9px;
  transform: translateX(-50%);
  &::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #21242d;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.tooltip-left {
  top: 50%;
  right: 100%;
  padding-right: 9px;
  transform: translateY(-50%);

  &::after {
    border-left: 9px solid #21242d;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.tooltip-right {
  top: 50%;
  left: 100%;
  padding-left: 9px;
  transform: translateY(-50%);

  &::after {
    border-right: 9px solid #21242d;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.tooltip-message-dark {
  background: #21242d;
  border-radius: 3px;
  color: #d2e8dee8;
  font-size: 0.75rem;
  line-height: 1.8;
  padding: 0.75em;
  text-align: left;
  width: 15rem;
  font-weight: 500;
  border: 1px solid #66666d;
}
.tooltip-title-dark {
  font-size: 16px;
  text-transform: uppercase;
  color: #66666d;
}
.tooltip-content-dark {
  color: #d2e8dee8;
}

/* position */
.tooltip-searchls-info-position {
  top: 3vh;
  right: -500%;
  /* right: auto; */
  transform: translateX(-50%);
  /* padding-right: 91px !important; */
  /* left: -92%; */
}

/* .tooltip-searchls-info-position:after {
  content: "";
  position: absolute;
  bottom: 98%;
  right: 3%;
  margin-left: -5px;
  border-width: 5px;
  border-style: inset;
  border-color: transparent transparent var(--gray-9) transparent;
} */

.tooltip-message-searchls-info-container {
  background: var(--gray-9);
  border-radius: 5px;
  /* color: var(--gray-4); */
  /* font-size: 14px; */
  /* line-height: 20px; */
  padding: 8px;
  /* text-align: left; */
  width: 209px;
  height: 100px;
  /* font-weight: 500; */
  border: 1px solid #66666d;
}

.tooltip-title-lssearch-info {
  font-size: 10px;
  text-transform: uppercase;
  color: var(--gray-9);
  background: var(--gray-4);
  border-radius: 4px;
  line-height: 20px;
  font-weight: 700;
  padding-left: 8px;
}
.update-lsinfo-content {
  padding: 8px;
  padding-top: 4px;
  color: var(--gray-4);
  font-size: 10px;
  font-weight: 400;
}
.update-lsinfo-content span {
  font-weight: 700;
}

.tooltip-liveshot-info-position {
  top: 3vh;
  left: 65%;
  transform: translateX(-50%);
}
.tooltip-message-liveshot-info-container,
.tooltip-message-light-theme {
  background: var(--gray-9);
  border-radius: 5px;
  padding: 8px;
  width: 209px;
  height: 159px;
  border: 1px solid #66666d;
  text-align: left;
}
.update-info.ant-tooltip .ant-tooltip-inner {
  background: transparent !important;
  text-align: left;
}
.tooltip-title-liveshot-info,
.tooltip-title-light-theme {
  font-size: 10px;
  text-transform: uppercase;
  color: var(--gray-9);
  background: var(--gray-4);
  border-radius: 4px;
  line-height: 20px;
  font-weight: 700;
  padding-left: 8px;
}
.update-info-content {
  padding: 8px;
  padding-top: 4px;
  color: var(--gray-4);
  font-size: 10px;
  font-weight: 400;
}
.update-info-content span {
  font-weight: 700;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  line-height: 20px;
  min-height: 0px;
  padding: 5px 16px 5px 16px;
  text-align: center;
  border: none;
  outline: none;
  font-weight: 700;
  color: var(--gray-1);
  font-family: "Karla";
  font-size: 14px;
  font-style: normal;
}
.ant-dropdown .ant-dropdown-menu {
  padding: 0px;
  background: inherit;
}
.ant-dropdown {
  margin-top: 2px;
  border-radius: 4px;
  background: var(--mint-6);
  cursor: pointer;
  min-width: 0px !important;
}
.ant-dropdown:active,
.ant-dropdown:hover {
  background: var(--mint-4);
}
.mint-btn {
  background: var(--mint-4) !important;
}

.ant-tooltip .ant-tooltip-inner {
  color: var(--gray-1);
  font-family: "Karla";
  font-size: 12px;
  font-style: normal;
  min-height: 0px;
  background: var(--gray-10);
  box-shadow: none;
  padding: 0px 8px;
  text-align: center;
  min-height: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: default;
}
