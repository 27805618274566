#studiohostrow > .container {
  padding: 20px 32px 20px;
  margin-bottom: 0px;
  background: var(--gray-2);
  max-width: 100%;
  border-radius: 0px;
}
.normalblockwrapper .container {
  margin-bottom: 0px !important;
  background: var(--gray-2);
  padding: 2px 32px 12px;
  border-radius: 0px;
  cursor: auto;
}
.green-border {
  border-color: var(--mint-6);
}
#studiohostrow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
}
#studiohostrow .container:first-child {
  margin-top: 4px;
}
.normalblockwrapper {
  justify-content: center;
  align-items: flex-start;
}
.normalblock-Take-Grab {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: fit-content;
  height: 24px;
  margin-left: auto;
}
.Container {
  display: flex;
  flex-direction: column;
}
.rc-time-picker-clear {
  display: none;
}
p.maintitle {
  color: #6e7176;
  font-size: 15px;
  width: 11em;
  background-color: #161922;
  height: 4ex;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 0;
}
.maintitle span {
  background-color: #2b3348;
  padding: 0px 13px;
  float: right;
  height: 100%;
}
.titlerow {
  margin: 0;
  background-color: #0a0b10 !important;
}
p.normalblock {
  color: #6e7176;
  padding: 2px 0px 2px 5px;
  margin: 0;
  margin-top: 20px;
  font-size: 15px;
  width: 160px;
  background-color: #161922;
}
span.alph-icon {
  display: inline-block;
  width: 30px;
}
.normalblock i.fa.fa-bars {
  transform: rotate(90deg);
  font-size: 21px;
}
#drag-bottom {
  height: 5%;
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}
.drag-drop-bottom-space {
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  flex-grow: 1;
}
.hide-delete-icon {
  display: none;
}
.hide-add-icon {
  display: none;
}
.block-name {
  border: none;
  height: 22px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-transform: uppercase;
  color: var(--gray-9);
  max-width: -moz-fit-content;
  max-width: fit-content;
  text-align: left;
  padding: 0px 10px;
  background: transparent;
  width: 60px;
  outline: none;
  border: none;
  border-radius: 4px;
}

.block-name::placeholder {
  color: var(--gray-7);
}

.block-name:focus {
  border-radius: 4px;
  outline: none;
}

.block-section .blocks {
  height: 22px;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: right;
  color: var(--gray-7);
}

.block-section {
  width: auto;
  margin-right: 8px;
}
.normalblockheader {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 16px;
  width: fit-content;
  background: var(--gray-3);
  border-radius: 30px;
  grid-gap: 16px;
}
.scrolltop_btn,
.remove-block,
.add-block {
  cursor: pointer;
}
.block-icon,
.add-block,
.remove-block,
.scrolltop_btn,
.grabber-btn {
  display: flex;
  align-items: center;
}
.grabber-btn:hover svg path,
.scrolltop_btn:hover svg path,
.remove-block:hover svg path,
.add-block:hover svg path {
  fill: var(--gray-9);
}
.grabber-btn:active svg path,
.scrolltop_btn:active svg path,
.remove-block:active svg path,
.add-block:active svg path {
  fill: var(--mint-6);
}
.block-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 22px;
  width: 100%;
  height: 34px;
  background: var(--gray-2);
  padding: 28px 32px 28px 36px;
  margin-top: 2px;
}
.block-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
}
.info-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #161922;
  padding-right: 0;
}
.info-section > div {
  flex: 0;
}
#block-name-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60px;
  height: 24px;
  background: var(--gray-4);
  border-radius: 4px;
  border: none;
  margin-right: 16px;
}
#block-name-div:hover input {
  color: var(--gray-9);
  border: 1px solid var(--gray-9);
}
#block-name-div:focus input {
  color: var(--gray-9);
  border: 1px solid var(--gray-9);
}

.info-section i.fa.fa-bars {
  transform: rotate(90deg);
  font-size: 21px;
  padding: 0.4rem 0.2rem;
}
.block-actions {
  display: flex;
  flex-direction: row;
  padding: 0.4rem 0.2rem;
  justify-content: space-between;
}
.save-block-name-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 8px;
  grid-gap: 4px;
  gap: 4px;
  width: 56px;
  height: 24px;
  background: var(--gray-4);
  border-radius: 60px;
  cursor: pointer;
}
.save-block-name-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: var(--gray-9);
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
.save-block-name-div:hover {
  border: 1px solid var(--gray-9);
}
.save-block-name-div:hover input {
  color: var(--gray-9);
}
.save-block-name-div:active {
  background-color: var(--mint-6);
}
.save-block-name-div:active input {
  color: var(--gray-1);
}
.title {
  float: left;
  margin-top: 10px;
}
.title-canvas {
  color: var(--gray-9);
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: right;
}
.mainblock-Take-Grab {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px 12px 20px;
  grid-gap: 16px;
  grid-gap: 16px;
  gap: 16px;
  width: 216px;
  height: 56px;
  background-color: var(--gray-1);
  border: 2px solid var(--gray-4);
  border-radius: 30px;
  position: absolute;
  top: -79px;
  right: 10px;
}

.container-canvasTakeGrab {
  position: relative;
  bottom: 78px;
  right: 250px;
}
.canvasblock-Take-Grab {
  background-color: var(--gray-1);
  border-radius: 30px;
  font-weight: bold;
  justify-content: center;
  border: 2px solid var(--gray-4);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px 12px 20px;
  grid-gap: 16px;
  gap: 16px;
  width: fit-content;
  height: 56px;
}

.canvas-block-btns {
  position: absolute;
  right: -220px;
  top: 10px;
}
.main-block-btns {
  position: absolute;
  right: 1.5%;
}

.title-block {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: right;
  color: var(--gray-9);
}

.normalblock-Take-Grab .title-block {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  display: inline-flex;
  align-items: center;
  color: var(--gray-7);
  margin-top: 0px;
}
.title-block span {
  text-transform: uppercase;
  margin-right: 3px;
}

.normalblock-Take-Grab .take-grab-btns .takeall,
.normalblock-Take-Grab .take-grab-btns .graball {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 8px;
  gap: 4px;
  width: 56px;
  height: 24px;
  background: var(--gray-4);
  border-radius: 60px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #dbdbdb;
  border: none;
}

.take-grab-btns .graball,
.take-grab-btns .takeall,
.maintake-grab-btns .graball,
.maintake-grab-btns .takeall {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 12px;
  gap: 4px;
  width: 57px;
  height: 32px;
  background: var(--gray-4);
  border-radius: 60px;
  margin-left: 0px;
  outline: none;
}

.takeall,
.graball {
  margin-left: 10px;
  border-radius: 10px;
  background-color: var(--gray-4);
  color: var(--gray-10);
  font-weight: 600;
  outline: none;
  font-size: smaller;
  width: 40px;
  border: none;
  cursor: pointer;
}
.take-grab-btns .takeall:hover,
.take-grab-btns .graball:hover {
  border: 1px solid var(--gray-9);
}
.take-grab-btns .takeall:focus,
.take-grab-btns .graball:focus {
  outline: none;
}
.take-grab-btns .takeall:active,
.take-grab-btns .graball:active {
  color: var(--gray-1) !important;
  outline: none;
  border: none;
  background-color: var(--mint-6) !important;
}
.normalblock-Take-Grab .take-grab-btns {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 124px;
  height: 24px;
  margin-top: 0px !important;
  margin-left: 0px;
}
.maintake-grab-btns {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 130px;
  height: 32px;
}
.graball {
  margin-left: 10px;
}

.take-grab-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 130px;
  height: 32px;
}
