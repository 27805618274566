.popup-modal-route {
  background-color: var(--gray-4);
  color: #21242d;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  width: 520px;
  align-items: center;
  position: fixed;
  z-index: 500;
  /*overflow: auto; LSM-4722 The error modal right side border gets thin When Screen zoom size 75*/
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.modal-content.signal-msg-content-box {
  z-index: 9999;
  cursor: auto;
  background-color: var(--gray-2);
  border: 2px solid var(--mint-6);
  padding: 32px;
}
.successMsg {
  color: green;
}
.failureMsg {
  color: #d64020;
}
.modal-body.signal-msg-body {
  overflow-x: auto;
  max-height: 340px;
  padding-left: 0px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.signal-msg-body p {
  margin: 0;
  font-size: 14px;
}

@media screen and (max-device-width: 575px) {
  .popup-modal {
    margin-left: 20%;
    width: 350px;
  }
}
.successStatusButton {
  display: flex;
  width: 76px;
  padding: 1px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--mint-6);
  color: var(--gray-1);
}
.failureStatusButton {
  display: flex;
  width: 76px;
  padding: 1px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--rose-6);
  color: var(--gray-1);
}
.textInfo {
  color: var(--gray-9);
}
.headerDiv {
  color: var(--gray-9);
  border-bottom: none;
  padding: 0px;
  font-weight: 700;
  display: block;
}
.signalModalFooter {
  border-top: none;
  padding: 16px 0px 0px 0px;
}
.signals-close-icon {
  margin: 2px;
  float: right;
}
.marginRight17rem {
  margin-right: 17rem;
}
.marginRight19rem {
  margin-right: 19rem;
}
.signalsYellowBorder {
  border: 2px solid var(--banana-6) !important;
}
.signalsGreenBorder {
  border: 2px solid var(--mint-6) !important;
}
.signalsRedBorder {
  border: 2px solid var(--rose-6) !important;
}
.modalIcon {
  margin-right: 12px;
  float: left;
}
.headerText {
  float: left;
  font-size: 20px;
}
.textMsg {
  display: flex;
  align-items: center;
  margin: 16px 0px;
  font-family: "Karla";
  font-style: normal;
}
.textMsg span {
  display: inline-flex;
  color: var(--gray-9);
  font-size: 14px;
}
.statusChip {
  color: var(--gray-1) !important;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  margin-right: 16px;
  width: 76px;
  height: 24px;
  justify-content: center;
  align-items: center;
}
.success-chip {
  background: var(--mint-6);
}
.failure-chip {
  background: var(--rose-6);
}
.marginBottom15Px {
  margin-bottom: 15px !important;
}
.error-ok-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  width: 65px;
  height: 32px;
  background: var(--gray-4);
  border-radius: 60px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-9);
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
}
.error-cancel-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  width: 65px;
  height: 32px;
  background: var(--gray-2);
  border-radius: 60px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--gray-7);
  border: 1px solid var(--gray-7) !important;
  outline: none;
  cursor: pointer;
}
.error-cancel-btn:hover,
.error-ok-btn:hover,
.error-close-btn:hover,
.duplicate-close-btn:hover {
  border: 1px solid var(--gray-9) !important;
  color: var(--gray-9);
}
.error-ok-btn:active,
.error-ok-btn:focus {
  color: var(--gray-1);
  outline: none !important;
  border: none !important;
  background-color: var(--rose-6) !important;
}
.error-cancel-btn:active,
.error-cancel-btn:focus {
  color: var(--rose-6);
  border: 1px solid var(--rose-6) !important;
}
.duplicate-close-icon:hover,
.duplicate-close-icon:focus {
  fill: var(--banana-6);
}
.error-close-icon:hover,
.error-close-icon:focus {
  fill: var(--rose-6);
}
.error-close-btn:active,
.error-close-btn:focus {
  background-color: var(--rose-6);
  color: var(--gray-1);
  border: none !important;
}

.duplicate-close-btn:active,
.duplicate-close-btn:focus {
  background-color: var(--banana-6);
  color: var(--gray-1);
  border: none !important;
}

.error-close-btn,
.duplicate-close-btn {
  color: var(--gray-9);
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  width: 65px;
  height: 32px;
  background: var(--gray-4);
  border-radius: 60px;
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  outline: none !important;
  cursor: pointer;
}
