.login {
  text-align: center;
  width: 70%;
  margin: 0 auto;
  padding-top: 55px;
}
.login button {
  background-color: #2b3348;
  color: gray;
  padding: 10px 20px;
  border: 1px solid gray;
  outline: none;
  font-weight: bold;
  cursor: pointer;
}
